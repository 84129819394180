import styled from "styled-components";
// import LogoIcon from "../assets/lumina_logo.png"
import { Link, useNavigate } from "react-router-dom";
import LogoIcon from "../assets/logos/no_bg_white_logo_with_text.png"
import axios from "axios";
import { useEffect, useState } from "react";
import { API_Route } from "../utils/Api";



const Header = styled.div`

    // position: sticky;
    top: 0;
    backdrop-filter: blur(10px);
    gap: 10px;
    z-index: 10;

`;

const InnerContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
    width: 1200px;
    margin: auto;

    
    @media screen and (max-width: 1250px){
        width: auto;
    }


    @media screen and (max-width: 500px){
        padding: 10px;
    }
`;


const LeftHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
`;


const RightHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 20px;

    flex: 1;

`;

const RightHeaderItem = styled.li`
    list-style: none;
    // padding: 13px 15px;
    border-radius: 100%;
    // color: var(--lumina-text-color);
    // background: rgba(255, 255, 255, 0.1);
    // color: var(--lumina-brand-color);
    // background: var(--lumina-transparent-brand-color);
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    font-weight: 500;
    background: rgba(225, 225, 225, 0.1);
    width: 40px;
    height: 40px;

    &:hover{
        // background: rgba(255, 255, 255, 0.2);
        // background: var(--lumina-transparent-brand-color-hover);
    }

    position: relative;
    overflow: hidden;

`;


const ImageShimer = styled.li`
    list-style: none;
    // padding: 13px 15px;
    border-radius: 100%;
    // color: var(--lumina-text-color);
    // background: rgba(255, 255, 255, 0.1);
    // color: var(--lumina-brand-color);
    // background: var(--lumina-transparent-brand-color);
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    font-weight: 500;
    background: rgba(225, 225, 225, 0.1);
    width: 40px;
    height: 40px;

    &:hover{
        // background: rgba(255, 255, 255, 0.2);
        // background: var(--lumina-transparent-brand-color-hover);
    }

    position: relative;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -150%;
        height: 100%;
        width: 150%;
        background: linear-gradient(
            90deg,
            transparent,
            rgba(255, 255, 255, 0.2),
            transparent
        );
        animation: shimmer 2s infinite;
    }

    @keyframes shimmer {
        0% {
            transform: translateX(-100%);
        }
        100% {
            transform: translateX(100%);
        }
    }
`;

const UserImage = styled.img`
    width: 100%;
    height: 100%;
`;


const LogoContainer = styled.h1`
    display: flex;
    // align-items: center;
    // font-size: 16px;
    // font-weight: 600;
    // color: var(--lumina-text-color);
    // gap: 10px;
    width: 150px;
    cursor: pointer;

`;

const Logo = styled.img`
    width: 100%;
    height: 100%;
`;

const SwapHeader = () => {

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState({position:"translateY(-180%)",message:""});
    const [userData, setUserData] = useState({
        profilePicture: '',
        fullName: ''
    });

    const navigate = useNavigate();

    let closeErrorModal=async()=>{
        await setInterval(()=>{
            const set_error_params={position:"translateY(-180%)",message:""}
            setError(set_error_params)
        },4000)
        clearInterval()
    }

    


    useEffect(()=>{
        const getUser = async () => {
            const userSessionToken = localStorage.getItem('userSessionToken');
            try {
                const response = await axios.post(`${API_Route}getUserProfile`, {
                    userSessionToken
                });
        
                if (response.status === 200) {
                    console.log(response.data);
    
                    // checking if the user email is activated
                    if(response.data.emailActivated){
                        setUserData(response.data);
                        setLoading(false);
                    }else{
                        navigate('/verify-email')
                    }
                }
            } catch (error) {
    
                const set_error_params = { position: "translateY(0%)", message: 'Check your network' };
                setError(set_error_params);
                await closeErrorModal();
    
    
            }
        };

        getUser();
    },[navigate])
    return (
        <Header>
            <InnerContainer>
                <LeftHeader>
                    <Link to='/'>
                        <LogoContainer>
                            <Logo src={LogoIcon}/>
                        </LogoContainer>
                    </Link>

                </LeftHeader>

                <RightHeader>

                    
                        {loading
                        ?<ImageShimer/>
                        :<Link to='/dashboard'>
                            <RightHeaderItem>
                                <UserImage src={userData.profilePicture}/>
                            </RightHeaderItem>
                        </Link>}
                    
                
                </RightHeader>
            </InnerContainer>


            <div style={{color:'white'}}>{error.message}</div>
           
            
            

            
        </Header>
    )
}

export default SwapHeader