import styled from "styled-components";
import ArrowBackIcon from "../assets/arrow-back-icon.svg";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { API_Route } from "../utils/Api";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import LoadingModalComponent from "./LoadingModal.Component";
import PaystackLogo from "../assets/paystack_logo.png";
import StripeLogo from "../assets/stripe_logo.png";




const Container = styled.div`
`;


const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 2px var(--lumina-border-line-color);
    padding: 20px;
`;
const HeaderIconsCon = styled.div`
    padding: 10px;
    border-radius: 100%;
    width: 13px;
    height: 13px;
    display: flex;
    flex-direction: row;
    align-items: center;

    cursor: pointer;

    &:hover{
        background: var(--lumina-text-box-color);
    }
`;

const HeaderIcons = styled.img`
    width: 100%;
    height: 100%;

    
`;
const HeaderText = styled.h2`
    color: var(--lumina-text-color);
    font-size: 15px;
    font-weight: 500;
`;

const PaymentOptionsContainer = styled.div`
    padding: 10px;
`;
const PaymentOptionsItem = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 15px;
    cursor: pointer;
`;

const PaymentLogoCon = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 10px;
    overflow: hidden;
`;
const PaymentLogoImg = styled.img`
    width: 100%;
    height: 100%;
`;

const PaymentOptionsName = styled.div`
    color: var(--lumina-text-color);
    font-size: 15px;
    font-weight: 500;
`;


const PaymentOptionsModal = ({toggleModal, selectedCurrencyCode, toRecieveInput, youPayInput, selectedBlockchain, selectedBlockchainAddress}) => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const checkIfUserEmailIsActivated =async(userSessionToken)=>{
        try {
            const response = await axios.post(`${API_Route}getUserProfile`,{
                userSessionToken
            })

            const emailActivated = response.data.emailActivated


            return {emailActivated};
        } catch (error) {
            console.log(error)
        }
    }

    const stripePayment = async()=>{
        // I was trying to test the email activated feature and also show a loader when the function is running

        
        setLoading(true)
       
        try {

            const userSessionToken = localStorage.getItem('userSessionToken');

            // check if the user email is activated
            const {emailActivated} = await checkIfUserEmailIsActivated(userSessionToken);

            if(emailActivated){
                const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

                const response = await axios.post(`${API_Route}buyCryptoCoin`,{
                    userSessionToken,
                    tx_amount: youPayInput,
                    tx_currency: selectedCurrencyCode,
                    tx_coin_name: selectedBlockchain,
                    stripePayment: true,
                    paystackPayment: false,
                    tx_amount_in_crypto: toRecieveInput,
                    tx_address: selectedBlockchainAddress,
                    
                })

                const session = response.data;

                // console.log(session.checkout_url)
                // console.log(session.checkout_id)

                stripe.redirectToCheckout({
                    sessionId: session.checkout_id
                });

                setLoading(false)
            }else{
                // take them back to verify their email
                navigate(`/verify-email`);
                setLoading(false)
            }

            
        } catch (error) {
            console.log(error)
        }

        // console.log(result)
    }

    const paystackPayment = async()=>{
        setLoading(true)

        try {
            const userSessionToken = localStorage.getItem('userSessionToken');

            // check if the user email is activated
            const {emailActivated} = await checkIfUserEmailIsActivated(userSessionToken);

            if(emailActivated){
                const response = await axios.post(`${API_Route}buyCryptoCoin`,{
                    userSessionToken,
                    tx_amount: youPayInput,
                    tx_currency: selectedCurrencyCode,
                    tx_coin_name: selectedBlockchain,
                    stripePayment: false,
                    paystackPayment: true,
                    tx_amount_in_crypto: toRecieveInput,
                    tx_address: selectedBlockchainAddress,
                    
                })
    
                window.location.replace(response.data.paystack_checkout_url)
                setLoading(false)
                // window.open(response.data.paystack_checkout_url)
    
                // window.open(response.data.paystack_checkout_url, "_blank", "width=400, height=400")
            }else{
                // take them back to verify their email
                navigate(`/verify-email`);
                setLoading(false)
            }


        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            {loading
            ?<LoadingModalComponent showModal={loading} />
            :<Container>
                <Header>
                    <HeaderIconsCon className='transparent-click' onClick={() => toggleModal('payment-options')}>
                        <HeaderIcons src={ArrowBackIcon} />
                    </HeaderIconsCon>

                    <HeaderText>Choose a payment option</HeaderText>
                </Header>


                <PaymentOptionsContainer>
                    <PaymentOptionsItem onClick={paystackPayment}>
                        <PaymentLogoCon>
                            <PaymentLogoImg src={PaystackLogo}/>
                        </PaymentLogoCon>
                        <PaymentOptionsName >Pay with Paystack</PaymentOptionsName>
                    </PaymentOptionsItem>

                    <PaymentOptionsItem onClick={stripePayment}>
                        <PaymentLogoCon>
                            <PaymentLogoImg src={StripeLogo} />
                        </PaymentLogoCon>
                        <PaymentOptionsName >Pay with Stripe</PaymentOptionsName>
                    </PaymentOptionsItem>
                </PaymentOptionsContainer>
            </Container>}
        </>
        
    )
}

export default PaymentOptionsModal