import styled from "styled-components"
import signin_banner from "../assets/signin_banner.png";
import lumina_logo from "../assets/logos/greenlogo.png"
import {  useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { API_Route } from "../utils/Api";
import { useGoogleLogin } from "@react-oauth/google";
import LoadingModalComponent from "../components/LoadingModal.Component";
import ErrorModalComponent from "../components/ErrorModal.Component";
import GoogleIcon from "../assets/google-icon.svg";
import { Helmet } from "react-helmet";

const Container = styled.div`
    display: flex;
    // height: 100vh;

    @media screen and (max-width: 850px){
        display: block;
    }

`;
const AuthParentContainer =styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 2;
    // overflow-y: auto;
    // max-height: 100vh;
    padding: 20px;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background: var(--lumina-text-box-color);
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
`;

const AuthInnerParent = styled.form`
    width: 400px;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 15px;
    

    // @media screen and (max-width: 850px){
    //     margin-top: 50px;
    // }

    @media screen and (max-width: 500px){
        width: 100%;
    }
`;
const AuthHeaderContainer =styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--lumina-text-color);
    margin-bottom: 30px;
`; 
const LogoContainer = styled.div`
    width: 150px;
    // height: 50px;
    // margin: auto;
`;

const ContainerImage = styled.img`
    width: 100%;
    height: 100%;
`;

const CustomGoogleSignIn = styled.div`
    color: var(--lumina-text-black-color);
    font-size: 13px;
    font-weight: 600;
    background: var(--lumina-text-color);
    text-align: center;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;

    @media screen and (max-width: 750px){
        padding: 15px;
    }

`;

const GoogleIconCon = styled.div`
    width: 15px;
    height: 15px;
`;

const GoogleImage = styled.img`
    width: 100%;
    height: 100%;
`;

const OrSection = styled.div`
    display: flex;
    gap: 10px;
    color: var(--lumina-text-color);
    align-items: center;
    padding: 0px 10px;
`;

const OrLine = styled.div`
    height: 1px;
    flex: 1;
    background: #5c5c5c;
`;

const Inputabel = styled.label`
    color: var(--lumina-text-color);
    font-size: 12px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    gap: 5px;

    @media screen and (max-width: 750px){
        font-size: 15px;
    }
`;
const InputContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    background: var(--lumina-text-box-color);
    padding: 10px;
    border-radius: 10px;

    @media screen and (max-width: 750px){
        padding: 15px;
        font-size: 15px;
    }
`;
const Input = styled.input`
    /* Hide up and down arrows in number input */
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    & {
        -moz-appearance: textfield; /* Firefox */
    }
    
    background: none;
    outline: none;
    border: none;
    color: var(--lumina-text-color);
    flex: 1;
    font-size: 16px;

`;
const ForgotPasswordContainer = styled.div`
    display: flex;

`;
const ForgotPassword = styled.div`
    color: var(--lumina-brand-color);
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    margin-top: 10px;
`;

const ActionButton = styled.button`
    color: var(--lumina-text-black-color);
    background: var(--lumina-brand-color);
    font-size: 13px;
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
    text-align: center;
    margin-top: 15px;
    width: 100%;
    font-weight: 600;

    @media screen and (max-width: 750px){
        padding: 15px;
    }

`;

const BannerSection = styled.div`
    // flex: 1.5;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: right;

    @media screen and (max-width: 850px){
        display: none;
    }

`;
const BannerImageContainer = styled.div`
    width: 400px;
    height: 100%;
    right: 0;

`;
const BannerImage = styled.img`
    width: 100%;
    height: 100%;
`;
const HaveAnAccountText = styled.div`
    color:var(--lumina-text-color);
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    margin-top: 10px;
`;


const Signin = () => {
    let [passwordState,setPasswordState]=useState("password")
    let [passwordTextState,setPasswordTextState]=useState("show");
    let [revealVisibiityState,setRevealVisibilityState]=useState("none");
    let [hiddenVisibiityState,setHiddenVisibilityState]=useState("block");
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState({position:"translateY(-180%)",message:""});

    const userGeneratedPassForGoogle = process.env.REACT_APP_USERS_GENERATED_PASSWORDS;

    const navigate = useNavigate();

    let RevealPassword=()=>{
        setPasswordState("text");
        setPasswordTextState("hide");
        setHiddenVisibilityState("block");
        setRevealVisibilityState("none")
    }

    let HidePassword=()=>{
        setPasswordState("password");
        setPasswordTextState("show")
        setHiddenVisibilityState("none")
        setRevealVisibilityState("block")
    }

    let closeErrorModal=async()=>{
        await setInterval(()=>{
            const set_error_params={position:"translateY(-180%)",message:""}
            setError(set_error_params)
        },4000)
        clearInterval()
    }

    const signinFunc = async(e)=>{
        e.preventDefault();

        setLoading(true)
        if(email && password){
            try {
                

                const response = await axios.post(`${API_Route}signin`,{
                    email,
                    password,
                })


                if(response.status===200){
                    // save user session token to local storage 
                    const userSessionToken = response.data.userSessionToken;
                    localStorage.setItem('userSessionToken', userSessionToken)

                    // check if the user email is activated
                    const {emailActivated} = await checkIfUserEmailIsActivated(userSessionToken);


                    if(emailActivated){
                        navigate('/dashboard');
                    }else{
                        navigate(`/verify-email`);
                    }
                    setLoading(false)
                }
            
            } catch (error) {
                // console.log(error.message)

                if(error.response.data&&error.response.data.error){
                    const set_error_params={position:"translateY(0%)",message:error.response.data.error}
                    setError(set_error_params);
                    await closeErrorModal()
                    setLoading(false)
                }else{
                    const set_error_params={position:"translateY(0%)",message:"Something Went Wrong"}
                    setError(set_error_params);
                    await closeErrorModal()
                    setLoading(false)
                }                
            }
        }else{
            const set_error_params={position:"translateY(0%)",message:"Something Went Wrong"}
            setError(set_error_params);
            await closeErrorModal()
            setLoading(false)
        }
    }

    const checkIfUserEmailIsActivated =async(userSessionToken)=>{
        try {
            const response = await axios.post(`${API_Route}getUserProfile`,{
                userSessionToken
            })

            const emailActivated = response.data.emailActivated


            return {emailActivated};
        } catch (error) {
            console.log(error)
        }
    }

    const login = useGoogleLogin({
        onSuccess: credentialResponse => googleSignIn(credentialResponse.access_token),
    })


    const googleSignIn = async(credentialResponse)=>{
        setLoading(true)
        // get the users data from the credential
        await axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${credentialResponse}`, {

            headers: {
                Authorization: `Bearer ${credentialResponse}`,
                Accept: 'application/json'
            }
        })
        .then(async(result)=>{
            const resultResponse = result.data;
            try {
                const response = await axios.post(`${API_Route}signin`,{
                    email: resultResponse.email,
                    password: userGeneratedPassForGoogle,
                })

                if(response.status===200){
                    // save user session token to local storage and move to the dashboard or swap page after 2 seconds
                    const userSessionToken = response.data.userSessionToken;
                    setTimeout(() => {
                        localStorage.setItem('userSessionToken', userSessionToken)
                        setLoading(false)
                        navigate('/dashboard');
                    }, 2000);
                    clearTimeout();
                }

            } catch (error) {
                if(error.response.data&&error.response.data.error){
                    const set_error_params={position:"translateY(0%)",message:error.response.data.error}
                    setError(set_error_params);
                    await closeErrorModal()
                    setLoading(false)
                }else{
                    const set_error_params={position:"translateY(0%)",message:"Something Went Wrong"}
                    setError(set_error_params);
                    await closeErrorModal()
                    setLoading(false)
                }
            }
        })
        .catch(async(err) => {
            const set_error_params={position:"translateY(0%)",message:"Something Went Wrong"}
            setError(set_error_params);
            await closeErrorModal()
            setLoading(false)
        });
    }

    return (
        <>
        <Helmet>
            <title>Handiswap | Sign-in</title>
        </Helmet>
            {loading&&<LoadingModalComponent showModal={loading} />}
            <ErrorModalComponent delay="4000" position={error.position} message={error.message} />

            <Container>

                <AuthParentContainer>

                    <AuthInnerParent onSubmit={signinFunc}>

                        <AuthHeaderContainer>
                            <LogoContainer>
                                <ContainerImage src={lumina_logo}/>
                            </LogoContainer>
                        </AuthHeaderContainer>

                        <CustomGoogleSignIn onClick={() => login()}><GoogleIconCon><GoogleImage src={GoogleIcon}/></GoogleIconCon>Signin with Google</CustomGoogleSignIn>

                        <OrSection>
                            <OrLine/>
                            or
                            <OrLine/>
                        </OrSection>

                        <Inputabel>
                            Email
                            <InputContainer>
                                <Input value={email} onChange={(e)=>setEmail(e.target.value)} type="email" placeholder="xxx@xxx.xxx"/>
                            </InputContainer>
                        </Inputabel>
                        <Inputabel>
                            Password
                            <InputContainer>
                                <Input value={password} onChange={(e)=>setPassword(e.target.value)}  type={passwordState} placeholder="Password"/>

                                <span className="password_show" onClick={()=>{RevealPassword()}} style={{cursor:"pointer",display:revealVisibiityState}}>{passwordTextState}</span>
                                <span className="password_hide" onClick={()=>{HidePassword()}} style={{cursor:"pointer",display:hiddenVisibiityState}} >{passwordTextState}</span>
                            </InputContainer>
                        </Inputabel>

                        <ForgotPasswordContainer>
                            <div style={{flex:'1'}}/>
                            <ForgotPassword><Link to={'/reset-password'} className="transparent-click" style={{fontWeight:'normal',  color:'var(--lumina-brand-color)'}}>Forgot Password?</Link></ForgotPassword>
                        </ForgotPasswordContainer>

                        <ActionButton type='submit' onClick={signinFunc} >Signin</ActionButton>

                        <HaveAnAccountText>Don't have an account? <Link className="transparent-click" to={'/signup'} style={{fontWeight:'normal', color:'var(--lumina-brand-color)'}}>SignUp</Link></HaveAnAccountText>

                    </AuthInnerParent>
                </AuthParentContainer>

                <BannerSection>
                    <BannerImageContainer>
                        <BannerImage src={signin_banner}/>
                    </BannerImageContainer>
                </BannerSection>
            </Container>
        </>
    )
}

export default Signin