import styled from "styled-components";
import { CiFilter } from "react-icons/ci";
import { useCallback, useEffect, useState } from "react";
import { GrStatusGood } from "react-icons/gr";
// import { MdEdit } from "react-icons/md";

import TransactionsComponent from "../components/TransactionsComponent"
// import LoadingModalComponent from "../components/LoadingModal.Component";
import ErrorModalComponent from "../components/ErrorModal.Component";
import { Link, useNavigate } from "react-router-dom";
import HeaderComponent from "../components/HeaderComponent";
import axios from "axios";
import { API_Route } from "../utils/Api";
import EmptydataImage from '../assets/emptydata.svg'
import LoadingModalComponent from "../components/LoadingModal.Component";
import {Helmet} from "react-helmet"



const Container = styled.div`
`;


const InnerContainer = styled.div`
    display: flex;
    gap: 10px;
    width: 1200px;
    margin: auto;

    @media screen and (max-width: 1250px){
        width: auto;
    }

    @media screen and (max-width: 750px){
        flex-direction: column;
    }
`;
const LeftContainer = styled.div`
    flex: 1.5;
`;


const UserProfileCon = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 20px;
`;

const UserProfileImageCon = styled.div`
    width: 150px;
    height: 150px;
    overflow: hidden;
    border-radius: 100%;
    margin: auto;
    margin-bottom: 10px;
    border-color:var(--lumina-brand-color);
    border-style:solid;    
`;
const UserProfileImage = styled.img`
    width: 100%;
    height: 100%;
`;
const UserProfileName = styled.h2`
    color: var(--lumina-text-color);
    font-size: 15px;
    font-weight: 600;
`;
const UserProfileEmail = styled.h4`
    color: var(--lumina-grey-color);
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 10px;
`;
// const EditProfile = styled.div`
//     text-align: center;
//     padding: 10px;
//     border-radius: 10px;
//     color: var(--lumina-brand-color);
//     background: var(--lumina-transparent-brand-color);
//     font-size: 13px;
//     cursor: pointer;
//     font-weight: 600;
//     display: flex;
//     align-items: center;
//     gap: 10px;
//     justify-content: center;

//     &:hover{
//         background: var(--lumina-transparent-brand-color-hover);
//     }
// `;




const RightContainer = styled.div`
    flex: 4;
    display: flex;
    flex-direction: column;
// <<<<<<< HEAD
    gap: 20px;
    margin-top:10px;
// =======
    gap: 20px;    
    padding-top: 20px;
// >>>>>>> 62d445785942d5ebac0a3afcf2032910194bf84b
`;

const Dashboardtext = styled.h1`
    color: var(--lumina-text-color);
    font-size: 15px;
    font-weight: 600;
    margin-left: 20px;
`;

const FilterArea = styled.div`
    padding: 10px 20px;
    display: flex;
    gap: 10px;
    align-items: center;

`;


const FilterItem = styled.h4`
    color: var(--lumina-text-color);
    font-size: 13px;
    font-weight: 500;
    padding: 10px;
    cursor: pointer;
    border-radius: 20px;
    background: var(--lumina-text-box-color);
    display: flex;
    align-items: center;
    gap: 10px;
`;
const InnerRightContainer = styled.div`
    padding: 10px;
    border-radius: 20px;
    background: var(--lumina-text-box-color)
    width: 300px;
    overflow-x: scroll;

    &::-webkit-scrollbar {
        width: 5px;
        height: 10px;
    }

    &::-webkit-scrollbar-track {
        background: var(--lumina-text-box-color);
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
`;

const NoatrsnactionText = styled.h2`
    color: var(--lumina-grey-color);
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;


`;
const NodataContainer = styled.div`
    width: 200px;
    height: 200px;
    margin: auto;
`;
const NodataImage = styled.img`
    width: 100%;
    height: 100%;
`;



const Dashboard = () => {
    const [transactionsItems, setTransactionsItems] = useState([])
    const [transactionsFilter, setTransactionsFilter] = useState([])
    const [transactionsFilterName, setTransactionsFilterName] = useState('all')
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState({position:"translateY(-180%)",message:""});
    const [userData, setUserData] = useState({
        profilePicture: '',
        email: '',
        fullName: ''
    });

    const navigate = useNavigate();

    let closeErrorModal=async()=>{
        await setInterval(()=>{
            const set_error_params={position:"translateY(-180%)",message:""}
            setError(set_error_params)
        },4000)
        clearInterval()
    }


    const toggleConfirmedTransactions =(filter)=>{
        if(filter==='confirmed'){
            // filter confirmed transactions
            const confirmedTransactions = transactionsItems.filter((transaction)=> transaction.tx_status==='completed')
            setTransactionsFilter(confirmedTransactions);
            setTransactionsFilterName('confirmed')
        }else if(filter==='unconfirmed'){
            // filter unconfirmed transactions
            const unconfirmedTransactions = transactionsItems.filter((transaction)=> transaction.tx_status==='pending')
            setTransactionsFilter(unconfirmedTransactions);
            setTransactionsFilterName('unconfirmed')
        }else if(filter==='all'){
            setTransactionsFilter(transactionsItems)
            setTransactionsFilterName('all')
        }
    }
    

    // FUNCTION FOR GETTING USER TRANSACTION FROM SERVER 
    const getUserTransactions = useCallback(async () => {
        try {
          const userSessionToken = localStorage.getItem('userSessionToken');
          const response = await axios.post(`${API_Route}getUserTransactions`, {
            userSessionToken
          });
    
          setTransactionsItems(response.data);
          setTransactionsFilter(response.data);
    
          console.log(response.data);
        } catch (e) {
          const set_error_params = { position: "translateY(0%)", message: 'Something went wrong' };
          setError(set_error_params);
          await closeErrorModal();
        }
      }, []);
    
      useEffect(() => {
        const getUser = async () => {
            const userSessionToken = localStorage.getItem('userSessionToken');
            try {
                const response = await axios.post(`${API_Route}getUserProfile`, {
                    userSessionToken
                });
        
                if (response.status === 200) {
                    console.log(response.data);

                    // checking if the user email is activated
                    if(response.data.emailActivated){
                        setUserData(response.data);
                        setLoading(false);
                        getUserTransactions(); // Call getUserTransactions here
                    }else{
                        navigate('/verify-email')
                    }
                }
            } catch (error) {

                const set_error_params = { position: "translateY(0%)", message: 'Something went wrong' };
                setError(set_error_params);
                await closeErrorModal();


            // const set_error_params={position:"translateY(0%)",message:'Somthing went wrong'}
            //     setError(set_error_params);
            //     await closeErrorModal()
                // if(error.response.data===undefined){
                //     const set_error_params={position:"translateY(0%)",message:"Something went wrong"}
                //     setLoading(false)
                //     setError(set_error_params);
                //     await closeErrorModal()
                // }else{
                //     const set_error_params={position:"translateY(0%)",message:error.response.data.error}
                //     setLoading(false)
                //     setError(set_error_params);
                //     await closeErrorModal()
                // }
            }
        };
    
        getUser();
      }, [getUserTransactions, navigate]);

    
    return (
        <>
        <Helmet>
            <title>Dashboard</title>
        </Helmet>
            {loading
            ?<>
                <LoadingModalComponent showModal={loading} />
                <ErrorModalComponent delay="4000" position={error.position} message={error.message} />
            </>
            :<Container>
                <HeaderComponent/>
                <span style={{display:'none'}}>{error.message}</span>
                
                <InnerContainer>
                    <LeftContainer>
                        <UserProfileCon>
                            <UserProfileImageCon>
                                {userData&&<UserProfileImage src={userData.profilePicture}/>}
                            </UserProfileImageCon>

                            {userData&&<UserProfileName>{userData.fullName}</UserProfileName>}
                            {userData&&<UserProfileEmail>{userData.email}</UserProfileEmail>}
                            {/* <EditProfile className='transparent-click'><MdEdit /> Edit Profile</EditProfile> */}
                        </UserProfileCon>

                    </LeftContainer>

                    <RightContainer>

                        
                        <Dashboardtext>Transactions</Dashboardtext>

                        {transactionsItems.length
                        ?<FilterArea>
                            <CiFilter color="var(--lumina-text-color)" />
                            <FilterItem className='transparent-click' style={{fontWeight: transactionsFilterName==='all'?'600':'', color: transactionsFilterName==='all'?'var(--lumina-brand-color)':''}} onClick={()=>toggleConfirmedTransactions('all')}>All {transactionsFilterName==='all'&&<GrStatusGood />}</FilterItem>
                            <FilterItem className='transparent-click' style={{fontWeight: transactionsFilterName==='confirmed'?'600':'', color: transactionsFilterName==='confirmed'?'var(--lumina-brand-color)':''}} onClick={()=>toggleConfirmedTransactions('confirmed')}>Completed {transactionsFilterName==='confirmed'&&<GrStatusGood />}</FilterItem>
                            <FilterItem className='transparent-click' style={{fontWeight: transactionsFilterName==='unconfirmed'?'600':'', color: transactionsFilterName==='unconfirmed'?'var(--lumina-brand-color)':''}} onClick={()=>toggleConfirmedTransactions('unconfirmed')}>Pending {transactionsFilterName==='unconfirmed'&&<GrStatusGood />}</FilterItem>
                        </FilterArea>
                        :null}

                        {transactionsItems.length
                        ?<InnerRightContainer>
                            <TransactionsComponent setTransactionsFilter={setTransactionsFilter} transactionsFilter={transactionsFilter} transactionsItems={transactionsItems}/>
                        </InnerRightContainer>
                        :<div style={{padding:'10px'}}>
                            <NoatrsnactionText>No transactions yet. Click <Link className='transparent-click' style={{color:'var(--lumina-brand-color)'}} to='/app/swap'>here</Link> to swap your fiat for crypto.</NoatrsnactionText>
                            <NodataContainer>
                                <NodataImage src={EmptydataImage}/>
                            </NodataContainer>
                        </div>}

                    </RightContainer>
                </InnerContainer>
            </Container>}
        </>
        

            
            
        
        
    )
}

export default Dashboard


