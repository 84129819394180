
import styled from "styled-components";
import lumina_swap from "../assets/lumina_swap.png"


const SwapParentContainer= styled.div`
    // padding:40px;
    padding: 20px ;
    
    

    // @media screen and (max-width:1000px){
    //     margin-top:50px;
    // }

    // @media screen and (min-width:1000px){
    //     margin-top:60px;
    // }

    // @media screen and (max-width:900px){
    //     padding:35px;
    //     margin-top:30px;
    // }

    @media screen and (max-width:500px){
        margin-top:30px;
    }
`;

const SwapInfoContainer = styled.div `
    display:flex;
    width: 1200px;
    gap:100px;
    margin: auto;

    @media screen and (max-width:1250px){
        width: auto;
    }


    @media screen and (max-width:1000px){
        gap:80px;
    }

    @media screen and (max-width:900px){
        flex-direction:column;
        text-align:center;
        // gap:2em;
        justify-content:center;
        align-items:center;
    }
`;

const ImageContainer = styled.div`
    // // width:600px;

    // @media screen and (max-width:999px){
    //     width:300px;
    // }

    // @media screen and (max-width:1000px){
    //     width:390px;
    // }

    // @media screen and (min-width:1000px){
    //     width:450px;
    // }

    flex: 1;
`;
const SwapImageElement = styled.img`
    width: 100%;
    height: 100%;
`;

const SwapDetailsDiv = styled.h2 `
    flex: 1;
    display: flex;
    align-items: center;
    
`;
const SwapDetailsTxt = styled.h2 `
    // padding: 100px;
    // width: 380px;

    // @media screen and (max-width:999px){
    //     padding:15px;
    //     font-size:16px;
    // }

    // @media screen and (max-width:1000px){
    //     padding:20px;
    //     font-size: 18px;
    //     margin-top:50px;
    // }

    // @media screen and (min-width:1000px){
    //     font-size:20px;
    //     margin-top:-20px;
    // }

    color:var(--lumina-text-color);

    

`

const HighlightImportantWord= styled.span`
    color:var(--lumina-brand-color);
`


const SwapComponentMockUp = ()=>{
    return(
        <SwapParentContainer>
            <SwapInfoContainer>
                <ImageContainer>
                    <SwapImageElement src={lumina_swap} alt="handiswap image component" />
                </ImageContainer>
                <SwapDetailsDiv>
                    <SwapDetailsTxt><HighlightImportantWord>Swap</HighlightImportantWord> Any Crypto Directly With Naira At The Snap Of A Finger</SwapDetailsTxt>
                </SwapDetailsDiv>
                
            </SwapInfoContainer>
        </SwapParentContainer>
    )
}

export default SwapComponentMockUp