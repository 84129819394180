import styled from "styled-components"
import HeaderComponent from "../components/HeaderComponent";
// import "../Homepage.css"
// import Navbar from "../components/Navbar.Component.js"
// import CallToAction from "../components/CallToAction.Component.js"
import InfoSection from "../components/InfoSection.Component.js"
import FooterComponent from "../components/Footer.Component.js"
// import BottomNav from "../components/BottomNav.Component.js"
import BackgroundIMG from "../assets/luminabg.png";
import {Helmet} from "react-helmet"

const Container = styled.div``;

const BackgroundImageContainer = styled.div`
    height: 100vh;
    width: 99vw;
    overflow: hidden;
    position: absolute;
    z-index: -1;
`;


const BackgroundImage = styled.img`
    height: 100%;
    width: 100%;
`;



const Homepage = () => {
    return (
        <>
        <Helmet>
            <title>Handiswap | Home</title>
        </Helmet>
          <Container>
            <BackgroundImageContainer>
                <BackgroundImage src={BackgroundIMG}/>
            </BackgroundImageContainer>
            <HeaderComponent/>
            <InfoSection/>
            <FooterComponent/>
        </Container>
        </>
    )
}

export default Homepage
