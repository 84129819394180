import React from 'react'
import confirm_png from '../assets/confirm_png.png'
import styled,{keyframes} from "styled-components"

const ProcessingComponent = (props) => {

    const ProcessingContainer=styled.div`
    display:${props.isShowing};
    justify-content:center;
    align-items:center;
    padding: 150px 10px;
    `;

    const ProcessingImageContainer = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    gap:3em;
    text-align:center;
    align-items:center;
    `;

    const ProcessingDetailContainer = styled.p`
    color:var(--lumina-text-color);
    font-size:15px;
    width:150px;
    text-align:center;
    `;

    const translationMotion=keyframes`
    0% {
    transform:translateY(0px)
    }
    50%{
    transform:translateY(20px)
    }
    100%{
    transform:translateY(0px);
    }
`
    const ProcessingIcon=styled.img`
    width:90px;
    transition:linear,1000ms;
    animation:${translationMotion} 1s linear infinite;
    `;

    return (
        <>
        {/* processing container beginning */}
        <ProcessingContainer>
            <ProcessingImageContainer>
                <ProcessingIcon src={confirm_png} alt='processing_image' />
                <ProcessingDetailContainer>{props.data}</ProcessingDetailContainer>
            </ProcessingImageContainer>
        </ProcessingContainer>
        {/* processing container ending  */}
     </>
    )
}

export default ProcessingComponent;