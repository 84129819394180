import styled from "styled-components"
import { FaSearch } from "react-icons/fa";
import { IoMdMenu } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { GrTransaction } from "react-icons/gr";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { MdOutlineStickyNote2 } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
// import lumina_logo from "../assets/lumina_logo.png"
import lumina_logo from "../assets/logos/no_bg_white_logo_with_text.png"
import { LuLogOut } from "react-icons/lu";
import axios from "axios";
import { API_Route } from "../utils/Api";
import { LuLayoutDashboard } from "react-icons/lu";
import { FaAngleDown } from "react-icons/fa";
import { FaAngleUp } from "react-icons/fa";



// Header styles
const Header = styled.div`
    position: sticky;
    top: 0;
    z-index: 5;
    background: inherit !important;
    backdrop-filter: blur(10px) !important;
    -webkit-backdrop-filter: blur(10px) !important;
`;


const InnerContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px;
    width: 1200px;
    margin: auto;

    
    @media screen and (max-width: 1250px){
        width: auto;
    }


    @media screen and (max-width: 500px){
        padding: 10px;
    }
`;
const LeftHeader = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
`;
const RightHeader = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
`;

const InnerRightHeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;


    @media screen and (max-width: 900px){
        display: none;
    }
`;
const LogoContainer = styled.h1`
    color: var(--lumina-brand-color);
    font-size: 20px;
    letter-spacing: 2px;
    display: flex;
    align-items: center;
    gap: 10px;
`;
const LogoImageContainer = styled.div`
    width: 150px;
    // height: 30px;
    display: flex;

`;
const LogoImage = styled.img`
    width: 100%;
    height: 100%;
`;
// const LogoImage = styled.div``;
const MenuContainer = styled.ul`
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;

    @media screen and (max-width: 900px){
        display: ${(props) => (props.isOpen ? 'block' : 'none')};
        flex-direction: column;
        gap: 0;
    }

    
`;
const MenuItem = styled.div`
    cursor: pointer;
    color: var(--lumina-text-color);
    font-size: 14px;
    font-weight: 500;
    list-style: none;
    transition: 0s;
    display: flex;
    align-items: center;
    gap: 10px;

    @media screen and (max-width: 900px){
        padding: 15px;
    }
    


    &:hover{
        color: var(--lumina-brand-color);
    }

    

`;
const SearchContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    color: var(--lumina-text-color);
    padding: 8px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.1);
    cursor: pointer;
    // min-width: 200px;
    

    &:hover{
        background: rgba(255, 255, 255, 0.2);
    }

    // @media screen and (max-width: 900px){
    //     min-width: auto;
    // }


`;
// const SearchInput = styled.input`
//     flex: 1;
//     border: none;
//     background: none;
//     color: var(--lumina-text-color);
    
//     @media screen and (max-width: 900px){
//         display: none;
//     }
// `;

const LoginButton = styled.div`
    background: rgba(255, 255, 255, 0.1);
    cursor: pointer;
    padding: 8px 15px;
    color: var(--lumina-text-color);
    border-radius: 10px;
    font-size: 13px;
    font-weight: 600;
    text-align: center;

    &:hover{
        background: rgba(255, 255, 255, 0.2);
    }

    @media screen and (max-width: 900px){
        flex:1;
        padding: 15px;
    }
`;
const SignupButton = styled.div`
    background: var(--lumina-brand-color);
    cursor: pointer;
    padding: 8px 15px;
    border-radius: 10px;
    font-size: 13px;
    font-weight: 600;
    text-align: center;
    color: var(--lumina-text-black-color);
    


    @media screen and (max-width: 900px){
        flex:1;
        padding: 15px;
    }
`;
const BuycryptoButton = styled.div`
    background: var(--lumina-brand-color);
    cursor: pointer;
    padding: 10px 15px;
    border-radius: 10px;
    font-size: 13px;
    font-weight: 600;
    text-align: center;
    color: var(--lumina-text-black-color);

    @media screen and (max-width: 900px){
        flex:1;
        padding: 15px;
    }
`;

const MenuButton = styled.div`
    color: var(--lumina-text-color);
    display: none;
    

    @media screen and (max-width: 900px){
        display: block;
        margin-left: 20px;
        cursor: pointer;
    }
`;
const SlideInMenu = styled.div`
    display: none;
    
    @media screen and (max-width: 900px){
        padding: 20px;
        position: fixed;
        top: 0;
        bottom: 0;
        background: var(--lumina-bg-color);
        transition: 0.3s ease-in-out;
        width: 350px;
        z-index: 10;
        right: ${(props) => (props.isOpen ? '0' : '-900px')};
        display: flex;
        flex-direction: column;
        gap: 20px;
        overflow-y: auto;
    }

    @media screen and (max-width: 600px){
        left: ${(props) => (props.isOpen ? '0' : '900px')};
        width: auto;
    }
`;
const BlackDiv = styled.div`
    position: fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background: rgb(0,0,0,0.2);
    z-index:6;
    align-items:center;
    justify-content:center;
    display: flex;
    backdrop-filter: blur(.5px);
    opacity: ${(props) => (props.isVisible ? '1' : '0')};
    visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};
    transition: opacity 0.3s ease, visibility 0.3s ease;
`;

const RightHeaderItem = styled.li`
    list-style: none;
    // padding: 13px 15px;
    border-radius: 100%;
    // color: var(--lumina-text-color);
    // background: rgba(255, 255, 255, 0.1);
    // color: var(--lumina-brand-color);
    // background: var(--lumina-transparent-brand-color);
    
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    font-weight: 500;
    background: rgba(225, 225, 225, 0.1);
    width: 30px;
    height: 30px;
    flex:1;

    &:hover{
        // background: rgba(255, 255, 255, 0.2);
        // background: var(--lumina-transparent-brand-color-hover);
    }

    position: relative;
    overflow: hidden;

`;


const ImageShimer = styled.li`
    list-style: none;
    // padding: 13px 15px;
    border-radius: 100%;
    // color: var(--lumina-text-color);
    // background: rgba(255, 255, 255, 0.1);
    // color: var(--lumina-brand-color);
    // background: var(--lumina-transparent-brand-color);
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    font-weight: 500;
    background: rgba(225, 225, 225, 0.1);
    width: 30px;
    height: 30px;

    &:hover{
        // background: rgba(255, 255, 255, 0.2);
        // background: var(--lumina-transparent-brand-color-hover);
    }

    position: relative;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -150%;
        height: 100%;
        width: 150%;
        background: linear-gradient(
            90deg,
            transparent,
            rgba(255, 255, 255, 0.2),
            transparent
        );
        animation: shimmer 2s infinite;
    }

    @keyframes shimmer {
        0% {
            transform: translateX(-100%);
        }
        100% {
            transform: translateX(100%);
        }
    }
`;

const UserImage = styled.img`
    width: 100%;
    height: 100%;
`;

const FloatingMenu= styled.div`
    position: absolute;
    width: 150px;
    padding: 10px;
    border-radius: 10px;
    background: var(--lumina-text-color);
    top: 50px;
    right: 0px;
`;
const FloatingMenuItem= styled.div`
    padding: 10px;
    color: var(--lumina-text-black-color);
    cursor: pointer;
    font-size: 15px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 10px;
    // flex-wrap: wrap;
`;

const HeaderComponent = () => {
    const userSessionToken = localStorage.getItem('userSessionToken');
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [showSmallMenu, setShowSmallMenu] = useState(false);
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState({position:"translateY(-180%)",message:""});
    const [userData, setUserData] = useState({
        profilePicture: '',
        fullName: ''
    });

    const navigate = useNavigate();

    const toggleSideBar = () => {
        // document.body.style.overflow = 'hidden';
        setIsSidebarOpen(prev=>!prev);
        // if(isSidebarOpen){
        //     setIsSidebarOpen(false);
        // }else{
        //     setIsSidebarOpen(true);
        // }
    };

    // Hide the scroll bar when a modal is open
    if(isSidebarOpen){
        document.body.style.overflow = 'hidden';
    }else{
        document.body.style.overflow = '';
    }

    const logout = ()=>{
        localStorage.clear();
        navigate('/signin');
    }

    const toggleSmallMenu=()=>{
        setShowSmallMenu(prev=>!prev);
    }


    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 900) {
                setIsSidebarOpen(false);
            }
        };
    
        handleResize();
    
        window.addEventListener('resize', handleResize);
    
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    
    let closeErrorModal=async()=>{
        await setInterval(()=>{
            const set_error_params={position:"translateY(-180%)",message:""}
            setError(set_error_params)
        },4000)
        clearInterval()
    }

    


    useEffect(()=>{
        const getUser = async () => {
            const userSessionToken = localStorage.getItem('userSessionToken');
            try {
                const response = await axios.post(`${API_Route}getUserProfile`, {
                    userSessionToken
                });
        
                if (response.status === 200) {
                    console.log(response.data);
    
                    // checking if the user email is activated
                    if(response.data.emailActivated){
                        setUserData(response.data);
                        setLoading(false);
                    }else{
                        navigate('/verify-email')
                    }
                }
            } catch (error) {
    
                const set_error_params = { position: "translateY(0%)", message: 'Check your network' };
                setError(set_error_params);
                await closeErrorModal();
    
    
            }
        };

        getUser();
    },[navigate])
    return (
        <>
            <Header>

                <InnerContainer>

                    <LeftHeader>
                        <Link onClick={toggleSideBar} className="transparent-click" to='/'>
                            <LogoContainer>
                                <LogoImageContainer>
                                    <LogoImage alt='handiswap logo' src={lumina_logo}/>
                                </LogoImageContainer>
                                {/* Handiswap */}
                            </LogoContainer>
                        </Link>

                        <MenuContainer>
                            {userSessionToken&&<Link className="transparent-click" to='/app/swap'>
                                <MenuItem>Buy Crypto</MenuItem>
                            </Link>}
                            <Link onClick={toggleSideBar} className="transparent-click" to='/about'>
                                <MenuItem>About</MenuItem>
                            </Link>
                            <Link onClick={toggleSideBar} className="transparent-click" to='https://medium.com/@lumina.team247'>
                                <MenuItem>Blog</MenuItem>
                            </Link>
                            
                        </MenuContainer>
                    </LeftHeader>

                    <RightHeader>
                        <InnerRightHeaderContainer>
                            <SearchContainer>
                                {/* <SearchInput placeholder="Search for available coins"/> */}
                                <FaSearch size={15} />
                            </SearchContainer>

                            {userSessionToken
                            ?<>
                                <Link onClick={toggleSideBar} target="_blank" rel="noreferrer" className="transparent-click" to='/app/swap'>
                                    <BuycryptoButton>Launch App</BuycryptoButton>
                                </Link>

                                <div style={{position:'relative'}}>
                                    <div onClick={toggleSmallMenu} style={{display:'flex', alignItems:'center', gap:'10px', padding: '5px', borderRadius:'10px',border:'solid 1px var(--lumina-text-color)', cursor: 'pointer'}}>
                                        {loading
                                        ?<ImageShimer/>
                                        :<RightHeaderItem>
                                            <UserImage src={userData.profilePicture}/>
                                        </RightHeaderItem>}
                                        {showSmallMenu
                                        ?<FaAngleUp style={{color:'var(--lumina-text-color)'}} />
                                        :<FaAngleDown style={{color:'var(--lumina-text-color)'}} />}
                                    </div>
                                    
                                    {showSmallMenu&&<FloatingMenu>
                                        <Link onClick={toggleSideBar} className="transparent-click" to='/dashboard'>
                                            <FloatingMenuItem><LuLayoutDashboard />Dashboard</FloatingMenuItem>
                                        </Link>
                                        <FloatingMenuItem onClick={logout}><LuLogOut style={{transition:'0s'}}  />Log Out</FloatingMenuItem>
                                    </FloatingMenu>}
                                </div>
                            </>
                            :<>
                                <Link onClick={toggleSideBar} className="transparent-click" to='/signin'>
                                    <LoginButton>Log In</LoginButton>
                                </Link>
                                <Link onClick={toggleSideBar} className="transparent-click" to='/signup'>
                                    <SignupButton>Sign Up</SignupButton>
                                </Link>
                            </>}
                        </InnerRightHeaderContainer>

                        <MenuButton onClick={toggleSideBar}>
                            <IoMdMenu  size={30} />
                        </MenuButton>
                        
                    </RightHeader>
                </InnerContainer>
                
            </Header>

            <SlideInMenu isOpen={isSidebarOpen}>
                <MenuButton className="transparent-click" onClick={toggleSideBar} style={{textAlign:'right'}}>
                    <IoClose size={25}/>
                </MenuButton>
                
                <div style={{display:'flex', gap:'10px'}}>
                    {userSessionToken
                    ?<>
                        <Link onClick={toggleSideBar} target="_blank" rel="noreferrer" className="transparent-click" style={{flex:'1'}} to='/app/swap'>
                            <BuycryptoButton>Launch App</BuycryptoButton>
                        </Link>

                    </>
                    :<>
                        <Link onClick={toggleSideBar} className="transparent-click" style={{flex:'1'}} to='/signin'>
                            <LoginButton>Log In</LoginButton>
                        </Link>
                        <Link onClick={toggleSideBar} className="transparent-click" style={{flex:'1'}} to='/signup'>
                            <SignupButton>Sign Up</SignupButton>
                        </Link>
                    </>}



                </div>

                <MenuContainer isOpen={isSidebarOpen}>
                    {userSessionToken&&<Link onClick={toggleSideBar} className="transparent-click" to='/dashboard'>
                        <MenuItem><LuLayoutDashboard />Dashboard</MenuItem>
                    </Link>}
                    {userSessionToken&&<Link onClick={toggleSideBar} target="_blank" rel="noreferrer" className="transparent-click" to='/app/swap'>
                        <MenuItem><GrTransaction style={{transition:'0s'}}/>Buy Crypto</MenuItem>
                    </Link>}
                    <Link onClick={toggleSideBar} className="transparent-click" to='/about'>
                        <MenuItem><IoMdInformationCircleOutline style={{transition:'0s'}}/>About</MenuItem>
                    </Link>
                    <Link onClick={toggleSideBar} className="transparent-click" to='https://medium.com/@lumina.team247'>
                        <MenuItem><MdOutlineStickyNote2 style={{transition:'0s'}}/>Blog</MenuItem>
                    </Link>
                    {userSessionToken
                    ?<MenuItem onClick={logout}><LuLogOut style={{transition:'0s'}} />Log Out</MenuItem>
                    :null}
                </MenuContainer>
            </SlideInMenu>

            <BlackDiv onClick={toggleSideBar} isVisible={isSidebarOpen}/>

            <span style={{display:'none'}}>{error.message}</span>
            
        </>
    )
}

export default HeaderComponent
