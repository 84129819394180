import React from "react";
import styled from "styled-components";
import checkbox_icon from '../assets/checkbox_icon.png'

const ConfirmComponent=(props)=>{

    const ConfirmedContainer = styled.div`
     justify-content:center;
    align-items:center;
    padding: 160px 10px;
    display:${props.isShowing};
    `;
    const ConfirmedImageContainer = styled.div`
    display:flex;
    text-align:center;
    color:var(--lumina-text-color);
    flex-direction:column;
    gap:1em;
    `;

    const ConfirmedImage = styled.img `
    width:100px;
    transition:linear,2000ms;
    transform:${props.imageScale};
    `;

    const ConfirmedDetail = styled.p `
    font-size:15px;
    `;

return (
    <>
    <ConfirmedContainer>
        <ConfirmedImageContainer>
            <ConfirmedImage  src={checkbox_icon} alt='checkbox_image' />
            <ConfirmedDetail>{props.data}</ConfirmedDetail>
        </ConfirmedImageContainer>
    </ConfirmedContainer>
    </>
)
}

export default ConfirmComponent;