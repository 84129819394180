import styled from "styled-components"
import Handiswap_logo from "../assets/logos/no_bg_white_logo_with_text.png"
import { FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";
import { Link } from "react-router-dom";

// import footer_policy from "../assets/footer_policy.png"
// import footer_support from "../assets/footer_support.png"
// import footer_privacy from "../assets/footer_privacy.png"
// import footer_discord from "../assets/footer_discord.png"
// import footer_instagram from "../assets/footer_instagram.png"
// import footer_x from "../assets/footer_x.png"

const Container = styled.div`
    background-color: var(--lumina-modal-bg-color);
    color: var(--lumina-text-color);
    // padding: 20px 10px;

`;

const InnerContainer = styled.div`
    width: 1200px;
    margin: auto;
    padding: 20px;


    @media screen and (max-width:1250px){
        width: auto;
    }
`;
const LogoArea = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
    // margin-left: 30px;
    margin-top: 10px;


    h1{
        font-size: 20px;
        
    }

`;

const MakingCryptoAccessibleText = styled.div`
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 20px;
    // margin-left: 30px;


    @media (max-width: 768px) {
        // margin-left: 10px;
    }
`;
const LogoContainer = styled.div`
    width: 150px;
    // height: 25px;
    display: flex;
`;
const LogoImage = styled.img`
    width: 100%;
    height: 100%;
`;

const FooterContainer = styled.footer`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    // padding: 10px;

    @media (max-width: 768px) {
        flex-direction: column;
        
    }
`;

const FooterSection = styled.div`
    flex: 1;
    margin: 10px 0px;
    
    @media (max-width: 768px) {
        margin: 20px 0;
        
    }

    h3 {
        font-size: 15px;
        margin-bottom: 15px;
        color: var(--lumina-text-color);
    }

    p, ul {
        font-size: 13px;
        line-height: 1.6;
    }

    ul {
        list-style: none;
        padding: 0;
    }

    li {
        // margin: 5px 0;
    }

    a {
        color: var(--lumina-text-color);
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
`;

const SocialIcons = styled.div`
    display: flex;
    gap: 15px;
    font-size: 24px;
`;




const FooterComponent = () => {
    return (
        <Container>

            <InnerContainer>
            
              
                <FooterContainer>
                    {/* Community Section */}
                    <FooterSection>
                        <h3>Community</h3>
                        <SocialIcons>
                            <a href="https://facebook.com">
                                <FaFacebook />
                            </a>
                            <a href="https://twitter.com">
                                <FaTwitter />
                            </a>
                            <a href="https://instagram.com">
                                <FaInstagram />
                            </a>
                        </SocialIcons>
                    </FooterSection>

                    {/* Products Section */}
                    <FooterSection>
                        <h3>Products</h3>
                        <ul>
                        <li><Link target="_blank" rel='noreferrer' to="/app/swap">Buy Crypto</Link></li>
                        {/* <li><a href="/product2">Sell Crypto</a></li> */}
                        </ul>
                    </FooterSection>

                    {/* About Us Section */}
                    <FooterSection>
                        <h3>About Us</h3>
                        <p>Company</p>
                        <p>Careers</p>
                    </FooterSection>

                    {/* About Us Section */}
                    <FooterSection>
                        <h3>Legal</h3>
                        <p>Terms of use</p>
                        <p>Privacy Policy</p>
                    </FooterSection>

                    {/* 24/7 Support Section */}
                    <FooterSection>
                        <h3>Support</h3>
                        <p>Contact our support team anytime for assistance.</p>
                        <p>Email: support@Handiswap.com</p>
                        <p>Phone: +1 800 123 456</p>
                    </FooterSection>


                </FooterContainer>

                <LogoArea>
                    <LogoContainer>
                        <LogoImage alt='handiswap logo' src={Handiswap_logo}/>
                    </LogoContainer>
                </LogoArea>

                <MakingCryptoAccessibleText>Making Crypto Accessible Seamlessly</MakingCryptoAccessibleText>

                {/* <div style={{background:'var(--lumina-grey-color)', padding:'.1px'}}/> */}
                <p style={{color:'var(--lumina-grey-color)', fontSize:'13px', marginTop:'20px'}}>© 2024 Handiswap. All rights reserved.</p>
                
            </InnerContainer>

        </Container>
    )
}

export default FooterComponent

// <>
        // <FooterContainer className="footer_container">
        //     <div class="Handiswap_footer_sub_container">
        //     {/* footer policy  */}
        //     <FooterParentContainer className="footer_parent_container">
        //         <FooterPolicyContainer className="footer_policy_container">
        //             <img alt='icon' src={footer_policy} className="footer_terms_icon" />
        //             <img alt='icon' src={footer_support} className="footer_terms_icon" />
        //             <img alt='icon' src={footer_privacy} className="footer_terms_icon" />
        //         </FooterPolicyContainer>

        //         <FooterDetailContainer className="footer_detail_container">
        //             <div className="footerLogoContainer">
        //                 <img alt='icon' src={Handiswap_logo} className="Handiswap_footer_logo" />
        //                 <h3>Handiswap</h3>
        //             </div>
        //             <p className="footerLogoDetails">
        //                 Making Crypto Accessible Seamlessly
        //             </p>
        //         </FooterDetailContainer>

        //     </FooterParentContainer>
          
        //     {/*  footer socials  */}
        //     <FooterSocialContainer className="footer_social_container">
        //     <img alt='icon' src={footer_instagram} className="footer_social_icon" />
        //     <img alt='icon' src={footer_x} className="footer_social_icon" />
        //     <img alt='icon' src={footer_discord} className="footer_social_icon" />
        //     </FooterSocialContainer>

        //     </div>

        //     <footerCheckMarkContainer className="footer_checkmark_container">
        //     <p className="footer_check_mark_details">Made with Love by Handiswap hq</p>
        //     </footerCheckMarkContainer>         
        // </FooterContainer>
        // </>