import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components';
import ArrowBackIcon from "../assets/arrow-back-icon.svg"
import InfoIcon from "../assets/info-icon.svg"
import SheildIcon from "../assets/shield-icon.svg"
import axios from 'axios';
import { API_Route } from '../utils/Api';
import LoadingModalComponent from './LoadingModal.Component';


const solanaAddressRegex = /^([1-9A-HJ-NP-Za-km-z]{32,44})$/;
const tonAddressRegex = /^([A-Za-z0-9_-]{48}|[A-Za-z0-9_-]{66})$/;
const ethAddressRegex = /^0x[a-fA-F0-9]{40}$/;


const isValidSolanaAddress = (address) => solanaAddressRegex.test(address);
const isValidTONAddress = (address) => tonAddressRegex.test(address);
const isValidBNBAddress = (address) => ethAddressRegex.test(address);

const Container = styled.div``;

const BodyContainer = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media screen and (max-width: 900px){
        gap: 25px;
    }
`;
const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 2px var(--lumina-border-line-color);
    padding: 20px;
`;
const HeaderIcons = styled.img`
    width: 15px;
    height: 15px;
    cursor: pointer;
`;
const HeaderText = styled.h2`
    color: var(--lumina-text-color);
    font-size: 15px;
    font-weight: 500;
`;



// Wallets styles start here
const WalletsContainer = styled.div`
    max-height: 200px;
    overflow-y: scroll;

    /* Scrollbar styles */
    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background: var(--lumina-text-box-color);
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }



`;
const WalletItem = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 5px;
    margin-right: 10px;
    border-radius: 10px;
    cursor: pointer;

    &:hover{
        background: var(--lumina-text-box-color);
    }
`;
const WalletImageAndTextCon = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    cursor: pointer;
`;

const WalletTextCon = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
`;
const WalletName = styled.h2`
    color: var(--lumina-text-color);
    font-size: 13px;

`;
const WalletAddress = styled.h4`
    color: var(--lumina-text-color);
    font-size: 13px;
    font-weight: 500;

  
`;



const Description = styled.h4`
    color: var(--lumina-text-color);
    font-size: 10px;
    font-weight: 500;
    text-align: center;

    @media screen and (max-width: 750px){
        font-size: 12px;
    }
`;

const ActionButton = styled.button`
    color: var(--lumina-text-black-color);
    background: var(--lumina-brand-color);
    font-size: 13px;
    border-radius: 10px;
    padding: 10px 15px;
    cursor: pointer;
    text-align: center;
    width: 100%;
    font-weight: 600;

    @media screen and (max-width: 750px){
        padding: 15px 30px;
        font-size: 15px;
    }
`;

const SecuredByText = styled.h4`
    color: var(--lumina-text-color);
    font-size: 11px;
    display: flex;
    align-items: center;
    gap: 10px;
    margin: auto;

`;
const SheildIconImg = styled.img`
    width: 20px;
    height: 20px;
`;

const NoWalletsCon = styled.div`
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const NoWalletsText = styled.h2`
    color: var(--lumina-text-color);
    font-size: 12px;
    text-align: center;
`;








// New wallet inputs and add button ================================================================================================
const NewWalletContainer = styled.form`
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    // height: 200px;

    @media screen and (max-width: 900px){
        gap: 25px;
    }
`;
const NewWalletInputLabel = styled.label`
    color: var(--lumina-text-color);
    font-size: 12px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    gap: 5px;

    @media screen and (max-width: 750px){
        font-size: 15px;
    }
`;
const NewWalletInputContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    background: var(--lumina-text-box-color);
    padding: 10px;
    border-radius: 10px;

    @media screen and (max-width: 750px){
        padding: 15px;
        font-size: 15px;
    }
`;
const NewWalletInput = styled.input`
    /* Hide up and down arrows in number input */
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    & {
        -moz-appearance: textfield; /* Firefox */
    }
    
    background: none;
    outline: none;
    border: none;
    color: var(--lumina-text-color);
    flex: 1;
    font-size: 16px;

`;

const ShimmeringWalletsContainer = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    height: 250px;
    overflow-y: scroll;


    

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background: var(--lumina-text-box-color);
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    @media screen and (max-width: 500px){
        height: 350px;
    }
`;
const ShimmeringItem = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
`;

const ShimmeringNameAndAddressCon = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;
const ShimmeringName = styled.div`
    padding: 5px;
    border-radius: 10px;
    background: var(--lumina-text-box-color);
    width: 100px;

    position: relative;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -150%;
        height: 100%;
        width: 150%;
        background: linear-gradient(
            90deg,
            transparent,
            rgba(255, 255, 255, 0.2),
            transparent
        );
        animation: shimmer 2s infinite;
    }

    @keyframes shimmer {
        0% {
            transform: translateX(-100%);
        }
        100% {
            transform: translateX(100%);
        }
    }
`;
const ShimmeringAddress = styled.div`
    padding: 5px;
    border-radius: 10px;
    background: var(--lumina-text-box-color);
    width: 150px;

    position: relative;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -150%;
        height: 100%;
        width: 150%;
        background: linear-gradient(
            90deg,
            transparent,
            rgba(255, 255, 255, 0.2),
            transparent
        );
        animation: shimmer 2s infinite;
    }

    @keyframes shimmer {
        0% {
            transform: translateX(-100%);
        }
        100% {
            transform: translateX(100%);
        }
    }
`;

const ResponseText = styled.div`
    font-size: 13px;
    // margin-top: 10px;
    font-weight: 500;
    color: red;
    text-align: left;
`;

function shortenTextWithParentheses(text, maxLength) {
    // Check if text is undefined or not a string
    if (typeof text !== 'string' || !text) {
      return ''; // Return an empty string if text is invalid
    }
  
    // Check if text length is already less than or equal to maxLength
    if (text.length <= maxLength) {
      return text; // Return the original text if it's already short enough
    }
  
    // Calculate the length of the first half of the text (excluding parentheses and ellipsis)
    const firstHalfLength = Math.floor((maxLength - 5) / 2); // -5 to account for "(...)"
  
    // Calculate the length of the second half of the text (excluding parentheses and ellipsis)
    const secondHalfLength = Math.ceil((maxLength - 5) / 2); // -5 to account for "(...)"
  
    // Extract the first and last parts of the text
    const firstHalf = text.slice(0, firstHalfLength);
    const secondHalf = text.slice(-secondHalfLength);
  
    // Construct the shortened text with parentheses in the middle
    const shortenedText = `${firstHalf}...${secondHalf}`;
  
    return shortenedText;
}


const WalletsComponent = ({toggleModal, setSelectedBlockchainAddress, setSelectedBlockchain, selectedBlockchain}) => {

    const [showNewBlockChainInputs, setShowNewBlockChainInputs] = useState(false);
    const [newUserWalletAddress, setnewUserWalletAddress] = useState('');
    const [newUserWalletName, setnewUserWalletName] = useState('');
    const [loadingWallets, setLoadingWallets] = useState(true);
    const [error, setError] = useState('');
    const [savingLoader, setSavingLoader] = useState(false);

    const [wallets, setWallets] = useState([]);
    const loadingLength = Array.from({length: 10})

// Filtering the wallets to show only wallets related to the selected blockchain ================================================================================================


    const goBackButton = ()=>{
        setnewUserWalletAddress('')
        setError('')

        if(showNewBlockChainInputs){
            setShowNewBlockChainInputs(false)
        }else{
            toggleModal('wallets')
        }
    }

    const fetchUserWallets = useCallback( async()=>{
        setLoadingWallets(true);
        const userSessionToken = localStorage.getItem('userSessionToken');

        try {
            const response = await axios.post(`${API_Route}getUserProfile`,{
                userSessionToken,
            })

            if(response.status===200){
                const wallets = response.data.wallets;

                const filteredWallets = wallets.filter((wallet)=> wallet.wallet_blockchain === selectedBlockchain)
                setWallets(filteredWallets);
            }
            
            setLoadingWallets(false);
        } catch (error) {
            
        }
    },[selectedBlockchain])


    // saving a new wallet function
    const saveWallet= async(e)=>{
        e.preventDefault();
        setSavingLoader(true);
        

        if(newUserWalletAddress && newUserWalletName){
            
            const userSessionToken = localStorage.getItem('userSessionToken');
            
            if(selectedBlockchain==='TON'){
                const walletResult = isValidTONAddress(newUserWalletAddress);

                if(walletResult){
                    try {
                        const response = await axios.post(`${API_Route}saveWalletAddress`,{
                            userSessionToken,
                            wallet_name: newUserWalletName,
                            wallet_blockchain: selectedBlockchain,
                            wallet_address: newUserWalletAddress
                        });
        
                        if(response.status===200){
                            setShowNewBlockChainInputs(false)
                            fetchUserWallets();
                            setSavingLoader(false);
                            setnewUserWalletAddress('')
                            setnewUserWalletName('')

                        }
                    } catch (error) {
                        setSavingLoader(false);
                        setError('Something went wrong')
                    }
                }else{
                    setError('Invalid TON address')
                    setSavingLoader(false);
                }

            }else if(selectedBlockchain==='SOL'){

                const walletResult = isValidSolanaAddress(newUserWalletAddress);

                if(walletResult){
                    try {
                        const response = await axios.post(`${API_Route}saveWalletAddress`,{
                            userSessionToken,
                            wallet_name: newUserWalletName,
                            wallet_blockchain: selectedBlockchain,
                            wallet_address: newUserWalletAddress
                        });
        
                        if(response.status===200){
                            setShowNewBlockChainInputs(false)
                            fetchUserWallets();
                            setSavingLoader(false);
                            setnewUserWalletAddress('')
                            setnewUserWalletName('')
                        }
                    } catch (error) {
                        setSavingLoader(false);
                        setError('Something went wrong')
                    }
                }else{
                    setError('Invalid SOL address')
                    setSavingLoader(false);
                }

            }else if(selectedBlockchain==='BNB'){   

                const walletResult = isValidBNBAddress(newUserWalletAddress);

                if(walletResult){
                    try {
                        const response = await axios.post(`${API_Route}saveWalletAddress`,{
                            userSessionToken,
                            wallet_name: newUserWalletName,
                            wallet_blockchain: selectedBlockchain,
                            wallet_address: newUserWalletAddress
                        });
        
                        if(response.status===200){
                            setShowNewBlockChainInputs(false)
                            fetchUserWallets();
                            setSavingLoader(false);
                            setnewUserWalletAddress('')
                            setnewUserWalletName('')
                        }
                    } catch (error) {
                        setSavingLoader(false);
                        setError('Something went wrong')
                    }
                }else{
                    setError('Invalid BNB address')
                    setSavingLoader(false);
                }
            }else if(selectedBlockchain==='MATIC'){

            }
        }else{
            setSavingLoader(false);
            setError(`Wallet address and name cannot be empty`)
        }
    }

    useEffect(()=>{
        fetchUserWallets();
    },[fetchUserWallets])

    return (
        <Container>
            <LoadingModalComponent showModal={savingLoader}/>
            <Header>
                {/* use the state to know what page to go back to ================================================================================================ */}
                <HeaderIcons className='transparent-click' onClick={()=>goBackButton()} src={ArrowBackIcon}/>
                <HeaderText>{!showNewBlockChainInputs?`Select a ${selectedBlockchain} wallet`:`Save your ${selectedBlockchain} wallet`}</HeaderText>
                <HeaderIcons src={InfoIcon}/>
            </Header>

            {/* show the wallets here ================================================================================================ */}

            {/* check if wallets are loading and show shimering  */}
            {loadingWallets?
                <ShimmeringWalletsContainer>
                    {loadingLength.map((_, index)=>(
                        <ShimmeringItem key={index}>
                            {/* <ShimmeringImage/> */}
                            <ShimmeringNameAndAddressCon>
                                <ShimmeringName/>
                                <ShimmeringAddress/>
                            </ShimmeringNameAndAddressCon>
                        </ShimmeringItem>
                    ))}
                   

                </ShimmeringWalletsContainer>
            :<>
                {!showNewBlockChainInputs
                ?<BodyContainer>
                    {/* under the header ================================================================================================ */}

                    {wallets.length
                    ?<WalletsContainer>
                            {wallets.map((wallet, index)=>(
                                <WalletItem key={index} className='transparent-click' onClick={()=>{
                                    setSelectedBlockchainAddress(wallet.wallet_address)
                                    setSelectedBlockchain(wallet.wallet_blockchain)
                                    toggleModal('wallets')
                                }}>
                                    <WalletImageAndTextCon>
                                        {/* <WalletBigNameImageCon>
                                            {wallet.wallet_name.charAt(0)}
                                            <WalletFloatingImageCon>
                                                <WalletFloatingImage src={wallet.image}/>
                                            </WalletFloatingImageCon>
                                        </WalletBigNameImageCon> */}

                                        <WalletTextCon>
                                            <WalletName>{wallet.wallet_name}</WalletName>
                                            <WalletAddress>{shortenTextWithParentheses(wallet.wallet_address, 25)}</WalletAddress>
                                        </WalletTextCon>
                                    </WalletImageAndTextCon>

                                    {/* <EditButton>Edit</EditButton> */}
                                </WalletItem>
                            ))}
                    </WalletsContainer>

                    :<NoWalletsCon>
                        <NoWalletsText>You have no available {selectedBlockchain} wallet</NoWalletsText>
                    </NoWalletsCon>}

                    <Description>Adding a wallet makes your transaction seamless</Description>

                    <ActionButton className='transparent-click' onClick={()=>{setShowNewBlockChainInputs(true)}}>New {selectedBlockchain} Wallet</ActionButton>

                    <SecuredByText><SheildIconImg src={SheildIcon}/> Secured by <span style={{color: "var(--lumina-brand-color)"}}>Lumina</span></SecuredByText>
                </BodyContainer>

                // display the new wallet add inputs ================================================================================================
                :<NewWalletContainer onSubmit={saveWallet}>
                    <NewWalletInputLabel>
                        {selectedBlockchain} Wallet Name
                        <NewWalletInputContainer>
                            <NewWalletInput placeholder={`Give your ${selectedBlockchain} wallet a name`} type='text' value={newUserWalletName} onChange={(e)=>{
                                setnewUserWalletName(e.target.value);
                                setError('')
                            }}/>
                        </NewWalletInputContainer>
                    </NewWalletInputLabel>

                    <NewWalletInputLabel>
                        {selectedBlockchain} Address
                        <NewWalletInputContainer>
                            <NewWalletInput placeholder={`Enter your ${selectedBlockchain} address`} type='text' value={newUserWalletAddress} onChange={(e)=>{
                                setnewUserWalletAddress(e.target.value);
                                setError('')
                            }}/>
                        </NewWalletInputContainer>
                    </NewWalletInputLabel>
                    {error&&<ResponseText>{error}</ResponseText>}

                    <ActionButton type='submit' className='transparent-click' style={{cursor: newUserWalletAddress && newUserWalletName?'pointer':'not-allowed'}} onClick={saveWallet}>Save wallet</ActionButton>
                </NewWalletContainer>}
            </>}
            {/* end of loading  */}
            
            

            

            {/* display the blockchains list to select from to create a new wallet ================================================================================================ */}
            {/* <AddwalletContainer>
                <BlockchainsContainer>
                {blockchains.map((blockchain)=>(
                        <BlockchainItem onClick={()=>{setShowNewBlockChainInputs(true); setNewSelectedBlockChain(blockchain.blockchain); setShowAddBlochainsState(false)}}>
                            <BlockchainImageContainer>
                                <BlockchainImage src={blockchain.image}/>
                            </BlockchainImageContainer>

                            <BlockchainName>{blockchain.name}</BlockchainName>
                        </BlockchainItem>
                    ))}
                </BlockchainsContainer>
            </AddwalletContainer> */}


        </Container>
    )
}

export default WalletsComponent