import styled from "styled-components";
import CryptoPeopleImage from "../assets/people_crypto_image.jpeg";
import { useRef } from "react";
import { FaFacebook, FaTwitter, FaInstagram,  } from "react-icons/fa";
import FooterComponent from "../components/Footer.Component";
// import fileDownload from 'js-file-download'
// import axios from "axios";
import chuks from "../assets/chuks.jpg"
import { FiExternalLink } from "react-icons/fi";
import xmucan from "../assets/xmucan.jpg"
import HeaderComponent from "../components/HeaderComponent";
import {Helmet} from "react-helmet"

const Container = styled.div`

`;


const TopSectionContainer = styled.div`
    background: var(--lumina-modal-bg-color);
    padding: 50px 0px;

    @media screen and (max-width: 1250px){
        padding: 50px 20px;
    }
`;

const InnerTopSectionContainer = styled.div`
    width: 1200px;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;


    @media screen and (max-width: 1250px){
        width: auto;
    }
    
`;
const AboutHeaderSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const AboutHeaderItem = styled.h4`
    color: var(--lumina-text-color);
    margin-bottom: 30px;
    display: flex;
    gap: 10px;
    align-items: center;
`;
const AboutSection = styled.div`
    display: flex;
    gap: 20px;

    @media screen and (max-width: 890px){
        flex-direction: column;
    }
`;
const AboutSectionTextContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;
const AboutSectionAboutText = styled.h4`
    color: var(--lumina-brand-color);
`;
const AboutSectionMainText = styled.h1`
    color: var(--lumina-text-color);
`;
const AboutSectionsText = styled.p`
    color: var(--lumina-grey-color);
`;
const AboutSectionImageContainer = styled.div`
    flex: 1;
    max-width: 100%;  
    max-height: 400px;
    border-radius: 10px;
    overflow: hidden;  
    box-shadow: 5px 5px 10px 10px rgba(255, 255, 255, 0.1);

    @media screen and (max-width: 1200px) {
        max-height: 350px;
    }

    @media screen and (max-width: 768px) {
        height: 250px;  
    }
`;

const AboutSectionImage = styled.img`
    width: 100%;
    height: 100%;  
    border-radius: 10px;
    // object-fit: cover;  // Ensures the image fills the container without distortion
`;


const Labels = styled.h3`
    color: var(--lumina-text-color);
    margin-top: 30px;
`;


const FactsContainer = styled.div`
    display: flex;
    gap: 50px;

    @media screen and (max-width: 800px){
        flex-direction: column;
    }
`;
const FactsItem = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1;
`;
const FactsTitle = styled.h4`
    color: var(--lumina-brand-color);
    border-left: solid 0.5px var(--lumina-brand-color);
    padding-left: 10px;
`;
const FactsDescription = styled.p`
    color: var(--lumina-grey-color);
    font-size: 14px;
    padding-left: 10px;
`;

const SecondSecondContainer = styled.div`
    width: 1200px;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 50px 0px;

    @media screen and (max-width: 1250px){
        width: auto;
        padding: 50px 20px;
    }
`;

const BigLabel = styled.h1`
    color: var(--lumina-text-color);
`;

const LeaderShipSection = styled.div`
    display: flex;
    gap: 20px;
    position: relative;
    justify-content: space-between;

    &::-webkit-scrollbar {
        width: 0 !important;
        height: 0 !important;
    }

    @media screen and (max-width: 800px) {
        overflow-x: auto;
        scroll-behavior: smooth;
        -webkit-overflow-scrolling: touch;
    }
`;

const LeaderShipItem = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    // flex: 1;
    width: 250px;

    @media screen and (max-width: 800px) {
        width: 350px;
        flex: none;
    }
`;

const LeaderShipImageCon = styled.div`
    width: 100%;
    height: 250px;
    border-radius: 10px;
    overflow: hidden;
    background: var(--lumina-modal-bg-color);

    @media screen and (max-width: 1200px) {
        height: 300px;
    }

    @media screen and (max-width: 768px) {
        height: 400px;
    }

    @media screen and (max-width: 500px) {
        height: 300px;
        width: 300px;
    }

    
`;

const SliderButtonCon = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: right;
`;
const SliderButton = styled.button`
    top: 50%;
    transform: translateY(-50%);
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    gap: 3px;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.6);
    font-size: 12px;
    font-weight: 500;
    background: rgba(255, 255, 255, 0.1);

    &:hover{
        gap: 5px;
        background: rgba(255, 255, 255, 0.2);
    }

    &.prev {
        left: 0;
    }

    &.next {
        right: 0;
    }

    @media screen and (min-width: 800px) {
        display: none;
    }
`;

const LeaderShipImage = styled.img`
    width: 100%;
    height: 100%;
`;
const LeaderShipName = styled.h3`
    color: var(--lumina-text-color);
    font-size: 15px;
`;

const LeaderShipTitle = styled.p`
    color: var(--lumina-grey-color);
    font-size: 15px;
    font-weight: 500;
`;
const SocialIcons = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
`;
const SocialIcon = styled.div`
    color: var(--lumina-text-color);
    cursor: pointer;
`;


const leadershipItems = [
    {
        image: xmucan,
        name: 'XMUCAN',
        title: 'Cofounder and CEO',
        twitterLink: '',
        instagramLink: '',
        emailLink: '',
    },
    {
        image: chuks,
        name: 'Chucks',
        title: 'Cofounder and CTO',
        twitterLink: '',
        instagramLink: '',
        emailLink: '',
    },
    {
        image: CryptoPeopleImage,
        name: 'Ojakominor Martins',
        title: 'Cofounder and COO',
        twitterLink: '',
        instagramLink: '',
        emailLink: '',
    },
    
]

const AboutPage = () => {

    const sliderRef = useRef(null);

    const scrollLeft = () => {
        if (sliderRef.current) {
            sliderRef.current.scrollLeft -= 350; // Adjust according to your item width
        }
    };

    const scrollRight = () => {
        if (sliderRef.current) {
            sliderRef.current.scrollLeft += 350;
        }
    };

  
    return (
        <>
        <Helmet>
            <title>
                Handiswap | About
            </title>
        </Helmet>
        <Container>
            <HeaderComponent/>

            <TopSectionContainer>
                {/* <Header>
                    <LeftHeader>
                        <LeftHeaderItem>handiswap</LeftHeaderItem>
                        <LeftHeaderItem>handiswap</LeftHeaderItem>
                        <LeftHeaderItem>handiswap</LeftHeaderItem>
                        <LeftHeaderItem>handiswap</LeftHeaderItem>
                    </LeftHeader>
                </Header> */}
                <InnerTopSectionContainer>

                    <AboutHeaderSection>
                        <AboutHeaderItem>Everything about <span style={{color:'var(--lumina-brand-color)'}}>Handiswap</span></AboutHeaderItem>
                        <AboutHeaderItem style={{cursor:'pointer'}}>Blog <FiExternalLink /></AboutHeaderItem>
                    </AboutHeaderSection>


                    <AboutSection>
                        <AboutSectionTextContainer>
                            <AboutSectionAboutText>About Handiswap</AboutSectionAboutText>
                            
                            <AboutSectionMainText>Our mission at HandiSwap is to allow people around the world to afford any cryptocurrency of their choice for as little as <span style={{color:'var(--lumina-brand-color)'}}>$1</span></AboutSectionMainText>

                            <AboutSectionsText>
                                This will enable many individuals to own cryptocurrency without the burden of needing $10, which is the minimum requirement on most centralized exchanges.<br/><br/>

                                With HandiSwap, you don’t need $10 or $20 to start buying or practicing DCA (dollar-cost averaging). You can use HandiSwap to purchase your favorite crypto daily, weekly, or monthly with as little as $1. This approach helps you buy at different prices comfortably, increasing your chances of long-term profitability. <br/><br/>

                                If you ever want to save and don't want to wait until you have $10 or $20 to buy stablecoins like USDT, USDC, or BUSD, HandiSwap allows you to buy regularly for $1 and have it sent to your wallet within 30 seconds.
                            </AboutSectionsText>
                        </AboutSectionTextContainer>

                        <AboutSectionImageContainer>
                            <AboutSectionImage src={CryptoPeopleImage} alt="Crypto People Image" />
                        </AboutSectionImageContainer>
                    </AboutSection>

                    <Labels>Facts about Handiswap</Labels>
                    <FactsContainer>
                        <FactsItem>
                            <FactsTitle>4+</FactsTitle>
                            <FactsDescription>Crypto coins currently availabe for sale on Handiswap.</FactsDescription>
                        </FactsItem>

                        <FactsItem>
                            <FactsTitle>30 seconds</FactsTitle>
                            <FactsDescription>The amount of time it takes for your purchased crypto to reflect in your wallet.</FactsDescription>
                        </FactsItem>

                        <FactsItem>
                            <FactsTitle>50+</FactsTitle>
                            <FactsDescription>Countries where Handiswap is currently available in.</FactsDescription>
                        </FactsItem>


                    </FactsContainer>

                </InnerTopSectionContainer>
            </TopSectionContainer>


            <SecondSecondContainer >
                <BigLabel>Leadership</BigLabel>

                <SliderButtonCon>
                    <SliderButton className="prev" onClick={scrollLeft}>←</SliderButton>
                    <SliderButton className="next" onClick={scrollRight}>→</SliderButton>
                </SliderButtonCon>
                
                <LeaderShipSection ref={sliderRef}>
                    {leadershipItems.map((leader, index)=>(<LeaderShipItem>
                        <LeaderShipImageCon key={index}>
                            <LeaderShipImage src={leader.image} alt='Cofounder and CEO' />
                            </LeaderShipImageCon>

                            <LeaderShipName>{leader.name}</LeaderShipName>
                            <LeaderShipTitle>{leader.title}</LeaderShipTitle>

                            <SocialIcons>
                            <SocialIcon>
                                    <FaFacebook />
                                </SocialIcon>
                                <SocialIcon>
                                    <FaTwitter />
                                </SocialIcon>
                                <SocialIcon>
                                    <FaInstagram />
                                </SocialIcon>
                            </SocialIcons>
                        </LeaderShipItem>))}
                    
                </LeaderShipSection>
            </SecondSecondContainer>


            {/* <ThirdSectionContainer>
                <BigLabel>Media Assets</BigLabel>

                

                
                <BigLabel onClick={()=>handleDownload('http://localhost:3000/static/media/people_crypto_image.3c5bf3d18392130e4c8b.jpeg', 'handiswap_logo.jpeg')}>Download</BigLabel>


            </ThirdSectionContainer> */}


            <FooterComponent/>
        </Container>
        </>
    )
}

export default AboutPage












// import styled,{keyframes} from "styled-components"
// import HeaderComponent from "../components/HeaderComponent"
// import MissionStatementComponent from "../components/MissionStatementComponent"
// import lumina_about_icon from "../assets/lumina_about_icon.png"
// import FooterComponent from "../components/Footer.Component";
// import chuks from "../assets/chuks.jpg"
// import xmucan from "../assets/xmucan.jpg"
// import earth from "../assets/earth.jpg"

// const AboutParentContainer = styled.div`
// padding:10px;
// margin-left:150px;
// color:var(--lumina-text-color);
// display:flex;
// flex-direction:column;
// justify-content:center;
// gap:5em;

// @media screen and (max-width:900px){
// padding:10px;
// margin-left:10px;
// font-size:15px;
// gap:3em;
// }
// `;


// const AboutSubContainer = styled.div `
// display:flex;
// gap:20em;
// align-items:center;

// @media screen and (max-width:900px){
// flex-direction:column;
// gap:1em;
// text-align:center;
// padding:50px;
// }
// `;

// const AboutHeaderSectionContainer = styled.div `
// display:flex;
// flex-direction:column;
// `;

// const translate =keyframes`
// 0%{
// transform:translateY(0px);
// }
// 20%{
// transform:translateY(30px);
// }

// 100%{
// transform:translateY(0px);
// }
// `

// const AboutHeaderSectionImg = styled.img`
// transition:linear,1000ms;
// animation:${translate} 2s linear infinite;
// @media screen and (max-width:900px){
// width:200px;
// }
// `;

// const AboutHeader=styled.h1`
// font-size:25px;
// `;
// const AboutHeaderSectionDetails = styled.p`
// width:500px;
// @media screen and (max-width:900px){
// width:300px;
// font-size:15px;
// }
// `;

// const AboutMissionSectionContainer = styled.div `
// display:flex;
// flex-direction:column;
// gap:2em;

// @media screen and (max-width:900px){
// justify-content:center;
// align-items:center;
// }
// `
// const AboutMissionImage = styled.img`
// width:469px;
// height:464px;
// background:grey;
// border-radius:15px;

// @media screen and (max-width:900px){
// width:300px;
// height:300px;
// }
// `;

// const AboutMissionHeader=styled.h2``;

// const AboutMissionContentContainer = styled.div`
// display:flex;
// gap:8em;

// @media screen and (max-width:900px){
// flex-direction:column;
// gap:2em;
// justify-content:center;
// align-items:center;
// align-content:center;
// font-size:15px;
// }

// `;

// const AboutMissionStatementContainer = styled.div `
// display:flex;
// flex-direction:column;
// gap:2em;
// justify-content:center;

// @media screen and (max-width:900px){
// align-items:center;
// align-content:center;
// }
// `

// const AboutTeamContainer = styled.div `
// display:flex;
// flex-direction:column;
// gap:4em;

// @media screen and (max-width:900px){
// justify-content:center;
// align-items:center;
// gap:2em;
// }
// `;

// const AboutTeamProfileContainer = styled.div`
// display:flex;
// flex-direction:column;
// justify-content:center;
// align-content:center;
// gap:10px;
// `;

// const AboutTeamProfileImage = styled.img`
// background:grey;
// width:200px;
// height:200px;
// border-radius:100px;
// border-color:var(--lumina-brand-color);
// border-style:solid;

// @media screen and (max-width:900px){
// width:150px;
// height:150px;
// }
// `;

// const AboutTeamIdentityContainer = styled.div`
// display:flex;
// flex-direction:column;
// justify-content:center;
// text-align:center;
// align-items:center
// `;

// const AboutTeamSubContainer=styled.div`
// display:flex;
// gap:10em;

// @media screen and (max-width:900px){
// flex-direction:column;
// gap:4em;
// }
// `;

// const AboutTeamProfileName = styled.p``;

// const AboutTeamProfileRole= styled.b``;

// const AboutTeamHeader= styled.h2`
// font-size:20px;
// `;

// const AboutFooterDetails =styled.h2 `
// display:flex;
// padding:100px;
// margin-left:150px;

// @media screen and (max-width:900px){
// margin-left:10px;
// padding:10px;
// font-size:20px;
// justify-content:center;
// align-items:center;
// align-content:center;
// gap:5em;
// text-align:center
// }
// `

// const AboutPage = ()=>{
//    const missionStatements=[
//        {
//           id:1,
//           header:'Make Crypto Accessible',
//           details:'We try Our best To Make Sure Crypto is More Accesible to People, We Believe in the power of crypto'
//        },
//        {
//           id:2,
//           header:'Cheap and Fast',
//           details:"Our Aim is to make buying of crypto easy to buy with as little as $1 at the speed of lightning"
//        }
//        ,
//        {
//           id:3,
//           header:'Secured Transaction',
//           details:'We make sure users get the coins safe without being scammed'
//        },
//        {
//           id:4,
//           header:'Freedom',
//           details:"We want to make sure users have the freedom to buy crypto with no stress or hassel"
//        }
//       ]

//       // define users profiles 
//       const profiles=[
//          {
//             name:"X muncan",
//             role:'CEO',
//             img:xmucan
//          },
//          {
//             name:"Chuks E.A",
//             role:'CTO',
//             img:chuks
//          },
//          {
//             name:'Martins Ojakominor',
//             role:'COO',
//             img:''
//          }
//       ]
//  return(
//     <>
//     <HeaderComponent/>
//     {/* Begining of About parent container */}
//     <AboutParentContainer>

//     {/* START OF ABOUT SUB SECTION  */}
//     <AboutSubContainer>     
   
//       <AboutHeaderSectionContainer>
//          <AboutHeader>Welcome To Lumina</AboutHeader>
//          <AboutHeaderSectionDetails>
//          At Lumina We try Our best To Make Sure Crypto is More Accesible to People, We Believe in the power of crypto
//          </AboutHeaderSectionDetails>
//       </AboutHeaderSectionContainer>

//          {/* Beginning of About header section  image */}
//          <AboutHeaderSectionImg src={lumina_about_icon} alt="lumina_about_icon" />
//          {/* Ending of about header section image  */}
//       </AboutSubContainer>
//       {/* END OF ABOUT SUB SECTION  */}

//       {/* START OF ABOUT MISSION SECTION CONTAINER  */}
//       <AboutMissionSectionContainer>
//          <AboutMissionHeader>Our Mission</AboutMissionHeader>

//          <AboutMissionContentContainer>
//          <AboutMissionImage src={earth} alt="earth" />

//          {/* about mission statement container starting  */}
//          <AboutMissionStatementContainer>
//             {
//               missionStatements.map((statements,index)=>(
//                <MissionStatementComponent keys={index} header={statements.header} details={statements.details} />
//               ))
//             }
//          </AboutMissionStatementContainer>
//          {/* about mission statement container ending */}
//          </AboutMissionContentContainer>
//       </AboutMissionSectionContainer>
//        {/* END OF ABOUT MISSION SECTION CONTAINER  */}
    

//     {/* Beginning of About teams container  */}
//     <AboutTeamContainer>
//       <AboutTeamHeader>Meet Our Executive Directors</AboutTeamHeader>

//       {/* starting of profile sub container  */}
//       <AboutTeamSubContainer>
//          {
//             profiles.map((data,index)=>(
//                <AboutTeamProfileContainer keys={index}>
//                <AboutTeamProfileImage src={data.img}></AboutTeamProfileImage>
//                <AboutTeamIdentityContainer>
//                <AboutTeamProfileName>{data.name}</AboutTeamProfileName>
//                <AboutTeamProfileRole>{data.role}</AboutTeamProfileRole>    
//                </AboutTeamIdentityContainer>
//                </AboutTeamProfileContainer>         
//             ))
//          }
     
//       </AboutTeamSubContainer>
//       {/* Ending of profile sub container  */}

//     </AboutTeamContainer>
//     {/* End of About teams Container  */}

//     {/* Begining of About Footer Details  */}
//     <AboutFooterDetails>We Are Making Crypto More Accessible</AboutFooterDetails>
//     {/* end of About of About footer Details */}
//     </AboutParentContainer>
//     {/* End of About parent container  */}
//     <FooterComponent/>
//     </>
//  )   
// }

// export default AboutPage;

