import React, { useState } from 'react'
import styled from 'styled-components';
import ArrowBackIcon from "../assets/arrow-back-icon.svg"
import PolygonIcon from "../assets/polygon.png"
import SolanaIcon from "../assets/solana.png"
import USDTIcon from "../assets/usdt.png"
import BNBIcon from "../assets/bnb.png"
import TONIcon from "../assets/ton.png"
import NearIcon from "../assets/near.png"
import { CgClose } from 'react-icons/cg';


const Container = styled.div`
`;

const BodyContainer = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media screen and (max-width: 900px){
        gap: 25px;
    }
`;
const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 2px var(--lumina-border-line-color);
    padding: 20px
`;
const HeaderIconsCon = styled.div`
    padding: 10px;
    border-radius: 100%;
    width: 13px;
    height: 13px;
    display: flex;
    flex-direction: row;
    align-items: center;

    cursor: pointer;

    &:hover{
        background: var(--lumina-text-box-color);
    }
`;

const HeaderIcons = styled.img`
    width: 100%;
    height: 100%;

    
`;
const HeaderText = styled.h2`
    color: var(--lumina-text-color);
    font-size: 15px;
    font-weight: 500;
`;




// search container ==========================================================================
const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    background: var(--lumina-text-box-color);
    padding: 10px;
    border-radius: 10px;

    @media screen and (max-width: 750px){
        padding: 15px;
        font-size: 15px;
    }
`;
const SearchBox = styled.input`
    /* Hide up and down arrows in number input */
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    & {
        -moz-appearance: textfield; /* Firefox */
    }
    
    background: none;
    outline: none;
    border: none;
    color: var(--lumina-text-color);
    flex: 1;
    font-size: 16px;

`;


// Availble coins styles here ===============================================================================================*/}
const AvailableCoinSectionBody = styled.div`
    flex: 1;

    
`;

const InnerAvailableCoinSectionBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;

    height: 250px;
    overflow-y: scroll;

    @media screen and (max-width: 500px){
        height: 350px;
    }

    /* Scrollbar styles */
    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background: var(--lumina-text-box-color);
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }


    // @media screen and (max-width: 750px){
    //     /* Scrollbar styles */
    //     &::-webkit-scrollbar {
    //         width: 5px;
    //     }

    //     &::-webkit-scrollbar-track {
    //         background: var(--lumina-modal-bg-color);
    //         border-radius: 10px;
    //     }

    //     &::-webkit-scrollbar-thumb {
    //         background: var(--lumina-modal-bg-color);
    //         border-radius: 10px;
    //     }

    //     &::-webkit-scrollbar-thumb:hover {
    //         background: var(--lumina-modal-bg-color);
    //     }
    }

`;

const AvailableCoinsCon = styled.div`
    dispplay: flex;
    flex-direction: column;

    margin-right: 10px;
`;
const AvailableCoinItem = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 10px 0px;
    border-radius: 20px;

    &:hover{
        background: var(--lumina-text-box-color);
    }
`;

const AvailableCoinImageAndTextSection = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
`;
const AvailableCoinImageCon = styled.div`
    display: flex;
    align-items: center;
    width: 30px;
    height: 30px;
    background: var(--lumina-text-color);
    border-radius: 100%;
    position: relative;
`;
const AvailableCoinImage = styled.img`
    width: 100%;
    height: 100%;
`;
const AvailableCoinNetworkImageCon = styled.div`
    display: flex;
    align-items: center;
    width: 11px;
    height: 11px;
    background: var(--lumina-text-color);
    border-radius: 100%;
    position: absolute;
    top:-4px;
    right:-4px;
    border: solid 2px var(--lumina-text-color);
    

`;
const AvailableCoinNetworkImage = styled.img`
    width: 100%;
    height: 100%;
`;
const AvailableCoinTextSection = styled.div`
    dispplay: flex;
    flex-direction: column;
    gap: 2px;
`;
const AvailableCoinName = styled.h4`
    color: var(--lumina-text-color);
    font-size: 13px;
`;
const AvailableCoinPrice = styled.h4`
    color: var(--lumina-text-color);
    font-size: 11px;
    font-weight: 500;
`;
const AvailableCoinPercentage = styled.h4`
    color: var(--lumina-brand-color);
    font-size: 12px;
    font-weight: 600;
    background: var(--lumina-transparent-brand-color);
    display: inline-flex;
    padding: 3px 15px;
    border-radius: 20px;
    align-items: center;
    cursor: pointer;
`;

const ButtonContainer = styled.div`
    // text-align: center;
`;

const CoinsComponent = ({toggleModal, setSelectedCoin, setSelectedCoinIcon, setSelectedBlockchain, setSelectedBlockchainAddress, setSelectedBlockchainNetworkImage, setSelectedBlockchainNetworkName}) => {

    const coinsList = [
        {
            image: SolanaIcon,
            name: 'Solana',
            price: 132000,
            percentage: 100,
            blockchain: 'SOL'
        },
        {
            image: TONIcon,
            name: 'TON',
            price: 8000,
            percentage: 230,
            blockchain: 'TON'
        },
        {
            image: NearIcon,
            name: 'Near',
            price: 1000,
            percentage: 100,
            blockchain: 'NEAR'
        },
        {
            image: BNBIcon,
            name: 'BNB',
            price: 290280,
            percentage: 100,
            blockchain: 'BNB'
        },
        {
            image: PolygonIcon,
            name: 'Polygon',
            price: 290280,
            percentage: 100,
            blockchain: 'MATIC'
        },
        {
            image: USDTIcon,
            name: 'Tether (USDT)',
            price: 560280,
            percentage: 80,
            blockchain: 'USDT',
            network: 'MATIC',
            networkImage: PolygonIcon,
        },
        
        
    ]

    const [search, setSearch] = useState('')
    const [filteredSearch, setFilteredSearch] = useState(coinsList)

    const searchFilter =(e)=>{
        const query = e.target.value;
        setSearch(query);
        filterItems(query);
    }

    const filterItems =(query)=>{
        const lowercaseQuery = query.toLocaleLowerCase();

        const filtered = coinsList.filter(coin=> coin.name.toLocaleLowerCase().includes(lowercaseQuery))

        setFilteredSearch(filtered);
    }
    return (
        <Container>
            <Header>
                <HeaderIconsCon className='transparent-click' onClick={()=>toggleModal('available-coins')}>
                    <HeaderIcons src={ArrowBackIcon}/>
                </HeaderIconsCon>
                
                <HeaderText>Select a coin</HeaderText>
                {/* <HeaderIconsCon>
                    <HeaderIcons src={InfoIcon}/>
                </HeaderIconsCon> */}
            </Header>


            <BodyContainer>
                <SearchContainer>
                    <SearchBox value={search} placeholder='Search available coins' onChange={searchFilter} type="text"/>
                    {search&&<CgClose style={{color: 'var(--lumina-text-color)', cursor:'pointer'}}
                    className='transparent-click'
                    onClick={()=>{
                        setSearch('')
                        setFilteredSearch(coinsList)
                    }}/>}
                </SearchContainer>


                {/* Available coins section starts here ===============================================================================================*/} 
                <AvailableCoinSectionBody>
                    <InnerAvailableCoinSectionBody>
                        <AvailableCoinsCon>
                            {filteredSearch.map((coin, index)=>(
                                <AvailableCoinItem key={index}
                                className='transparent-click'
                                onClick={()=>{
                                    setSelectedCoin(coin.name)
                                    setSelectedCoinIcon(coin.image)
                                    setSelectedBlockchain(coin.blockchain)
                                    setSelectedBlockchainAddress('')
                                    toggleModal('available-coins')
                                    setSelectedBlockchainNetworkName(coin.network)
                                    setSelectedBlockchainNetworkImage(coin.networkImage)
                                }}>

                                    <AvailableCoinImageAndTextSection>
                                        <AvailableCoinImageCon>
                                            <AvailableCoinImage src={coin.image}/>

                                            {coin.network==='MATIC'&&<AvailableCoinNetworkImageCon>
                                                <AvailableCoinNetworkImage src={PolygonIcon}/>
                                            </AvailableCoinNetworkImageCon>}
                                        </AvailableCoinImageCon>

                                        <AvailableCoinTextSection>
                                            <AvailableCoinName>{coin.name}</AvailableCoinName>
                                            <AvailableCoinPrice>{coin.price.toLocaleString()}</AvailableCoinPrice>
                                        </AvailableCoinTextSection>
                                    </AvailableCoinImageAndTextSection>

                                    <ButtonContainer>
                                        <AvailableCoinPercentage>{coin.percentage}%</AvailableCoinPercentage>
                                    </ButtonContainer>

                                </AvailableCoinItem>
                            ))}
                        </AvailableCoinsCon>

                    </InnerAvailableCoinSectionBody>
                </AvailableCoinSectionBody>
            </BodyContainer>

            
        </Container>
    )
}

export default CoinsComponent