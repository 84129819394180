import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import moment from 'moment';
import { GrStatusGood } from "react-icons/gr";
import { IoCopyOutline } from "react-icons/io5";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import TransactionIcon from "../assets/green-transaction-icon.svg"



const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 400px;

    @media screen and (max-width: 900px){
        gap: 25px;
    }    
`;


// Confirmed transactions section
const Transactions = styled.table`
    width: 100%;
    border-collapse: collapse;
    // overflow-x:auto
    // table-layout: fixed;
    // overflow-x: scroll;
`;

const TransactionsTableMainTr = styled.tr``;
const TransactionsTableMainTd = styled.td`
    padding: 10px;
    color: var(--lumina-grey-color);
    font-size: 15px;
    font-weight: 600;
    white-space: nowrap;
`;

const TransactionsTableSubTr = styled.tr``;
const TransactionsTableSubTd = styled.td`
    white-space: nowrap;  // stop from wrapping
    padding: 10px;
    color: var(--lumina-text-color);
    font-size: 15px;
    font-weight: 500;
    width: 200px;
`;

const TransactionItemImageContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 15px;
    border-radius: 100%;
`;
const TransactionItemImage = styled.img`
    width: 15px;
    height: 15px;
`;
const TransactionItemTitleAndDescriptionCon = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
`;

const TransactionItemDescription = styled.div`
    color: var(--lumina-text-color);
    font-size: 12px;
    font-weight: 600;

`;
const TransactionItemStatus = styled.div`
    color: var(--lumina-brand-color);
    font-size: 12px;
    font-weight: 600;
    background: var(--lumina-transparent-brand-color);
    display: inline-flex;
    padding: 5px 15px;
    border-radius: 20px;
    align-items: center;

    
`;

const TransactionItemDate = styled.div`
    color: var(--lumina-grey-color);
    font-size: 12px;
    font-weight: 600;
    // background: var(--lumina-transparent-brand-color);
    display: inline-flex;
    border-radius: 20px;
    align-items: center;
    display: flex;
    gap: 10px;

`;
const TransactionItemRef = styled.div`
    color: var(--lumina-grey-color);
    font-size: 12px;
    font-weight: 600;
    // background: var(--lumina-transparent-brand-color);
    display: inline-flex;
    border-radius: 20px;
    align-items: center;
    display: flex;
    gap: 10px;
    cursor: pointer;

   
`;



const formatDate = (date) => {
    // const timestamp = Date.now();
    const formattedDateTime = moment(date).format('D MMM YYYY, h:mm A');
    // const formattedDateTime = moment(date).format('MMMM Do, YYYY, h:mm:ss A');
  
    return `${formattedDateTime}`;
};
const TransactionsComponent = ({transactionsFilter, setTransactionsFilter, transactionsItems}) => {

    const [copiedState, setCopiedState] = useState(false)
    const [copiedStateIndex, setCopiedStateIndex] = useState(false)

    const copyRefNum = (index)=>{
        setCopiedStateIndex(index)
        setCopiedState(true)
        setTimeout(() => {
            setCopiedState(false)
        }, 2000);
    }

    useEffect(()=>{
        setTransactionsFilter(transactionsItems)
    },[setTransactionsFilter, transactionsItems])


    return (
        <Container>
            <Transactions>
                <TransactionsTableMainTr>
                    <TransactionsTableMainTd></TransactionsTableMainTd>
                    <TransactionsTableMainTd>Details</TransactionsTableMainTd>
                    <TransactionsTableMainTd>Status</TransactionsTableMainTd>
                    <TransactionsTableMainTd>Date</TransactionsTableMainTd>
                    <TransactionsTableMainTd>Transaction Ref</TransactionsTableMainTd>
                </TransactionsTableMainTr>



                {transactionsFilter.map((transaction, index)=>(
                    <TransactionsTableSubTr  key={index}>

                        <TransactionsTableSubTd>
                            <TransactionItemImageContainer>
                                <TransactionItemImage src={TransactionIcon} />
                            </TransactionItemImageContainer>
                        </TransactionsTableSubTd>

                        <TransactionsTableSubTd>
                            <TransactionItemTitleAndDescriptionCon>
                                {/* <TransactionItemTitle>Crypto Purchase</TransactionItemTitle> */}
                                <TransactionItemDescription>{transaction.tx_details}</TransactionItemDescription>
                            </TransactionItemTitleAndDescriptionCon>
                        </TransactionsTableSubTd>

                        <TransactionsTableSubTd>
                            <TransactionItemStatus style={{color: transaction.tx_status!=='completed'&&'#ff6666', background: transaction.tx_status!=='completed'&&'rgba(255, 0, 0, 0.1)', }}>{transaction.tx_status}</TransactionItemStatus>
                        </TransactionsTableSubTd>

                        <TransactionsTableSubTd>
                            <TransactionItemDate>{formatDate(transaction.tx_date)}</TransactionItemDate>
                        </TransactionsTableSubTd>

                        <TransactionsTableSubTd>
                            <CopyToClipboard text={transaction.paystack_reference_no?transaction.paystack_reference_no:transaction.stripe_reference_no}>
                                <TransactionItemRef className='transparent-click' onClick={()=>copyRefNum(index)}>{transaction.paystack_reference_no?transaction.paystack_reference_no:transaction.stripe_reference_no}  {copiedState&&copiedStateIndex===index?<GrStatusGood/>:<IoCopyOutline />}</TransactionItemRef>
                            </CopyToClipboard>
                        </TransactionsTableSubTd>

                </TransactionsTableSubTr>))}
            </Transactions>

        </Container>
    )
}

export default TransactionsComponent