// import styled from "styled-components"
// import {useState} from "react"

// // define styled elements 
// const ErrorParentContainer = styled.div`
// margin-top:20px ;
// height:8vh;
// position:absolute;
// justify-content:center;
// display:flex;
// width:100vw;
// transform:translateY(-180%); 
// transition:linear,500ms;
// `;

// const ErrorModalContainer = styled.div`
// display:flex
// padding:60px;
// background:#df1515;
// width:300px;
// border-radius:100px;
// justify-content:center;
// align-items:center;
// `;

// const ErrorModalContentContainer = styled.div`
// display:flex;
// color:var(--lumina-text-color);
// font-size:14px;
// justify-content:center;
// align-item:center;
// padding:15px;
// `;

// const ErrorModalComponent=(props)=>{
//     return(
//         <>
//         <ErrorParentContainer id="error_modal" style={{transform:props.position}}>
//             <ErrorModalContainer>
//             <ErrorModalContentContainer>{props.message}</ErrorModalContentContainer>
//             </ErrorModalContainer>
//         </ErrorParentContainer>
//         </>
//     )
// }

// export default ErrorModalComponent;


import styled from "styled-components"

// define styled elements 
const ErrorParentContainer = styled.div`
margin-top:20px ;
// height:8vh;
position:absolute;
justify-content:center;
display:flex;
width:100vw;
transform:translateY(-180%); 
transition:linear,500ms;
`;


const ErrorModalContentContainer = styled.div`
display:flex;
color:var(--lumina-text-color);
font-size:14px;
justify-content:center;
align-item:center;
background:#df1515;
padding:15px 30px;
border-radius: 30px;
`;

const ErrorModalComponent=(props)=>{
    return(
        <>
        <ErrorParentContainer id="error_modal" style={{transform:props.position}}>
            {/* <ErrorModalContainer> */}
            <ErrorModalContentContainer>{props.message}</ErrorModalContentContainer>
            {/* </ErrorModalContainer> */}
        </ErrorParentContainer>
        </>
    )
}

export default ErrorModalComponent;