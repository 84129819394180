import axios from 'axios';
import React, { useEffect,useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { API_Route } from '../utils/Api';
import ConfirmComponent from '../components/ConfirmComponent';
import ProcessingComponent from '../components/ProcessingComponent';
import { Helmet } from 'react-helmet';

const ConfirmPaystackTransaction = () => {

    const [searchParams] = useSearchParams();

    const paystack_ref = searchParams.get('trxref')

    const navigate = useNavigate();

    const [processingModal,setProcessingModal] = useState('flex');
    const [confirmedModal, setConfirmedModal] = useState({scaling:'50%',visibility:'none'})

    // // check if there's a session id
    useEffect(()=>{
        if(!paystack_ref){
            navigate('/')
        }
    },[paystack_ref, navigate])

    useEffect(()=>{
        const confirmTransaction=async()=>{
            try {
                const userSessionToken = localStorage.getItem('userSessionToken');
                
                // console.log(paystack_ref)

                const response = await axios.post(`${API_Route}confirmBuyCryptoTransaction`,{
                    userSessionToken,
                    paystack_reference_no: paystack_ref,
                    stripePayment: false,
                    paystackPayment: true,
                });

                if(response.status===200){
                   setProcessingModal('none');  // make processing modal disappear after transaction is confirmed 

                    // define confirmation params 
                    const confirmation_params={
                        scaling:'100%',
                        visibility:'flex'
                    }
                    setConfirmedModal(confirmation_params); // display confirmation modal after processing
                    setInterval(async()=>{
                        navigate('/dashboard') // navigate to dashboard after processing transaction 
                    },3000)
                    clearInterval();
                }
            } catch (error) {
                
                console.log(error)
            }
        }
        confirmTransaction();
    },[paystack_ref, navigate])

 
    return (
        <>
        <Helmet>
            <title>Confirming..</title>
        </Helmet>
        <ConfirmComponent isShowing={confirmedModal.visibility} imageScale={confirmedModal.scaling} data='Successful' />
       <ProcessingComponent isShowing={processingModal} data='Processing ...'/>
     </>
    )
}

export default ConfirmPaystackTransaction;