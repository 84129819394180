import styled from "styled-components";
import CryptoPeopleImage from "../assets/people_crypto_image.jpeg";
import { useRef } from "react";
import { FaFacebook, FaTwitter, FaInstagram,  } from "react-icons/fa";
import FooterComponent from "./Footer.Component";
import fileDownload from 'js-file-download'
import axios from "axios";
import chuks from "../assets/chuks.jpg"
import { FiExternalLink } from "react-icons/fi";
import xmucan from "../assets/xmucan.jpg"


const Container = styled.div`

`;
const TopSectionContainer = styled.div`
    background: var(--lumina-modal-bg-color);
    padding: 50px 0px;

    @media screen and (max-width: 1200px){
        padding: 50px 20px;
    }
`;

const InnerTopSectionContainer = styled.div`
    width: 1100px;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;


    @media screen and (max-width: 1200px){
        width: auto;
    }
    
`;
const HaederSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const HeaderItem = styled.h4`
    color: var(--lumina-text-color);
    margin-bottom: 30px;
    display: flex;
    gap: 10px;
    align-items: center;
`;
const AboutSection = styled.div`
    display: flex;
    gap: 20px;

    @media screen and (max-width: 890px){
        flex-direction: column;
    }
`;
const AboutSectionTextContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;
const AboutSectionAboutText = styled.h4`
    color: var(--lumina-brand-color);
`;
const AboutSectionMainText = styled.h1`
    color: var(--lumina-text-color);
`;
const AboutSectionsText = styled.p`
    color: var(--lumina-grey-color);
`;
const AboutSectionImageContainer = styled.div`
    flex: 1;
    max-width: 100%;  // Ensures container is responsive
    max-height: 400px;
    border-radius: 10px;
    overflow: hidden;  // Correct spelling

    @media screen and (max-width: 1200px) {
        max-height: 350px;
    }

    @media screen and (max-width: 768px) {
        max-height: 250px;  // Adjust based on smaller screens
    }
`;

const AboutSectionImage = styled.img`
    width: 100%;
    height: auto;  
    border-radius: 10px;
    object-fit: cover;  // Ensures the image fills the container without distortion
`;


const Labels = styled.h3`
    color: var(--lumina-text-color);
    margin-top: 30px;
`;


const FactsContainer = styled.div`
    display: flex;
    gap: 50px;

    @media screen and (max-width: 800px){
        flex-direction: column;
    }
`;
const FactsItem = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1;
`;
const FactsTitle = styled.h4`
    color: var(--lumina-brand-color);
    border-left: solid 0.5px var(--lumina-brand-color);
    padding-left: 10px;
`;
const FactsDescription = styled.p`
    color: var(--lumina-grey-color);
    font-size: 14px;
    padding-left: 10px;
`;

const SecondSecondContainer = styled.div`
    width: 1100px;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 50px 0px;

    @media screen and (max-width: 1200px){
        width: auto;
        padding: 50px 20px;
    }
`;

const BigLabel = styled.h1`
    color: var(--lumina-text-color);
`;

const LeaderShipSection = styled.div`
    display: flex;
    gap: 20px;
    position: relative;
    justify-content: space-between;

    &::-webkit-scrollbar {
        width: 0 !important;
        height: 0 !important;
    }

    @media screen and (max-width: 800px) {
        overflow-x: auto;
        scroll-behavior: smooth;
        -webkit-overflow-scrolling: touch;
    }
`;

const LeaderShipItem = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    // flex: 1;
    width: 250px;

    @media screen and (max-width: 800px) {
        width: 350px;
        flex: none;
    }
`;

const LeaderShipImageCon = styled.div`
    width: 100%;
    height: 250px;
    border-radius: 10px;
    overflow: hidden;
    background: var(--lumina-modal-bg-color);

    @media screen and (max-width: 1200px) {
        height: 300px;
    }

    @media screen and (max-width: 768px) {
        height: 400px;
    }
`;

const SliderButtonCon = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: right;
`;
const SliderButton = styled.button`
    top: 50%;
    transform: translateY(-50%);
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    gap: 3px;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.6);
    font-size: 12px;
    font-weight: 500;
    background: rgba(255, 255, 255, 0.1);

    &:hover{
        gap: 5px;
        background: rgba(255, 255, 255, 0.2);
    }

    &.prev {
        left: 0;
    }

    &.next {
        right: 0;
    }

    @media screen and (min-width: 800px) {
        display: none;
    }
`;

const LeaderShipImage = styled.img`
    width: 100%;
    height: 100%;
`;
const LeaderShipName = styled.h3`
    color: var(--lumina-text-color);
    font-size: 15px;
`;

const LeaderShipTitle = styled.p`
    color: var(--lumina-grey-color);
    font-size: 15px;
    font-weight: 500;
`;
const SocialIcons = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
`;
const SocialIcon = styled.div`
    color: var(--lumina-text-color);
    cursor: pointer;
`;

const ThirdSectionContainer = styled.div`
    width: 1100px;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 50px 0px;

    @media screen and (max-width: 1200px){
        width: auto;
        padding: 50px 20px;
    }
`;

const leadershipItems = [
    {
        image: xmucan,
        name: 'XMUCAN',
        title: 'Cofounder and CEO',
        twitterLink: '',
        instagramLink: '',
        emailLink: '',
    },
    {
        image: chuks,
        name: 'Chucks',
        title: 'Cofounder and CTO',
        twitterLink: '',
        instagramLink: '',
        emailLink: '',
    },
    {
        image: CryptoPeopleImage,
        name: 'Ojakominor Martins',
        title: 'Cofounder and COO',
        twitterLink: '',
        instagramLink: '',
        emailLink: '',
    },
    
]

const AboutUs = () => {

    const sliderRef = useRef(null);

    const scrollLeft = () => {
        if (sliderRef.current) {
            sliderRef.current.scrollLeft -= 350; // Adjust according to your item width
        }
    };

    const scrollRight = () => {
        if (sliderRef.current) {
            sliderRef.current.scrollLeft += 350;
        }
    };

    const handleDownload = (url, filename)=>{
        console.log('downloaindg          1')

        axios.get(url, {
            responseType: 'blob',
        })
        .then((res) => {
            console.log('downloaindg')
            fileDownload(res.data, filename)
            
        })
        .catch((e)=>{
            console.log(e)
        })
    }
    return (
        <Container>
            <TopSectionContainer>
                <InnerTopSectionContainer>
                    <HaederSection>
                        <HeaderItem>Everything about <span style={{color:'var(--lumina-brand-color)'}}>Handiswap</span></HeaderItem>
                        <HeaderItem style={{cursor:'pointer'}}>Blog <FiExternalLink /></HeaderItem>
                    </HaederSection>


                    <AboutSection>
                        <AboutSectionTextContainer>
                            <AboutSectionAboutText>About Handiswap</AboutSectionAboutText>
                            
                            <AboutSectionMainText>Our mission to get more people into the crypto world with as little as <span style={{color:'var(--lumina-brand-color)'}}>$1</span></AboutSectionMainText>

                            <AboutSectionsText>Handiswap is a this dummy text bla bla bla bla bla bla .in   iunnwe and is a technology company that builds economic infrastructure for the internet. Businesses of every size—from new startups to public companies—use our software to accept payments and manage their businesses online. has dual headquarters in San Francisco and Dublin, as well as offices in London, Paris, Singapore, Tokyo, and other locations around the world. this and this and this and that and this, bla bla bla bla </AboutSectionsText>
                        </AboutSectionTextContainer>

                        <AboutSectionImageContainer>
                            <AboutSectionImage src={CryptoPeopleImage} alt="Crypto People Image" />
                        </AboutSectionImageContainer>
                    </AboutSection>

                    <Labels>Facts about Handiswap</Labels>
                    <FactsContainer>
                        <FactsItem>
                            <FactsTitle>4+</FactsTitle>
                            <FactsDescription>Crypto coins currently availabe for sale on Handiswap.</FactsDescription>
                        </FactsItem>

                        <FactsItem>
                            <FactsTitle>30 seconds</FactsTitle>
                            <FactsDescription>The amount of time it takes for your purchased crypto to reflect in your wallet.</FactsDescription>
                        </FactsItem>

                        <FactsItem>
                            <FactsTitle>50+</FactsTitle>
                            <FactsDescription>Countries where Handiswap is currently available in.</FactsDescription>
                        </FactsItem>


                    </FactsContainer>

                </InnerTopSectionContainer>
            </TopSectionContainer>


            <SecondSecondContainer >
                <BigLabel>Leadership</BigLabel>

                <SliderButtonCon>
                    <SliderButton className="prev" onClick={scrollLeft}>←</SliderButton>
                    <SliderButton className="next" onClick={scrollRight}>→</SliderButton>
                </SliderButtonCon>
                
                <LeaderShipSection ref={sliderRef}>
                    {leadershipItems.map((leader, index)=>(<LeaderShipItem>
                        <LeaderShipImageCon key={index}>
                            <LeaderShipImage src={leader.image} alt='Cofounder and CEO' />
                            </LeaderShipImageCon>

                            <LeaderShipName>{leader.name}</LeaderShipName>
                            <LeaderShipTitle>{leader.title}</LeaderShipTitle>

                            <SocialIcons>
                            <SocialIcon>
                                    <FaFacebook />
                                </SocialIcon>
                                <SocialIcon>
                                    <FaTwitter />
                                </SocialIcon>
                                <SocialIcon>
                                    <FaInstagram />
                                </SocialIcon>
                            </SocialIcons>
                        </LeaderShipItem>))}
                    
                </LeaderShipSection>
            </SecondSecondContainer>


            <ThirdSectionContainer>
                <BigLabel>Media Assets</BigLabel>

                
                <BigLabel onClick={()=>handleDownload('http://localhost:3000/static/media/people_crypto_image.3c5bf3d18392130e4c8b.jpeg', 'handiswap_logo.jpeg')}>Download</BigLabel>


            </ThirdSectionContainer>


            <FooterComponent/>
            
        </Container>
    )
}

export default AboutUs