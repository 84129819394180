import styled from "styled-components";
import PolygonIcon from "../assets/polygon.png"
import SolanaIcon from "../assets/solana.png"
import BNBIcon from "../assets/bnb.png"
import TONIcon from "../assets/ton.png"
import { useEffect, useState } from "react";
import { FaAngleRight } from "react-icons/fa6";
import { Link } from "react-router-dom";
import FAQComponent from "./FAQComponent";
import SwapComponentMockUp from "./SwapComponentMockup";


const Container = styled.div`
    
`;

const InnerContainer = styled.div`
    width: 1200px;
    margin: auto;
    padding: 10px;
    margin-top:45px;

    @media screen and (max-width:1250px){
        width: auto;
        margin-top:10px;
    }
`;
// available coins section
const AvailableCoinsSection = styled.div`
    display: flex;
    // padding: 40px;
    gap: 20px;
    
    @media screen and (max-width: 900px){
        flex-direction: column;
    }

`;

const BigTextSectionContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    
    @media screen and (max-width: 500px){
        justify-content: center;
        width:350px;
        align-items:center;
    }


`;

const SignUpEmailLabel = styled.label`
    color: var(--lumina-text-color);
    margin-top: 20px;
    font-size: 13px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    gap: 5px;
`;
const EmailLabelText = styled.div`
    @media screen and (max-width: 500px){
        display: none;
    }
`;
const SignUpEmailContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;

    @media screen and (max-width: 500px){
        justify-content: center;
    }
`;
const SignUpEmailInputContainer = styled.div`
    display: flex;
    padding: 15px;
    border-radius: 10px;
    border: solid 1px var(--lumina-grey-color);
    width: 300px;

    @media screen and (max-width: 1127px){
        width: 200px;
    }

    @media screen and (max-width: 500px){
        display: none;
    }
`;
const SignUpEmailInput = styled.input`
    background: none;
    border: none;
    color: var(--lumina-text-color);
    flex: 1;
`;
const SignUpEmailButton = styled.div`
    color: var(--lumina-text-black-color);
    background: var(--lumina-brand-color);
    padding: 15px 40px;
    border-radius: 10px;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;


    @media screen and (max-width: 500px){
        padding: 15px 70px;
    }
`;

const BigText = styled.h1`
    font-size: 40px;
    font-weight: 900;
    color: var(--lumina-text-color);
    


    // @media screen and (max-width: 900px){
    //     font-size: 50px;
    // }

    @media screen and (max-width: 600px){
        font-size: 30px;
    }

    @media screen and (max-width: 500px){
        text-align: center;
    }
`;

const AuthenticatedLuanchButton = styled.div`
    color: var(--lumina-text-black-color);
    background: var(--lumina-brand-color);
    padding: 15px 70px;
    border-radius: 10px;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;


    @media screen and (max-width: 500px){
        margin: auto;
    }
`;

const AvailableCoinsSectionContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: right;

    @media screen and (max-width: 900px){
        display: block;
    }
`;

const AvailableCoinsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5;
    width: 450px;
    // max-width: 600px;
    min-width: 400px;
    // margin: auto;
    border-radius: 20px;
    background-color: var(--lumina-modal-bg-color);
    padding: 10px;



    @media screen and (max-width: 900px){
        width: auto;
        min-width: auto;
    }

`;
const AvailableCoinItem = styled.div`
    display: flex;
    padding: 10px;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
`;
const AvailableCoinImageAndTextContainer = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
`;
const AvailableCoinImageContainer = styled.div`
    width: 30px;
    height: 30px;
    overflow: hidden;
    background: var(--lumina-text-color);
    border-radius: 100%;
`;
const AvailableCoinImage = styled.img`
    width: 100%;
    height: 100%;
`;
const AvailableCoinTextContainer = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
`;
const AvailableCoinTitle = styled.div`
    color: var(--lumina-text-color);
    font-size: 15px;
    font-weight: 600;
`;
const AvailableCoinName = styled.div`
    color: var(--lumina-grey-color);
    font-size: 14px;
    font-weight: 500;
`;
const AvailableCoinsViewAll = styled.div`
    color: var(--lumina-grey-color);
    font-size: 13px;
    font-weight: 600;
    padding: 10px;
    cursor: pointer;
    display: flex;
    gap: 2px;
    align-items: center;

    &:hover{
        gap: 5px;
    }
`;
const AvailableCoinPrice = styled.div`
    color: var(--lumina-text-color);
    font-size: 15px;
    font-weight: 600;
`;





const InfoSection = () => {
    const [email, setEmail] = useState('')
    const userSessionToken = localStorage.getItem('userSessionToken');

    
    // define coin price and name 
    const [coinPrice,setcoinPrice]=useState({
        ton_price:0,
        sol_price:0,
        bnb_price:0,
        matic_price:0,
    })

    const url=`https://min-api.cryptocompare.com/data/pricemulti?fsyms=SOL,BNB,TONCOIN,MATIC,NEAR,&tsyms=USD,NGN&api_key=${process.env.REACT_APP_PRICE_COMPARE_KEY}`;

    //function for formating number 
    let formatCoinPrice=(coin_type,currency='USD')=>{
         const currencyOptions = { 
            style: 'currency', 
            currency: currency
          };
          return coin_type.toLocaleString('en-US', currencyOptions);
    }

    // fetch live coinn price 
    useEffect(()=>{
        fetch(url)
            .then((response)=>{
                return response.json()
            })
            .then((data)=>{
                // define coin price params 
                let coin_price_params={
                    ton_price:formatCoinPrice(data.TONCOIN.USD),
                    sol_price:formatCoinPrice(data.SOL.USD),
                    bnb_price:formatCoinPrice(data.BNB.USD),
                    matic_price:formatCoinPrice(data.MATIC.USD),
                }
            setcoinPrice(coin_price_params);
        })
        // set an interval to update the price every 60 seconds
        // setInterval(() => {
            // fetch(url)
            // .then((response)=>{
            //     return response.json()
            // })
            // .then((data)=>{
            //     // define coin price params 
            //     let coin_price_params={
            //         ton_price:formatCoinPrice(data.TONCOIN.USD),
            //         sol_price:formatCoinPrice(data.SOL.USD),
            //         bnb_price:formatCoinPrice(data.BNB.USD),
            //         matic_price:formatCoinPrice(data.MATIC.USD),
            //     }
            // setcoinPrice(coin_price_params);
            // })
        // }, 30000);
    },[url])

    const coins = [
        {
            name:'Toncoin',
            symbol: 'TON',
            price: coinPrice.ton_price,
            image: TONIcon
        },
        {
            name:'Binance Smart Chain',
            symbol: 'BNB',
            price: coinPrice.bnb_price,
            image: BNBIcon
        },
        {
            name:'MATIC',
            symbol: 'Polygon',
            price: coinPrice.matic_price,
            image: PolygonIcon
        },
        {
            name:'Solana',
            symbol: 'SOL',
            price: coinPrice.sol_price,
            image: SolanaIcon
        },
        
    ]
    
    return (
        <Container>

            <InnerContainer>
                {/* The available coins sections starts here */}
                <AvailableCoinsSection>
                    <BigTextSectionContainer>
                        <BigText>Buy any crypto coin</BigText>
                        <BigText style={{fontSize:'18px', fontWeight:'500', marginBottom:'10px', marginTop:'5px'}}>Millions choose Handiswap to buy crypto. <span style={{color:'var(--lumina-brand-color)', fontSize:'18px', fontWeight:'600'}}>Start with just $1.</span></BigText>

                        <SignUpEmailLabel>
                            {!userSessionToken&&<EmailLabelText>Email Address</EmailLabelText>}
                            <SignUpEmailContainer>
                                {userSessionToken
                                ?<Link style={{display:'inline-block'}} className="transparent-click" target="_blank" rel="noreferrer" to='/app/swap'>
                                    <AuthenticatedLuanchButton style={{display:'inline-block'}} >Launch App</AuthenticatedLuanchButton>
                                </Link>
                                :<>
                                    <SignUpEmailInputContainer>
                                        <SignUpEmailInput value={email} onChange={(e)=>setEmail(e.target.value)} placeholder="Enter email"/>
                                    </SignUpEmailInputContainer>

                                    <Link className="transparent-click" to={email?`/signup?email=${email}`:'/signup'}>
                                        <SignUpEmailButton>Sign Up</SignUpEmailButton>
                                    </Link>
                                </>}
                            </SignUpEmailContainer>
                        </SignUpEmailLabel>
                        
                    </BigTextSectionContainer>

                    <AvailableCoinsSectionContainer>
                        <AvailableCoinsContainer>
                            <AvailableCoinTitle style={{ margin:'10px'}}>Available Now</AvailableCoinTitle>
                            {/* individual available coin */}
                            {coins.map((coin, index)=>(
                                <AvailableCoinItem key={index}>
                                    <AvailableCoinImageAndTextContainer>
                                        <AvailableCoinImageContainer>
                                            <AvailableCoinImage src={coin.image}/>
                                        </AvailableCoinImageContainer>

                                        <AvailableCoinTextContainer>
                                            <AvailableCoinTitle>{coin.symbol}</AvailableCoinTitle>
                                            <AvailableCoinName>{coin.name}</AvailableCoinName>
                                        </AvailableCoinTextContainer>
                                    </AvailableCoinImageAndTextContainer>

                                    <AvailableCoinPrice>{coin.price}</AvailableCoinPrice>
                                </AvailableCoinItem>))}

                            <AvailableCoinsViewAll>View all available coins <FaAngleRight /></AvailableCoinsViewAll>
                        </AvailableCoinsContainer>
                    </AvailableCoinsSectionContainer>
                </AvailableCoinsSection>
                {/* THE AVAILABLE COINS SECTION ENDS HERE */}
            </InnerContainer>
            

            {/* SWAP SECTION STARTS HERE */}
            <SwapComponentMockUp />
            {/* SWAP SECTION ENDS HERE  */}


            {/* FAQ SECTION */}
            <FAQComponent/>

            
        </Container>
    )
}

export default InfoSection

















// import styled from "styled-components"
// import available from "../assets/available.png";
// import AvailableCoinBox from "./AvailableCoinBox.Component";
// import swapimage from "../assets/swapimage.png";
// import packageImage from "../assets/packageImage.png"
// import gatewayicon from "../assets/gatewayicon.png"
// import { useEffect, useState } from "react";
// import tonicon from "../assets/tonicon.png"
// import bnbicon from '../assets/bnbicon.png'
// import solicon from '../assets/solicon.png'

// const InfoSectionContainer=styled.section``
// const ProductDescriptionContainer=styled.section``;
// const BuyCoinDescriptionContainer = styled.div``;
// const InstantDeliverDescriptionContainer=styled.div``;
// const GainAccessContainer = styled.section``;
// const GateWayContainer = styled.section``;


// const InfoSection = () => {
//     // define coin price and name 
//     const [coinPrice,setcoinPrice]=useState({
//         ton_price:0,
//         sol_price:0,
//         bnb_price:0
//     })

//     const url=`https://min-api.cryptocompare.com/data/pricemulti?fsyms=SOL,BNB,TON&tsyms=USD,NGN&api_key=${process.env.REACT_APP_PRICE_COMPARE_KEY}`;

//     //function for formating number 
//     let formatCoinPrice=(coin_type,currency='NGN')=>{
//          const currencyOptions = { 
//             style: 'currency', 
//             currency: currency
//           };
//           return coin_type.toLocaleString('en-US', currencyOptions);
//     }

//     // fetch live coinn price 
//     useEffect(()=>{
//     fetch(url)
//     .then((response)=>{
//         return response.json()
//     })
//     .then((data)=>{       
//         // define coin price params 
//         let coin_price_params={
//             ton_price:formatCoinPrice(data.TON.NGN),
//             sol_price:formatCoinPrice(data.SOL.NGN),
//             bnb_price:formatCoinPrice(data.BNB.NGN)
//         }
//       setcoinPrice(coin_price_params);
//     })
//     },[url])
    
//     return (
//         <>
//         <InfoSectionContainer className="info_section_container">
//             <div className="available_coin_info_container">
//                 <div className="available_text_coin">
//                     <img alt='available_image' src={available} className="available_img" />
//                     <h2 className="available_sub_txt">Discover Amazing Crypto Currency In A <span>Flash</span></h2>
//                 </div>

//                 {/* list of coin parent container */}
//                 <div className="box_container">
//                     {/* list of available coins beginning */}
//                 <AvailableCoinBox coin_icon={tonicon} coin_price={coinPrice.ton_price} coin_name='TON' />
//                 <AvailableCoinBox coin_icon={solicon} coin_price={coinPrice.sol_price} coin_name='SOL' />
//                 <AvailableCoinBox coin_icon={bnbicon} coin_price={coinPrice.bnb_price} coin_name='BNB' />
//                 {/* list of available coins ending  */}
//                 </div>
//                 {/* list of coin parent container  */}
//                 <button className="discover_more_btn">See More</button>
//             </div>

//             {/* description of product section  */}
//             <ProductDescriptionContainer className="product_description_container">

//                 {/* section describing or teling user to buy any coin  */}
//                 <BuyCoinDescriptionContainer className="buy_coin_description_container">
//                     <img alt='swap_coin_image' src={swapimage} className="swap_coin_image" />
//                     <div className="buy_coin_text_container">
//                         <h2>Buy Any Crypto Coins Directly With Dollar With Ease</h2>
//                         <p>The easiest and seamless way to buy crypto coin directly with dollar</p>
//                     </div>
//                 </BuyCoinDescriptionContainer>            

//                 {/* section telling user that all transaction will go instantly without any hidden charges  */}
//                 <InstantDeliverDescriptionContainer className="instant_coin_delivery_container">
//                 <img alt='package_coin_image' src={packageImage} className="package_coin_image" />
//                     <div className="instant_coin_text_container">
//                         <h2>Instant Delivery With No Hidden Charges</h2>
//                         <p>Get Access To Instant Delivery of any crypto coin you buy with no hidden charges or fees </p>
//                     </div>
//                 </InstantDeliverDescriptionContainer>

//                 {/* gain access into more in lumina section  */}
//                 <GainAccessContainer className="gain_access_container_parent">
//                     <div className="gain_access_container">
//                     <div className="gain_access_ctx_container">
//                         <h2 className="gain_main_txt">Get Access To Any Crypto Of Your Choice With Lumina</h2>
//                         <button className="gain_access_cta_btn">Get Started</button>
//                     </div>
//                     </div>
//                 </GainAccessContainer>

//                 {/* gateway section */}
//                 <GateWayContainer className="gateway_container">
//                     <h2 className="gateway_main_txt">Your Gateway To Crypto Freedom With <span>Speed</span></h2>          
//                     <img src={gatewayicon} className="gateway_square_icon" alt="gateway_icon" />
//                 </GateWayContainer>

//             </ProductDescriptionContainer>
//         </InfoSectionContainer>
//         </>
//     )
// }

// export default InfoSection

