import styled from "styled-components"
import {Helmet} from "react-helmet"

const Container = styled.div``;

const PrivacyPolicy = () => {
    return (
        <>
         <Helmet>
            <title>Handiswap |  PrivacyPolicy</title>
        </Helmet>
        <Container>
            
            </Container>
        </>
    )
}

export default PrivacyPolicy