import styled from "styled-components"
import signup_banner from "../assets/signup_banner.png";
import lumina_logo from "../assets/logos/greenlogo.png"

import {  useEffect, useMemo, useState } from "react";
import axios from "axios";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { API_Route } from "../utils/Api";
import { useGoogleLogin } from "@react-oauth/google";
import LoadingModalComponent from "../components/LoadingModal.Component";
import ErrorModalComponent from "../components/ErrorModal.Component";
import GoogleIcon from "../assets/google-icon.svg";
import { FcCancel } from "react-icons/fc";
import { FaCheck } from "react-icons/fa6";
import { FaAngleLeft } from "react-icons/fa6";
import { Helmet } from "react-helmet";

const Container = styled.div`
    display: flex;
    // height: 100vh;

    @media screen and (max-width: 850px){
        display: block;
    }

`;

const AuthParentContainer =styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 2;
    // overflow-y: auto;
    // max-height: 100vh;
    padding: 20px;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background: var(--lumina-text-box-color);
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    @media screen and (max-width: 850px){
        // overflow-y: hidden;
    }
`;

const AuthInnerParent = styled.form`
    width: 400px;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 15px;

    @media screen and (max-width: 850px){
        // margin-top: 50px;
    }

    @media screen and (max-width: 500px){
        width: 100%;
    }
`;
const AuthHeaderContainer =styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--lumina-text-color);
    margin-bottom: 10px;


    @media screen and (max-width: 750px){
        margin-bottom: 30px;
    }
`; 
const LogoContainer = styled.div`
    width: 150px;
    // height: 50px;
    // margin: auto;
`;

const ContainerImage = styled.img`
    width: 100%;
    height: 100%;
`;

const CustomGoogleSignIn = styled.div`
    color: var(--lumina-text-black-color);
    font-size: 13px;
    font-weight: 600;
    background: var(--lumina-text-color);
    text-align: center;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;


    @media screen and (max-width: 750px){
        padding: 15px;
    }

`;

const GoogleIconCon = styled.div`
    width: 15px;
    height: 15px;
`;

const GoogleImage = styled.img`
    width: 100%;
    height: 100%;
`;

const OrSection = styled.div`
    display: flex;
    gap: 10px;
    color: var(--lumina-text-color);
    align-items: center;
    padding: 0px 10px;
`;

const OrLine = styled.div`
    height: 1px;
    flex: 1;
    background: #5c5c5c;
`;

const Inputabel = styled.label`
    color: var(--lumina-text-color);
    font-size: 12px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    gap: 5px;

    @media screen and (max-width: 750px){
        font-size: 15px;
    }
`;
const InputContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    background: var(--lumina-text-box-color);
    padding: 10px;
    border-radius: 10px;

    @media screen and (max-width: 750px){
        padding: 15px;
        font-size: 15px;
    }
`;
const Input = styled.input`
    /* Hide up and down arrows in number input */
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    & {
        -moz-appearance: textfield; /* Firefox */
    }
    
    background: none;
    outline: none;
    border: none;
    color: var(--lumina-text-color);
    flex: 1;
    font-size: 16px;

`;
const ActionButton = styled.button`
    color: var(--lumina-text-black-color);
    background: var(--lumina-brand-color);
    font-size: 13px;
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
    text-align: center;
    width: 100%;
    font-weight: 600;

    @media screen and (max-width: 750px){
        padding: 15px;
    }

`;

const BannerSection = styled.div`
    // flex: 1.5;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: right;
    // position: fixed;
    

    @media screen and (max-width: 850px){
        display: none;
    }

`;
const BannerImageContainer = styled.div`
    width: 400px;
    height: 100%;
    right: 0;

`;
const BannerImage = styled.img`
    width: 100%;
    height: 100%;
`;
const HaveAnAccountText = styled.div`
    color:var(--lumina-text-color);
    font-size: 13px;
    font-weight: 500;
    text-align: center;

    @media screen and (max-width: 750px){
        margin-top: 10px;
    }
    
`;

const BackButton = styled.div`
    display: inline-flex;
    padding: 10px;
    border-radius: 20px;
    align-items: center;
    gap: 3px;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.6);
    font-size: 12px;
    font-weight: 500;
    background: var(--lumina-text-box-color);

    &:hover{
        gap: 5px;
        background: rgba(255, 255, 255, 0.1);
    }
`;

const BySigninUpText = styled.div`
    color: var(--lumina-grey-color);
    font-size: 12px;
`;

// urls for random images
const imageUrls = [
    'https://i.pinimg.com/564x/0f/d0/fa/0fd0fa63ff40ebc9f50873d14a043436.jpg',
    'https://i.pinimg.com/736x/a2/10/e6/a210e604c35af946cdbc4dadc8637986.jpg',
    'https://i.pinimg.com/736x/d2/f2/91/d2f2914431de5161c4aad3fa577926b1.jpg',
    'https://i.pinimg.com/736x/f1/6c/c5/f16cc502a1f6c7a4f4660a2ba74a1cce.jpg',
    'https://i.pinimg.com/736x/cc/f9/c9/ccf9c99cbaa33123b82e84ae5beeb869.jpg',
    'https://i.pinimg.com/736x/dd/d0/67/ddd0671fa0d308f8991b22ee18c575f7.jpg',
    'https://i.pinimg.com/736x/fb/03/06/fb0306aab19161ce0aafd5fde225aafe.jpg',
    'https://i.pinimg.com/564x/19/2d/b9/192db93872e68e0abebba9b7ab38e82a.jpg',

];

function getRandomImageUrl(imageUrls) {
    // functon to shuffle the images
    for (let i = imageUrls.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [imageUrls[i], imageUrls[j]] = [imageUrls[j], imageUrls[i]];
    }
    return imageUrls[0];
}


const Signin = () => {

    const [searchParams] = useSearchParams();

    const userEmail = searchParams.get('email');

    const userGeneratedPassForGoogle = process.env.REACT_APP_USERS_GENERATED_PASSWORDS;

    let [passwordState,setPasswordState]=useState("password")
    let [passwordTextState,setPasswordTextState]=useState("show");
    let [revealVisibiityState,setRevealVisibilityState]=useState("none");
    let [hiddenVisibiityState,setHiddenVisibilityState]=useState("block");

    // const [displaySection,setDisplaySection]=useState({ showNameSection:"flex", showSecuritySection:"none",message:"Lets Get You Started"});
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [nationality, setNationality] = useState('');
    const [loading, setLoading] = useState(false)
    const [showEmailSection, setShowEmailSection] = useState(false)
    const [isEmailValid, setIsEmailValid] = useState(false)
    const [error, setError] = useState({position:"transform:translateY(-180%)",message:""});

    const emailRegex = useMemo(() => {
        return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    }, []);

    const navigate = useNavigate();

    let RevealPassword=()=>{
        setPasswordState("text");
        setPasswordTextState("hide");
        setHiddenVisibilityState("block");
        setRevealVisibilityState("none")
    }

    let HidePassword=()=>{
        setPasswordState("password");
        setPasswordTextState("show")
        setHiddenVisibilityState("none")
        setRevealVisibilityState("block")
    }

    useEffect(()=>{
        if(userEmail){
            setEmail(userEmail)

            if (!emailRegex.test(userEmail)) {
                setIsEmailValid(false)
            } else {
                setIsEmailValid(true)
            }
        }
    },[userEmail, emailRegex])
          
      
    // const proceed_to_passwordVerification=()=>{
    //     const params_config={
    //         showNameSection:"none",
    //         showSecuritySection:"flex",
    //         message:"Protect Your Account"
    //     }
    //     // setDisplaySection(params_config)
    // }

    let closeErrorModal=async()=>{
        await setInterval(()=>{
            const set_error_params={position:"translateY(-180%)",message:""}
            setError(set_error_params)
        },4000)
        clearInterval()
    }

    const signupFunc = async(e)=>{
        e.preventDefault();

        setLoading(true)
        if(firstName && lastName && email && password && confirmPassword && password===confirmPassword){

            if(isEmailValid){
                try {
                
                    const randomImageUrl = getRandomImageUrl(imageUrls);
    
                    const response = await axios.post(`${API_Route}signup`,{
                        fullName: `${firstName} ${lastName}`,
                        email,
                        password,
                        profilePicture: randomImageUrl,
                        isGoogleSignUp: false
                    })
    
                    if(response.status===200){
                        // save user session token to local storage and move to the dashboard or swap page after 2 seconds
                        const userSessionToken = response.data.userSessionToken;
                        localStorage.setItem('userSessionToken', userSessionToken)
                        navigate(`/verify-email`);
                        setLoading(false)
                    }
                
                } catch (error) {
                    if(error.response.data&&error.response.data.error){
                        const set_error_params={position:"translateY(0%)",message:error.response.data.error}
                        setError(set_error_params);
                        await closeErrorModal()
                        setLoading(false)
                    }else{
                        const set_error_params={position:"translateY(0%)",message:"Something Went Wrong"}
                        setError(set_error_params);
                        await closeErrorModal()
                        setLoading(false)
                    }
                }
            }else{
                setLoading(false)
                const set_error_params={position:"translateY(0%)",message:"Please enter a valid email"}
                setError(set_error_params);
                await closeErrorModal();
            }
            
        }else{
            setLoading(false)
            const set_error_params={position:"translateY(0%)",message:"Please fill all fields"}
            setError(set_error_params);
            await closeErrorModal();
        }
    }

    const login = useGoogleLogin({
        onSuccess: credentialResponse => googleSignUp(credentialResponse.access_token),
    })



    const googleSignUp = async(credentialResponse)=>{
        setLoading(true)
        // get the users data from the credential
        await axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${credentialResponse}`, {

            headers: {
                Authorization: `Bearer ${credentialResponse}`,
                Accept: 'application/json'
            }
        })
        .then(async(result)=>{
            const resultResponse = result.data;
            try {
                const randomImageUrl = getRandomImageUrl(imageUrls);
    
                const response = await axios.post(`${API_Route}signup`,{
                    fullName: resultResponse.name,
                    email: resultResponse.email,
                    password: userGeneratedPassForGoogle,
                    profilePicture: randomImageUrl,
                    isGoogleSignUp: true,
                    // user is signing up with google, which means their email is already verified
                    emailActivated: true
                })
    
                if(response.status===200){
                    // save user session token to local storage and move to the dashboard or swap page after 2 seconds
                    const userSessionToken = response.data.userSessionToken;
                    setTimeout(() => {
                        localStorage.setItem('userSessionToken', userSessionToken)
                        setLoading(false)
                        navigate('/dashboard'); 
                    }, 2000);
                    clearTimeout();
                }
    
            } catch (error) {
                if(error.response.data&&error.response.data.error){
                    const set_error_params={position:"translateY(0%)",message:error.response.data.error}
                    setError(set_error_params);
                    await closeErrorModal()
                    setLoading(false)
                }else{
                    const set_error_params={position:"translateY(0%)",message:"Something Went Wrong"}
                    setError(set_error_params);
                    await closeErrorModal()
                    setLoading(false)
                }
            }

        })
        .catch(async(err) => {
            const set_error_params={position:"translateY(0%)",message:"Something Went Wrong"}
            setError(set_error_params);
            await closeErrorModal()
            setLoading(false)
        });

    }

    // const backToInitialSignupModel=()=>{
    //     const params_config={
    //         showNameSection:"flex",
    //         showSecuritySection:"none",
    //         message:"Let's Get You Started"
    //     }
    //     setDisplaySection(params_config)
    // }



    return (
        <>
        <Helmet>
            <title>Handiswap | Get Started</title>
        </Helmet>
            {loading&&<LoadingModalComponent showModal={loading} />}
            <ErrorModalComponent delay="4000" position={error.position} message={error.message} />

            <Container>

                <AuthParentContainer>

                    <AuthInnerParent onSubmit={signupFunc}>

                        <AuthHeaderContainer>
                            <LogoContainer>
                                <ContainerImage src={lumina_logo}/>
                            </LogoContainer>
                            {/* <h4>Lumina</h4> */}
                        </AuthHeaderContainer>

                        <CustomGoogleSignIn onClick={() => login()}><GoogleIconCon><GoogleImage src={GoogleIcon}/></GoogleIconCon>Signup with Google</CustomGoogleSignIn>

                        <OrSection>
                            <OrLine/>
                            or
                            <OrLine/>
                        </OrSection> 
                        

                        {!showEmailSection
                        ?<>
                            <Inputabel>
                                First Name
                                <InputContainer>
                                    <Input value={firstName} onChange={(e)=>setFirstName(e.target.value)} type="text" placeholder="first name"/>
                                </InputContainer>
                            </Inputabel>

                            <Inputabel>
                                Last Name
                                <InputContainer>
                                    <Input value={lastName} onChange={(e)=>setLastName(e.target.value)} type="text" placeholder="last name"/>
                                </InputContainer>
                            </Inputabel>

                            <Inputabel>
                                Nationality
                                <InputContainer>
                                    <Input value={nationality} onChange={(e)=>setNationality(e.target.value)} type="text" placeholder="country"/>
                                </InputContainer>
                            </Inputabel>

                            <ActionButton onClick={()=>setShowEmailSection(true)} >Continue</ActionButton>
                        </>

                        :<>
                            <div>
                                <BackButton onClick={()=>setShowEmailSection(false)}><FaAngleLeft /> back</BackButton>
                            </div>
                            <Inputabel>
                                Email
                                <InputContainer>
                                    <Input value={email} onChange={(e)=>{
                                        setEmail(e.target.value)
                                        // check if email is valid
                                        if (!emailRegex.test(e.target.value)) {
                                            setIsEmailValid(false)
                                        } else {
                                            setIsEmailValid(true)
                                        }
                                    }} type="email" placeholder="xxx@xxx.xxx"/>
                                    {email&&!isEmailValid?(<FcCancel />):email&&isEmailValid?(<FaCheck color="var(--lumina-brand-color)"/>):null}
                                </InputContainer>
                            </Inputabel>

                            <Inputabel>
                                Password
                                <InputContainer>
                                    <Input value={password} onChange={(e)=>setPassword(e.target.value)}  type={passwordState} placeholder="Password"/>

                                    <span className="password_show" onClick={()=>{RevealPassword()}} style={{display:revealVisibiityState}}>{passwordTextState}</span>
                                    <span className="password_hide" onClick={()=>{HidePassword()}} style={{display:hiddenVisibiityState}} >{passwordTextState}</span>
                                </InputContainer>
                            </Inputabel>

                            <Inputabel>
                                Confirm Password
                                <InputContainer>
                                    <Input value={confirmPassword} onChange={(e)=>setConfirmPassword(e.target.value)}  type={passwordState} placeholder="Password"/>

                                </InputContainer>
                            </Inputabel>

                            <div style={{color: 'red', fontSize:'12px'}}>{password!==''&&confirmPassword!==''&&confirmPassword!==password?'Passwords must match':''}</div>


                            <ActionButton type='submit' style={{cursor: isEmailValid && firstName && lastName && email && password && confirmPassword?'pointer':'not-allowed'}} onClick={signupFunc} >Sign Up</ActionButton>

                            
                        </>}

                        <BySigninUpText>
                            By signing up, I agree with Handiswap's <Link className="transparent-click" to='/terms-of-service' style={{color:'var(--lumina-brand-color)'}}>Terms of Service</Link> and <Link to='/privacy-policy' className="transparent-click" style={{color:'var(--lumina-brand-color)'}}>Privacy Policy</Link>.
                        </BySigninUpText>

                        <HaveAnAccountText>Already have an account? <Link className="transparent-click" to={'/signin'} style={{fontWeight:'normal', color:'var(--lumina-brand-color)'}}>SignIn</Link></HaveAnAccountText>

                    </AuthInnerParent>
                </AuthParentContainer>

                <BannerSection>
                    <BannerImageContainer>
                        <BannerImage src={signup_banner}/>
                    </BannerImageContainer>
                </BannerSection>
                
                
            </Container>

        </>
    )
}

export default Signin