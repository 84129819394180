import styled,{keyframes} from "styled-components"

// define styled elements 
const LoadingParentContainer=styled.div`
    // display:none;
    // position:absolute;
    // background-color:rgba(0, 0, 0, 0.744);
    // height:100vh;
    // width:100vw;
    // overflow:hidden;
    // backdrop-filter:blur(20px);
    // justify-content:center;
    // align-items:center;
    // flex-direction:column;
    // gap:1em;
    // text-align:center;
    // z-index:1;

    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 10;
    gap: 10px;
    background: rgb(0,0,0,0.4);
    backdrop-filter: blur(20px);

`;

const rotate=keyframes`
    from {
    transform:rotate(0deg);
    }
    to{
    transform:rotate(360deg)
    }
`

const LoadingSpinnerElement=styled.div`
    padding:10px;
    background-color:transparent;
    border-radius:100px;
    width:3px;
    height:3px;
    border-style:solid;
    border-left-color:var(--lumina-brand-color);
    border-top-color:var(--lumina-brand-color);
    border-width:5px;
    border-right-color:rgba(214, 214, 212, 0.39);
    border-bottom-color:rgba(214, 214, 212, 0.39);
    transition:linear,500ms;
    animation:${rotate} 1s linear infinite;
`;

const LoaderTxt=styled.span`
    color:var(--lumina-text-color);
    font-size:14px;
`

const LoadingModalComponent=(props)=>{

    if(props){
        document.body.style.overflow = 'hidden';
    }else{
        document.body.style.overflow = '';
    }
    return(
        <>
        <LoadingParentContainer style={{display:props.showModal===true?'flex' :'none'}} >
            <LoadingSpinnerElement>
            </LoadingSpinnerElement>
            <LoaderTxt>Please Wait... </LoaderTxt>
        </LoadingParentContainer>
        </>
    )
}

export default LoadingModalComponent;