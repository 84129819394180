

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ArrowBackIcon from "../assets/arrow-back-icon.svg";
import { CgClose } from 'react-icons/cg';
import axios from 'axios';

const Container = styled.div`

`;

const BodyContainer = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media screen and (max-width: 900px){
        gap: 25px;
    }
`;
const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 2px var(--lumina-border-line-color);
    padding: 20px
`;
const HeaderIconsCon = styled.div`
    padding: 10px;
    border-radius: 100%;
    width: 13px;
    height: 13px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    &:hover {
        background: var(--lumina-text-box-color);
    }
`;
const HeaderIcons = styled.img`
    width: 100%;
    height: 100%;
`;
const HeaderText = styled.h2`
    color: var(--lumina-text-color);
    font-size: 15px;
    font-weight: 500;
`;
const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    background: var(--lumina-text-box-color);
    padding: 10px;
    border-radius: 10px;

    @media screen and (max-width: 750px) {
        padding: 15px;
        font-size: 15px;
    }
`;
const SearchBox = styled.input`
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    & {
        -moz-appearance: textfield; /* Firefox */
    }

    background: none;
    outline: none;
    border: none;
    color: var(--lumina-text-color);
    flex: 1;
    font-size: 16px;
`;
const AvailableCurrencySectionBody = styled.div`
    flex: 1;
`;
const InnerAvailableCurrencySectionBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;

    height: 250px;
    overflow-y: scroll;


    

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background: var(--lumina-text-box-color);
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    @media screen and (max-width: 500px){
        height: 350px;
    }
`;
const AvailableCurrencyCon = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 10px;
`;
const AvailablecurrencyItem = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 10px 0px;
    border-radius: 20px;

    &:hover {
        background: var(--lumina-text-box-color);
    }
`;
const AvailablecurrencyImageAndTextSection = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
`;
const AvailablecurrencyImageCon = styled.div`
    display: flex;
    align-items: center;
    width: 30px;
    height: 30px;
    background: var(--lumina-text-color);
    border-radius: 100%;
    overflow: hidden;
`;
const AvailablecurrencyImage = styled.img`
    width: 100%;
    height: 100%;
`;
const AvailablecurrencyTextSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
`;
const AvailablecurrencyName = styled.h4`
    color: var(--lumina-text-color);
    font-size: 13px;
`;
const AvailablecurrencyPrice = styled.h4`
    color: var(--lumina-text-color);
    font-size: 11px;
    font-weight: 500;
`;


const ShimmeringCountriesContainer = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
`;
const ShimmeringItem = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
`;
const ShimmeringImage = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: var(--lumina-text-box-color);

    position: relative;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -150%;
        height: 100%;
        width: 150%;
        background: linear-gradient(
            90deg,
            transparent,
            rgba(255, 255, 255, 0.2),
            transparent
        );
        animation: shimmer 2s infinite;
    }

    @keyframes shimmer {
        0% {
            transform: translateX(-100%);
        }
        100% {
            transform: translateX(100%);
        }
    }
`;
const ShimmeringNameAndCurrencyCon = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;
const ShimmeringName = styled.div`
    padding: 6px;
    border-radius: 10px;
    background: var(--lumina-text-box-color);
    width: 100px;

    position: relative;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -150%;
        height: 100%;
        width: 150%;
        background: linear-gradient(
            90deg,
            transparent,
            rgba(255, 255, 255, 0.2),
            transparent
        );
        animation: shimmer 2s infinite;
    }

    @keyframes shimmer {
        0% {
            transform: translateX(-100%);
        }
        100% {
            transform: translateX(100%);
        }
    }
`;
const ShimmeringCurrency = styled.div`
    padding: 5px;
    border-radius: 10px;
    background: var(--lumina-text-box-color);
    width: 150px;

    position: relative;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -150%;
        height: 100%;
        width: 150%;
        background: linear-gradient(
            90deg,
            transparent,
            rgba(255, 255, 255, 0.2),
            transparent
        );
        animation: shimmer 2s infinite;
    }

    @keyframes shimmer {
        0% {
            transform: translateX(-100%);
        }
        100% {
            transform: translateX(100%);
        }
    }
`;

const CurrenciesComponent = ({ toggleModal, setSelectedFiat, setSelectedFiatIcon, setSelectedCurrencyCode }) => {
    const [search, setSearch] = useState('');
    const [filteredSearch, setFilteredSearch] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currencies, setCurrencies] = useState([]);
    const [error, setError] = useState('');
    const loadingLength = Array.from({length: 10})


    const fetchCurrencies = async () => {
        try {
            const response = await axios.get('https://restcountries.com/v3.1/all');

            if (response.status === 200) {
                const countries = response.data;
                const currenciesList = countries.flatMap(country => {
                    if (country.currencies) {
                        return Object.keys(country.currencies).map(currencyCode => ({
                            countryName: country.name.common,
                            currencyCode,
                            currencyName: country.currencies[currencyCode].name,
                            currencySymbol: country.currencies[currencyCode].symbol,
                            flag: country.flags.png
                        }));
                    }
                    return [];
                });
                setCurrencies(currenciesList);
                setFilteredSearch(currenciesList);
                setLoading(false);
            } else {
                setError('Something went wrong');
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching countries data:', error);
            setError('Something went wrong');
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCurrencies();
    }, []);

    const searchFilter = (e) => {
        const query = e.target.value;
        setSearch(query);
        filterItems(query);
    };

    const filterItems = (query) => {
        const lowercaseQuery = query.toLowerCase();
        const filtered = currencies.filter(currency =>
            currency.countryName.toLowerCase().includes(lowercaseQuery) ||
            currency.currencyName.toLowerCase().includes(lowercaseQuery) ||
            currency.currencyCode.toLowerCase().includes(lowercaseQuery)
        );
        setFilteredSearch(filtered);
    };

    return (
        <Container>
            <Header>
                <HeaderIconsCon className='transparent-click' onClick={() => toggleModal('available-currencies')}>
                    <HeaderIcons src={ArrowBackIcon} />
                </HeaderIconsCon>

                <HeaderText>Select a currency</HeaderText>
            </Header>

            <BodyContainer>
                <SearchContainer>
                    <SearchBox
                        value={search}
                        placeholder="Search available currencies"
                        onChange={searchFilter}
                        type="text"
                    />
                    {search && (
                        <CgClose
                            className='transparent-click'
                            style={{ color: 'var(--lumina-text-color)', cursor: 'pointer' }}
                            onClick={() => {
                                setSearch('');
                                setFilteredSearch(currencies);
                            }}
                        />
                    )}
                </SearchContainer>

                <AvailableCurrencySectionBody>
                    <InnerAvailableCurrencySectionBody>
                        {loading ? (
                            <ShimmeringCountriesContainer>
                                {loadingLength.map((_, index)=>(
                                    <ShimmeringItem key={index}>
                                        <ShimmeringImage/>
                                        <ShimmeringNameAndCurrencyCon>
                                            <ShimmeringName/>
                                            <ShimmeringCurrency/>
                                        </ShimmeringNameAndCurrencyCon>
                                    </ShimmeringItem>
                                ))}
        
                            </ShimmeringCountriesContainer>
                        ) : error ? (
                            <h3 style={{ color: 'red', textAlign:'center' }}>{error}</h3>
                        ) : (
                            <AvailableCurrencyCon>
                                {filteredSearch.map((currency, index) => (
                                    <AvailablecurrencyItem
                                        key={index}
                                        className='transparent-click'
                                        onClick={() => {
                                            setSelectedFiat(currency.currencyName);
                                            setSelectedFiatIcon(currency.flag);
                                            toggleModal('available-currencies');
                                            setSelectedCurrencyCode(currency.currencyCode)
                                        }}
                                    >
                                        <AvailablecurrencyImageAndTextSection>
                                            <AvailablecurrencyImageCon>
                                                <AvailablecurrencyImage src={currency.flag} />
                                            </AvailablecurrencyImageCon>

                                            <AvailablecurrencyTextSection>
                                                <AvailablecurrencyName>{currency.countryName}</AvailablecurrencyName>
                                                <AvailablecurrencyPrice>{`${currency.currencyCode} (${currency.currencyName})`}</AvailablecurrencyPrice>
                                            </AvailablecurrencyTextSection>
                                        </AvailablecurrencyImageAndTextSection>
                                    </AvailablecurrencyItem>
                                ))}
                            </AvailableCurrencyCon>
                        )}
                    </InnerAvailableCurrencySectionBody>
                </AvailableCurrencySectionBody>
            </BodyContainer>

        </Container>
    );
};

export default CurrenciesComponent;
