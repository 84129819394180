import styled from "styled-components";
import SheildIcon from "../assets/shield-icon.svg"
import { useEffect, useRef, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import CoinsComponent from "./CoinsComponent.js";
import CurrenciesComponent from "./CurrenciesComponent.js.js";
import WalletsComponent from "./WalletsComponent.js";
import axios from "axios";
import { API_Route } from "../utils/Api.js";
import PaymentOptionsModal from "./PaymentOptionsModal.js";
// import { clusterApiUrl, Connection, Keypair, Message, SystemProgram, SYSTEM_INSTRUCTION_LAYOUTS, Transaction, LAMPORTS_PER_SOL, } from '@solana/web3.js';
// import { FaGasPump } from "react-icons/fa6";
import { FaExchangeAlt } from "react-icons/fa";




const Container = styled.div`

`;

// Swap bpdy section  styles here ===============================================================================================*/}


const SwapSectionBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;

`;

const SwapInputAndButton = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    background: var(--lumina-input-icon-bg);
    padding: 4px;
    border-radius: 20px;
    
    @media screen and (max-width: 750px){
        max-width: 100%;
    }
`;
const MainText = styled.h2`
    color: var(--lumina-text-color);
    font-size: 20px;
    text-align: center;

    // @media screen and (max-width: 750px){
    //     font-size: 25px;
    // }
`;
const SwapInputLabel = styled.div`
    color: var(--lumina-grey-color);
    font-size: 12px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    background: var(--lumina-text-box-color);
    padding: 15px;
    border-radius: 20px;
    font-weight: 500;

`;
const SwapInputContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    // padding: 5px;
    width: 100%;
    background: var(--lumina-text-box-color);
`;
const SwapInput = styled.input`
    /* Hide up and down arrows in number input */
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    & {
        -moz-appearance: textfield; /* Firefox */
    }
    
    background: none;
    outline: none;
    border: none;
    color: var(--lumina-text-color);
    flex: 1;
    width: 60%;
    font-weight: 600;
    font-size: 30px;

`;
const SwapInputDropDownIcon = styled.h4`
    color: var(--lumina-text-color);
    display: flex;
    gap: 10px;
    align-items: center;
    border: solid 3px var(--lumina-input-icon-bg);
    padding: 10px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 13px;
    background: var(--lumina-text-box-color);
`;

const SwapInputDropDownIconImageCon = styled.div`
    background: var(--lumina-text-color);
    width: 20px;
    height: 20px;
    border-radius: 100%;
    // overflow: hidden;
    position: relative;
`;
const SwapInputDropDownIconImage = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 100%;
`;

const AvailableCoinNetworkImageCon = styled.div`
    display: flex;
    align-items: center;
    width: 10px;
    height: 10px;
    background: var(--lumina-text-color);
    border-radius: 100%;
    position: absolute;
    top:-4px;
    right:-4px;
    border: solid 1.5px var(--lumina-text-color);
    

`;
const AvailableCoinNetworkImage = styled.img`
    width: 100%;
    height: 100%;
`;

const CommisionSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    background: var(--lumina-input-icon-bg);
    padding: 10px;
    border-radius: 20px;
`;


const CommissionTransactionFeeCon = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const CommissionTransactionFeeText = styled.h4`
    color: var(--lumina-text-color);
    font-size: 13px;

    @media screen and (max-width: 750px){
        // font-size: 15px;
    }
`;
const CommissionTransactionFeePrice = styled.h4`
    color: var(--lumina-grey-color);
    font-size: 13px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 10px;
   
`;

const SecuredByText = styled.h4`
    color: var(--lumina-text-color);
    font-size: 11px;
    display: flex;
    align-items: center;
    gap: 10px;
    margin: auto;

    
`;
const SheildIconImg = styled.img`
    width: 20px;
    height: 20px;
`;

const ActionButton = styled.h4`
    font-size: 15px;
    border-radius: 10px;
    text-align: center;
    font-weight: 500;
    // color: var(--lumina-brand-color);
    // background: var(--lumina-transparent-brand-color);
    background: var(--lumina-brand-color);
    color: black;
    border-radius: 20px;
    padding: 15px 30px;
    cursor: pointer;
    font-weight: 600;

    @media screen and (max-width: 750px){
        padding: 20px 30px;
    }

    &:hover{
        background: var(--lumina-transparent-brand-color-hover);
    }
`;




// Modal sections =============================================================================================== */}
const ModalBackground = styled.div`
    position: fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background: rgb(0,0,0,0.3);
    z-index:20;
    align-items:center;
    justify-content:center;
    display: flex;
    backdrop-filter: blur(3px);
    opacity: ${(props) => (props.isvisible ? '1' : '0')};
    visibility: ${(props) => (props.isvisible ? 'visible' : 'hidden')};
    transition: opacity 0.3s ease, visibility 0.3s ease;

    
`;
const ModalContainer = styled.div`
    border-radius: 10px;
    background: var(--lumina-modal-bg-color);
    width: 350px;
    // border: solid 1px #5a5a5a;

    @media screen and (max-width: 500px){
        position: fixed;
        bottom: 0;
        right:0;
        left:0;
        width: auto;
        border-radius: 0px;

        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }

    // /* Slide-in transition */
    // transform: ${(props) => (props.isvisible ? 'translateY(100%)' : 'translateY(0)')};
    // transition: transform 0.3s ease;

    
`;


const Loader = styled.div`
    padding: 10px;
    border-radius: 10px;
    background: var(--lumina-text-box-color);
    width: 100px;
    flex: 1;

    position: relative;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -150%;
        height: 100%;
        width: 150%;
        background: linear-gradient(
            90deg,
            transparent,
            rgba(255, 255, 255, 0.2),
            transparent
        );
        animation: shimmer 2s infinite;
    }

    @keyframes shimmer {
        0% {
            transform: translateX(-100%);
        }
        100% {
            transform: translateX(100%);
        }
    }
`;
const SmallLoader = styled.div`
    padding: 5px;
    border-radius: 10px;
    background: var(--lumina-text-box-color);
    width: 100px;
    flex: 1;

    position: relative;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -150%;
        height: 100%;
        width: 150%;
        background: linear-gradient(
            90deg,
            transparent,
            rgba(255, 255, 255, 0.2),
            transparent
        );
        animation: shimmer 2s infinite;
    }

    @keyframes shimmer {
        0% {
            transform: translateX(-100%);
        }
        100% {
            transform: translateX(100%);
        }
    }
`;



function shortenTextWithParentheses(text, maxLength) {
    // Check if text is undefined or not a string
    if (typeof text !== 'string' || !text) {
      return ''; // Return an empty string if text is invalid
    }
  
    // Check if text length is already less than or equal to maxLength
    if (text.length <= maxLength) {
      return text; // Return the original text if it's already short enough
    }
  
    // Calculate the length of the first half of the text (excluding parentheses and ellipsis)
    const firstHalfLength = Math.floor((maxLength - 5) / 2); // -5 to account for "(...)"
  
    // Calculate the length of the second half of the text (excluding parentheses and ellipsis)
    const secondHalfLength = Math.ceil((maxLength - 5) / 2); // -5 to account for "(...)"
  
    // Extract the first and last parts of the text
    const firstHalf = text.slice(0, firstHalfLength);
    const secondHalf = text.slice(-secondHalfLength);
  
    // Construct the shortened text with parentheses in the middle
    const shortenedText = `${firstHalf}...${secondHalf}`;
  
    return shortenedText;
}

const SwapComponent = () => {

    const [youPayInput, setYouPayInput] = useState('');
    const [toRecieveInput, setToRecieveInput] = useState('');

    const [coinsModal, setCoinsModal] = useState(false);
    const [currenciesModal, setCurrenciesModal] = useState(false);
    const [walletsModal, setWalletsModal] = useState(false);
    const [paymentOptionsModal, setPaymentOptionsModal] = useState(false);

    const [selectedCoinIcon, setSelectedCoinIcon] = useState('')
    const [selectedCoin, setSelectedCoin] = useState('')
    const [selectedFiatIcon, setSelectedFiatIcon] = useState('')
    const [selectedFiat, setSelectedFiat] = useState('')

    const [selectedCurrencyCode, setSelectedCurrencyCode] = useState('')
    const [selectedBlockchain, setSelectedBlockchain] = useState('')
    const [selectedBlockchainNetworkImage, setSelectedBlockchainNetworkImage] = useState('')
    const [selectedBlockchainNetworkName, setSelectedBlockchainNetworkName] = useState('')
    const [selectedBlockchainAddress, setSelectedBlockchainAddress] = useState('')
    const [cryptoRate, setCryptoRate] = useState(null)
    const [loadingCryptoAmount, setLoadingCryptoAmount] = useState(false)
    

    // const [gasFee, setGasFee] = useState(0);

    const numberInputRef1 = useRef(null)
    const numberInputRef2 = useRef(null)


    // // gas fee calculator
    // useEffect(() => {
    //     const calculateFee = async () => {
    //         try {
    //             console.log('heyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy')
    //             const connection = new Connection(clusterApiUrl("testnet"), "confirmed");
    //             const payer = Keypair.generate();
    //             const recipient = Keypair.generate();

    //             const type = SYSTEM_INSTRUCTION_LAYOUTS.Transfer;
    //             const data = Buffer.alloc(type.layout.span);

    //             const layoutFields = Object.assign({ instruction: type.index });
    //             type.layout.encode(layoutFields, data);
                
    //             const recentBlockhash = await connection.getLatestBlockhash();

    //             const messageParams = {
    //                 accountKeys: [
    //                   payer.publicKey.toString(),
    //                   recipient.publicKey.toString(),
    //                   SystemProgram.programId.toString(),
    //                 ],
    //                 header: {
    //                   numReadonlySignedAccounts: 0,
    //                   numReadonlyUnsignedAccounts: 1,
    //                   numRequiredSignatures: 1,
    //                 },
    //                 instructions: [
    //                   {
    //                     accounts: [0, 1],
    //                     data: bs58.encode(data),
    //                     programIdIndex: 2,
    //                   },
    //                 ],
    //                 recentBlockhash: recentBlockhash.blockhash,
    //               };
                 
    //               const message = new Message(messageParams);
                 
    //             const fees = await connection.getFeeForMessage(message);
    //             console.log(`Estimated SOL transfer cost: ${fees.value} lamports`);


                
    //             // Convert lamports to SOL
    //             const solFee = fees.value / LAMPORTS_PER_SOL;

    //             setGasFee(`${solFee} SOL`);
    //         } catch (error) {
    //             console.error("Failed to calculate the fee:", error);
    //         }
    //     };

    //     calculateFee();
    // }, []);



    const handleInputChange = (event, input) => {
        const newValue = event.target.value;
        // Allow only numeric values
        if(input===1){
            // stopping the number input from having negative value
            if (newValue >= 0) {
                setYouPayInput(newValue);
                // if(exchangeRate){
                //     setToRecieveInput((youPayInput / exchangeRate).toFixed(6))
                // }else{
                //     setToRecieveInput(null)
                // }
            }else if(!newValue){
                setToRecieveInput('')
            }
        }else if(input===2){
            if (newValue >= 0) {
                setToRecieveInput(newValue);
            }
        }
        
    };


    // This stops the input from changing value when the number user scrolls on the input ===============================================================================================
    const handleWheel = (event) => {
        // Prevent scroll
        event.preventDefault();
    };

    useEffect(() => {
        const inputElement1 = numberInputRef1.current;
        const inputElement2 = numberInputRef2.current;
        if (inputElement1||inputElement2) {
            inputElement1.addEventListener('wheel', handleWheel);
            inputElement2.addEventListener('wheel', handleWheel);

            // Cleanup function to remove the event listener
            return () => {
                inputElement1.removeEventListener('wheel', handleWheel);
                inputElement2.removeEventListener('wheel', handleWheel);
            };
        }
    }, []);

    const toggleModal = (buttonClicked)=>{
        if(coinsModal || currenciesModal || walletsModal || paymentOptionsModal){

            setCoinsModal(false)
            setCurrenciesModal(false)
            setWalletsModal(false)
            setPaymentOptionsModal(false)

            window.history.back();
        }else{

            if(buttonClicked==='available-coins'){
                setCoinsModal(true)
                window.history.pushState(null, '', '#available-coins');
            }else if(buttonClicked==='available-currencies'){
                setCurrenciesModal(true)
                window.history.pushState(null, '', '#available-currencies');
            }else if(buttonClicked==='wallets'){
                setWalletsModal(true)
                window.history.pushState(null, '', '#wallets');
            }else if(buttonClicked==='payment-options'){
                setPaymentOptionsModal(true)
                window.history.pushState(null, '', '#payment-options');
            }
            
        }
    }

    useEffect(() => {
        const handlePopState = () => {
            setWalletsModal(false)
            setCoinsModal(false)
            setCurrenciesModal(false)
            setPaymentOptionsModal(false)
        };
    
        window.addEventListener('popstate', handlePopState);
    
        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, []);

    // This fetches the current exchange rate from coinmarketcap
    useEffect(() => {
        const fetchExchangeRate = async () => {
            try {
                setLoadingCryptoAmount(true);
                const userSessionToken = localStorage.getItem('userSessionToken');

                console.log(userSessionToken)

                console.log('Fetching exchange rate...');
                const response = await axios.post(`${API_Route}get-crypto-rate-from-coinmarketcap?symbol=${selectedBlockchain}&convert=${selectedCurrencyCode}`,{
                    userSessionToken
                });

                
                const rate = response.data.data[selectedBlockchain].quote[selectedCurrencyCode].price;
                console.log('Rate fetched:', rate);
                setCryptoRate(rate);
                setLoadingCryptoAmount(false)


            } catch (error) {
                console.error('Something went wrong:', error);
            }

        };

        if (selectedCoin && selectedCurrencyCode) {
            
            fetchExchangeRate();

        }
    }, [selectedCoin, selectedBlockchain, selectedCurrencyCode]);

    // clear the coin value if the fiat input is empty
    useEffect(()=>{
        if(!youPayInput){
            setToRecieveInput('')
        }
    },[youPayInput])



    // This calculate the amount of crypto the user is meant to recieve
    useEffect(() => {
        // Calculate the amount to receive whenever the youPayInput or exchangeRate changes
        if (youPayInput && cryptoRate && selectedCurrencyCode) {
            const receivedAmount = (youPayInput / cryptoRate).toFixed(5);

            setToRecieveInput(receivedAmount);

            // subtract 15% as charge
            // setToRecieveInput(receivedAmount * 0.85);
        }
    }, [youPayInput, cryptoRate, selectedCurrencyCode]);

    // Hide the scroll bar when a modal is open ===============================================================================================
    if(coinsModal || currenciesModal || walletsModal || paymentOptionsModal){
        document.body.style.overflow = 'hidden';
    }else{
        document.body.style.overflow = '';
    }

    return (            

        <Container>
            
            {/* Swap UI starts here ===============================================================================================*/}
            <SwapSectionBody>

                <MainText>Swap fiat for Crypto in a flash</MainText>
                {/* <SubText>Exchange Your Fiat For Crypto In A flash</SubText> */}

                {/* BOX that houses the input and swap button =============================================================================================== */}
                <SwapInputAndButton>
                    {/* currency fiat dropdown =============================================================================================== */}
                    <SwapInputLabel>
                        You Pay  {selectedCurrencyCode&&selectedCurrencyCode}
                        <SwapInputContainer>
                            <SwapInput ref={numberInputRef1} value={youPayInput} onChange={(e)=>handleInputChange(e,1)} placeholder="0" type="number"/>
                            

                            {/* Icons for money  */}
                            <SwapInputDropDownIcon className="transparent-click" onClick={()=>toggleModal('available-currencies')}>
                                {selectedFiat
                                ?<>
                                    <SwapInputDropDownIconImageCon>
                                        <SwapInputDropDownIconImage src={selectedFiatIcon}/>
                                    </SwapInputDropDownIconImageCon>
                                    {selectedFiat}
                                </>
                                :'Select a currency'}
                                
                                <IoIosArrowDown />
                            </SwapInputDropDownIcon>
                        </SwapInputContainer>
                        <br/>

                        {/* {!selectedCurrencyCode&&!fiatExchangeRate?<br/>:(`${fiatExchangeRate.toFixed(2)} ${selectedCurrencyCode} ~ 1 USD`)} */}
                    </SwapInputLabel>
                    

                    {/* Coin dropdown ===============================================================================================  */}
                    <SwapInputLabel>
                        You Recieve

                        <SwapInputContainer>
                            {loadingCryptoAmount
                            ?<Loader/>
                            :<SwapInput ref={numberInputRef2} value={toRecieveInput} onChange={(e)=>handleInputChange(e,2)} placeholder="0" readOnly type="number"/>}

                            {/* Icons for crypto  */}
                            <SwapInputDropDownIcon className="transparent-click" onClick={()=>toggleModal('available-coins')}>
                                {selectedCoin
                                ?<>
                                    <SwapInputDropDownIconImageCon>
                                        <SwapInputDropDownIconImage src={selectedCoinIcon}/>

                                        {selectedBlockchainNetworkImage&&
                                        <AvailableCoinNetworkImageCon>
                                            <AvailableCoinNetworkImage src={selectedBlockchainNetworkImage}/>
                                        </AvailableCoinNetworkImageCon>}
                                    </SwapInputDropDownIconImageCon>
                                    {selectedCoin}
                                </>
                                :'Select a coin'}
                                <IoIosArrowDown />
                            </SwapInputDropDownIcon>
                        </SwapInputContainer>

                        {/* this is just here to create a space on input bottom */}
                        {/* {!youPayInput||!selectedBlockchain||!exchangeRate||!selectedCoin||<br/>} */}

                        {!toRecieveInput?<br/>:loadingCryptoAmount?<SmallLoader/>:(`${cryptoRate.toFixed(2)} ${selectedCurrencyCode} ~ 1 ${selectedBlockchain}`)}
                        {/* {toRecieveInput&&} */}
                    </SwapInputLabel>


                    {/* add wallet and connect button =============================================================================================== */}
                    {/* check if coin has been selected first and if show a connect wallet button button if not. */}
                    {selectedCoin

                    // if a coin has already been selected, the button should open a modal for the user to choose the wallet they to receive their crypto.
                    // we are sending the coin type to the wallets so the user will see only the wallets tey saved for that particular coin. the parameters are seen at the bottom.
                    ?<ActionButton className="transparent-click" onClick={()=> selectedBlockchainAddress
                        // open payment options modal if a currency has been chosen, else open currencies modal. also che if toRecieveInput has value
                        ?selectedCurrencyCode?toRecieveInput&&toggleModal('payment-options'):toggleModal('available-currencies')
                        // pick a wallet address if none is chosen
                        :toggleModal('wallets')}> {selectedBlockchainAddress? `${selectedCurrencyCode? `Buy ${selectedBlockchain}` : `Select a currency`}`:'Add wallet'} </ActionButton>

                    :<ActionButton className="transparent-click" onClick={()=>toggleModal('available-coins')}>Select a coin</ActionButton>}

                </SwapInputAndButton>


                

                {/* transactions detatils under the swap button  */}

                {!selectedBlockchainAddress&&<br/>}

                {selectedBlockchainAddress&&
                <CommisionSection>  
                    {/* the user can only see this when they have already selected an address and they want to change the address they want to recieve the crypto */}
                    
                    <CommissionTransactionFeeCon>
                        <CommissionTransactionFeeText>{shortenTextWithParentheses(selectedBlockchainAddress, 15)}</CommissionTransactionFeeText>
                        <CommissionTransactionFeePrice style={{cursor:'pointer', fontSize:'12px'}} onClick={()=>toggleModal('wallets')}><FaExchangeAlt /> change wallet</CommissionTransactionFeePrice>
                    </CommissionTransactionFeeCon>
                </CommisionSection>}


                <SecuredByText><SheildIconImg src={SheildIcon}/> Secured by <span style={{color: "var(--lumina-brand-color)"}}>Handiswap</span></SecuredByText>


            </SwapSectionBody>

            
            
            {/* the modal popups for the swap component */}
            <ModalBackground onClick={(e)=>{
                toggleModal('wallets')
            }} isvisible={coinsModal || currenciesModal || walletsModal || paymentOptionsModal}>
                <ModalContainer className="modal"  onClick={(e) => e.stopPropagation()}>
                    {coinsModal?<CoinsComponent setSelectedBlockchainNetworkName={setSelectedBlockchainNetworkName} setSelectedBlockchainNetworkImage={setSelectedBlockchainNetworkImage} toggleModal={toggleModal} setSelectedCoinIcon={setSelectedCoinIcon} setSelectedCoin={setSelectedCoin} setSelectedBlockchain={setSelectedBlockchain} setSelectedBlockchainAddress={setSelectedBlockchainAddress}/>
                    :currenciesModal?<CurrenciesComponent setSelectedCurrencyCode={setSelectedCurrencyCode} toggleModal={toggleModal} setSelectedFiatIcon={setSelectedFiatIcon} setSelectedFiat={setSelectedFiat}/>
                    :walletsModal?<WalletsComponent toggleModal={toggleModal} setSelectedBlockchainAddress={setSelectedBlockchainAddress} setSelectedBlockchain={setSelectedBlockchain} selectedBlockchain={selectedBlockchain}/>
                    :paymentOptionsModal?<PaymentOptionsModal selectedBlockchainAddress={selectedBlockchainAddress} selectedBlockchain={selectedBlockchain} youPayInput={youPayInput} toRecieveInput={toRecieveInput} selectedCurrencyCode={selectedCurrencyCode} toggleModal={toggleModal} />:''}
                </ModalContainer>
            </ModalBackground>

            <span style={{display:'none'}}>{selectedBlockchainNetworkName}</span>


        </Container>
    )
}

export default SwapComponent;
