import styled, { keyframes } from "styled-components"
import SwapComponent from "../components/SwapComponent";
import PolygonIcon from "../assets/polygon.png"
import SolanaIcon from "../assets/solana.png"
import USDTIcon from "../assets/usdt.png"
import BNBIcon from "../assets/bnb.png"
import TONIcon from "../assets/ton.png"
import SwapHeader from "../components/SwapHeader";
import { useEffect, useState } from "react";
import {Helmet} from "react-helmet"


const Container = styled.div`
    
`;

const InnerContainer = styled.div`
    width: 1200px;
    margin: auto;
    padding: 10px;


    @media screen and (max-width:1250px){
        width: auto;
    }
`;

const BodyContainer = styled.div`
    display: flex;
    justify-content: center;
    // padding: 20px;
    background: none;
`;

const float = keyframes`
    0% { transform: translateY(0); }
    50% { transform: translateY(-50px); }
    100% { transform: translateY(0); }
`;



const SwapContainer = styled.div`
    width: 430px;
    // margin: auto;
    z-index: 2;
    
    
    @media screen and (max-width: 750px){
        max-width: 100%;
    }
`;




const BlobImageIcon = styled.div`
    width: ${props => props.width};
    height: ${props => props.height};
    background-color: white;
    background-image: url(${props => props.backgroundImage});
    // background-image: url(${USDTIcon});
    background-size: cover;
    border-radius: 100%;
    background-position: center;
    z-index: 1;
    position: absolute;
    filter: blur(5px) !important;
    -webkit-filter: blur(5px) !important;
    animation: ${float} 10s ease-in-out infinite;
    // backdrop-filter: blur(10px);



    &:hover {
        filter: none !important;
        width: 75px;
        height: 75px;


        // Borderline around the blob
        &::before {
            content: "";
            position: absolute;
            top: -10px; 
            left: -10px; 
            width: calc(100% + 20px); 
            height: calc(100% + 20px);
            border: 1px solid ${props => props.backgroundColor};
            border-radius: 50%;
            opacity: 0;
            transition: opacity 0.3s ease, transform 0.3s ease;
        }

        // Text displayed inside the border
        &::after {
            content: "${props => props.priceToDollar}"; 
            position: absolute;
            top: 50%; 
            left: -1px;
            transform: translateX(-50%);
            color: white;
            font-size: 14px;
            background-color: #333; 
            padding: 2px 6px; 
            border-radius: 4px;
            opacity: 0;
            transition: opacity 0.3s ease;
        }

        // Hover effects for the border and text
        &:hover::before {
            opacity: 1;
            transform: scale(1.2); 
        }

        &:hover::after {
            opacity: 1;
        }
    }
`;



const Swap = () => {

    // define coin price and name 
    const [coinPrice,setcoinPrice]=useState({
        ton_price:0,
        sol_price:0,
        bnb_price:0,
        matic_price:0,
    })

    const url=`https://min-api.cryptocompare.com/data/pricemulti?fsyms=SOL,BNB,TONCOIN,MATIC,NEAR,&tsyms=USD,NGN&api_key=${process.env.REACT_APP_PRICE_COMPARE_KEY}`;

    //function for formating number 
    let formatCoinPrice=(coin_type,currency='USD')=>{
         const currencyOptions = { 
            style: 'currency', 
            currency: currency
          };
          return coin_type.toLocaleString('en-US', currencyOptions);
    }

    // fetch live coinn price 
    useEffect(()=>{
        fetch(url)
            .then((response)=>{
                return response.json()
            })
            .then((data)=>{
                // define coin price params 
                let coin_price_params={
                    ton_price:formatCoinPrice(data.TONCOIN.USD),
                    sol_price:formatCoinPrice(data.SOL.USD),
                    bnb_price:formatCoinPrice(data.BNB.USD),
                    matic_price:formatCoinPrice(data.MATIC.USD),
                }
            setcoinPrice(coin_price_params);
        })
    },[url])


    return (
        <>
        <Helmet>
            <title>Handiswap | Swap</title>
        </Helmet>
        <Container>
            <SwapHeader />

            <InnerContainer>
                

                {/* {coins.map((coin, index)=>(
                    <>
                        <BlobImageIcon style={{top:'20vh', left:'10vw'}} backgroundImage={USDTIcon} height='60px' width='60px'/>
                    </>
                ))} */}
        
                <BlobImageIcon priceToDollar={coinPrice.bnb_price} backgroundColor={'#4cae95'} style={{top:'20vh', left:'10vw'}} backgroundImage={USDTIcon} height='60px' width='60px'/>
                <BlobImageIcon priceToDollar={coinPrice.bnb_price} backgroundColor={'#f0b90b'} style={{top:'70vh', left:'14vw'}} backgroundImage={BNBIcon} height='70px' width='70px'/>
                <BlobImageIcon priceToDollar={coinPrice.matic_price} backgroundColor={'#8247e5'} style={{top:'50vh', left:'30px'}} backgroundImage={PolygonIcon} height='50px' width='50px'/>
                <BlobImageIcon priceToDollar={coinPrice.sol_price} backgroundColor={'#1e0552'} style={{top:'30vh', right:'8vw'}} backgroundImage={SolanaIcon} height='50px' width='50px'/>
                <BlobImageIcon priceToDollar={coinPrice.ton_price} backgroundColor={'#26adf0'} style={{top:'60vh', right:'10vw'}} backgroundImage={TONIcon} height='65px' width='65px'/>
                

                <BodyContainer>
                    <SwapContainer>
                        <SwapComponent/>
                    </SwapContainer>
                </BodyContainer>
            </InnerContainer>  
        </Container>
        </>
    )
}

export default Swap




