import React, { useState, useRef } from "react";
import styled from "styled-components";
import { FaPlus, FaMinus } from "react-icons/fa";
import { Link } from "react-router-dom";

const Container = styled.div`
    display: flex;
    padding: 20px;
    // gap: 20px;
    flex-direction: column;
    


    width: 1200px;
    margin: auto;

    
    @media screen and (max-width: 1250px){
        width: auto;
        // padding: 10px;
    }

    @media screen and (max-width: 500px) {
        // padding: 10px;
    }
`;
const FAQLabel = styled.h1`
    color: var(--lumina-text-color);
    font-weight: 600;
    font-size: 25px;
    text-align: center;
`;
const FAQContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 30px;
    width: 100%;
    flex-direction: column;
`;
const FAQItem = styled.div`
    width: 100%;
    // padding: 10px 0;
    border-radius: 10px;
    // cursor: pointer;

    // &:hover{
    //     background: var(--lumina-modal-bg-color);
    // }
`;
const ItemQuestionHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border-radius: 5px;
    padding: 20px 0px;
`;
const FAQIndex = styled.h4`
    margin-right: 20px;
    font-size: 18px;
    font-weight: 600;
    border: solid 2px var(--lumina-grey-color);
    color: var(--lumina-grey-color);
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
`;
const FAQQuestion = styled.h1`
    font-size: 15px;
    font-weight: 600;
    flex: 1;
    margin: 0;
    color: var(--lumina-text-color);

`;
const FAQAnswer = styled.p`
    font-size: 15px;
    overflow: hidden;
    max-height: ${(props) => (props.isVisible ? props.maxHeight : "0px")};
    transition: max-height 0.3s ease;
    padding: ${(props) => (props.isVisible ? "10px" : "0px")};
    border-radius: 5px;
    margin-top: ${(props) => (props.isVisible ? "10px" : "0px")};
    color: var(--lumina-text-color);
`;
const FAQPlusSign = styled.div`
    font-size: 18px;
    color: var(--lumina-grey-color);
`;

const FAQComponent = () => {
    const [activeIndex, setActiveIndex] = useState(null);
    // const [contentHeights, setContentHeights] = useState([]);

    const faqRefs = useRef([]);

    // useEffect(() => {
    //     setContentHeights(faqRefs.current.map(ref => ref.scrollHeight));
    // }, []);

    const toggleFAQ = (index) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    const faqs = [
        {
            question: "What is Handiswap?",
            answer:  (
                <> 
                    <span style={{color:'var(--lumina-brand-color)'}}>Handiswap</span> is a crypto platform that allows you to buy cryptocurrency for as little as one dollar.

                </>
            ),
        },
        {
            question: "How can I buy crypto on Handiswap?",
            answer: (
                <>
                    You can buy cryptocurrency by signing up on <span style={{color:'var(--lumina-brand-color)'}}>Handiswap</span> and following the instructions to complete a purchase. <br/><br/>
                    or you can <strong>watch</strong> the step by step video <Link style={{color:'var(--lumina-brand-color)'}} to='/video-link'>here</Link>
                </>
            ),
        },
        {
            question: "Is Handiswap secure?",
            answer: (
                <>
                    Yes, <span style={{color:'var(--lumina-brand-color)'}}>Handiswap</span> follows the best security practices in the industry to keep your account safe and we do not hold your coins for you, your coins are immediately sent to the wallet you provided
                </>
            ),
        },
        {
            question: "Can I sell crypto on Handiswap?",
            answer: (
                <>
                    Not yet. You can currently buy crypto on the <span style={{color:'var(--lumina-brand-color)'}}>Handiswap</span> platform, but the sell feature is coming soon, once it is ready, we are going to announce on all our social platforms
                </>
            ),
        },
    ];

    return (
        <Container>
            <FAQLabel>FAQ</FAQLabel>
            {faqs.map((faq, index) => (
                <FAQContainer key={index}>
                    <FAQItem style={{background: activeIndex===index?'var(--lumina-modal-bg-color)':''}}>
                        <ItemQuestionHeader className="transparent-click" onClick={() => toggleFAQ(index)}>
                            <FAQIndex>{index + 1}</FAQIndex>
                            <FAQQuestion>{faq.question}</FAQQuestion>
                            <FAQPlusSign>
                                {activeIndex === index ? <FaMinus /> : <FaPlus />}
                            </FAQPlusSign>
                        </ItemQuestionHeader>
                        <FAQAnswer
                            isVisible={activeIndex === index}
                            ref={(el) => (faqRefs.current[index] = el)}
                        >
                            {faq.answer}
                        </FAQAnswer>
                    </FAQItem>
                </FAQContainer>
            ))}
        </Container>
    );
};

export default FAQComponent;
