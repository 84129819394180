import axios from "axios";
import { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components"
import { API_Route } from "../utils/Api";
import { useNavigate } from "react-router-dom";
import LoadingModalComponent from "../components/LoadingModal.Component";
import { Helmet } from "react-helmet";

const Container = styled.div`
    padding: 20px;
    position: absolute;
    display: flex;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    align-items: center;
    justify-content: center;

`;

const MiddleSection = styled.form`
    width: 400px;
    

    @media screen and (max-width: 500px){
        width: 100%;
    }
`;
const Inputabel = styled.label`
    color: var(--lumina-text-color);
    font-size: 12px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    gap: 5px;

    @media screen and (max-width: 750px){
        font-size: 15px;
    }
`;
const InputContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    background: var(--lumina-text-box-color);
    padding: 15px;
    border-radius: 10px;

    @media screen and (max-width: 750px){
        padding: 15px;
        font-size: 15px;
    }
`;
const Input = styled.input`
    /* Hide up and down arrows in number input */
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    & {
        -moz-appearance: textfield; /* Firefox */
    }
    
    background: none;
    outline: none;
    border: none;
    color: var(--lumina-text-color);
    flex: 1;
    font-size: 16px;

`;

const ActionButton = styled.button`
    color: var(--lumina-text-black-color);
    background: var(--lumina-brand-color);
    font-size: 13px;
    border-radius: 10px;
    padding: 15px;
    cursor: pointer;
    text-align: center;
    margin-top: 30px;
    width: 100%;
    font-weight: 600;

`;


const Label = styled.div`
    color: var(--lumina-text-color);
    font-size: 13px;
`;
// email found section, time to enter OTP
const CodeInputContainer = styled.div`
display: flex;
align-items: center;
gap: 10px;
margin: 10px 0px;
// padding: 10px;

// border-radius: 10px;

// @media screen and (max-width: 750px){
//     padding: 15px;
// }
`;

const WeSentYouText = styled.h4`
    font-size: 14px;
    font-weight: 500;
    color: var(--lumina-text-color);
    margin-bottom: 20px;
`;
const CodeInput = styled.input`
    /* Hide up and down arrows in number input */
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    & {
        -moz-appearance: textfield; /* Firefox */
    }

    background: var(--lumina-text-box-color);
    border-radius: 10px;
    outline: none;
    border: solid 2px var(--lumina-text-box-color);
    color: var(--lumina-text-color);
    flex: 1;
    width: 60%;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    transition: 0s;
    padding: 5px;

    &:focus{
        border: solid 2px var(--lumina-brand-color);
    }
`;
const ResendContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;
const ResendButton = styled.div`
    color: var(--lumina-brand-color);
    font-weight: 600;
    font-size: 13px;
    background: var(--lumina-transparent-brand-color);
    padding: 10px;
    border-radius: 20px;
    cursor: pointer;
`;
const ResponseText = styled.div`
    font-size: 12px;
    margin-top: 10px;
    font-weight: 500;
    color: red;
`;


// OTP correct time to reset password



const rotate=keyframes`
    from {
        transform:rotate(0deg);
    }
    to{
        transform:rotate(360deg)
    }
`;

const LoadingSpinnerElement=styled.div`
    padding:10px;
    background-color:transparent;
    border-radius:100px;
    width:3px;
    height:3px;
    border-style:solid;
    border-left-color:var(--lumina-brand-color);
    border-top-color:var(--lumina-brand-color);
    border-width:5px;
    border-right-color:rgba(214, 214, 212, 0.39);
    border-bottom-color:rgba(214, 214, 212, 0.39);
    transition:linear,500ms;
    animation:${rotate} 1s linear infinite;
    margin-left: 20px;
`;


const ResetPassEnterEmail = () => {

    const inputLength = 6;
    const [email, setEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [consfirmedOTP, setConfirmedOTP] = useState('');
    const [otp, setOTP] = useState(new Array(inputLength).fill(''));
    const [emailFound, setEmailFound] = useState(false);
    const [otpIsConfirmed, setOTPIsConfirmed] = useState(false);
    const [timeLeft, setTimeLeft] = useState(null); 
    const [isRunning, setIsRunning] = useState(false);
    // const [lastRequestTime, setLastRequestTime] = useState(null);
    const [canResend, setCanResend] = useState(true);
    const [message, setMessage] = useState('')
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [smallLoader, setSmallLoader] = useState(false)

    


    const navigate = useNavigate();


    
    useEffect(() => {
        let timerInterval;
        if (isRunning && timeLeft > 0) {
            timerInterval = setInterval(() => {
                setTimeLeft(prevTime => {
                    if (prevTime <= 1) {
                        clearInterval(timerInterval);
                        setIsRunning(false);
                        setCanResend(true);
                        return 0;
                    }
                    return prevTime - 1;
                });
            }, 1000);
        }

        return () => clearInterval(timerInterval);
    }, [isRunning, timeLeft]);

    const startTimer = () => {
        setTimeLeft(120); // 2 minutes
        setIsRunning(true);
        setCanResend(false);
        // const currentTime = new Date();
        // setLastRequestTime(currentTime);
    };
    const handleInputChange = (e, index)=>{
        const value = e.value;
        setError('');
        setMessage('');

        
        // Ensure the input is a number and of a single character
        if (isNaN(value) || value.length > 1) return;

        const newCode = [...otp];
        newCode[index] = value;
        setOTP(newCode)

        if(index < inputLength - 1 && value){
            e.nextSibling.focus();
        }

        // Check if all inputs are filled
        const allFilled = newCode.every(val => val.length === 1);
        
        if (allFilled) {
            confirmOTP(newCode.join(''));
            setConfirmedOTP(newCode.join('')) 
        }
    }

    const handleBackspace=(e, index)=>{
        const newCode = [...otp];
        newCode[index] = '';
        setOTP(newCode)

        if(index > 0){
            e.previousSibling.focus();
        }
    }
    const formatTime = (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60).toString().padStart(2, '0');
        const seconds = (timeInSeconds % 60).toString().padStart(2, '0');
        return `00:${minutes}:${seconds}`;
    };

    const resendOTP = async () => {
        // Call your API to resend the OTP here...
        setLoading(true);
        setError('');
        try {
            const response = await axios.post(`${API_Route}regenerateTempVerificationCode`, {
                email: email
            });

            if (response.status === 200) {
                setMessage(`An OTP has been sent to ${email}`);


                startTimer();
                setLoading(false);
            }
        } catch (error) {
            setError('Something went wrong')
            setLoading(false);
        }
    }

    const findEmail = async(e)=>{
        e.preventDefault();

        if(email){
            setLoading(true);
            try {
                const response = await axios.post(`${API_Route}resetPasswordFindEmail`,{
                    email: email,
                });

                if(response.status===200){
                    setEmailFound(true);
                    setLoading(false)
                }
            } catch (error) {
                setLoading(false)

                if(error.response.status&&error.response.status===404){
                    setError('User not found')
                }else{
                    setError('Something went wrong')
                }
            }
        }else{
            setError('Please enter a valid email')
        }

        
    }

    const confirmOTP= async(otp)=>{
        setSmallLoader(true)


        if(otp){
            try {
                const response = await axios.post(`${API_Route}checkPasswordResetOTP`,{
                    email,
                    otp,
                })
    
                if(response.status===200){
                    setOTPIsConfirmed(true)
                    setSmallLoader(false)
                }
            } catch (error) {
                setSmallLoader(false)
                if(error.response.status&&error.response.status===400){
                    setError('Incorrect OTP')
                }else{
                    setError('Something went wrong')
                }
                
            }
        }else{
            setError('OTP cannot be empty');
            setSmallLoader(false)
        }
    }

    const resetPassword=async(e)=>{
        e.preventDefault();

        setLoading(true)

        if(newPassword&&confirmNewPassword){

            if(newPassword===confirmNewPassword){
                try {
                    const response = await axios.post(`${API_Route}resetPassword`,{
                        email,
                        otp: consfirmedOTP,
                        password: newPassword,
                    })
        
                    if(response.status===200){
                        setLoading(false)
                        navigate('/signin')
                    }
                } catch (error) {
                    setLoading(false)
                    setError('Something went wrong')
                }
            }else{
                setError("Passwords must match")
                setLoading(false)
            }
        }else{
            setError('Please enter a new password')
            setLoading(false)
        }
        
    }


    return (
        <>
         <Helmet>
            <title>Reset Password</title>
        </Helmet>
        <Container>
            <LoadingModalComponent showModal={loading} />
            {emailFound && !otpIsConfirmed
            // email found section, time to enter OTP
            ?<MiddleSection>
                <WeSentYouText>A 6-digit OTP has been sent to <strong>{email.toLowerCase()}</strong></WeSentYouText>
                <Label>
                    Enter OTP
                
                    <CodeInputContainer>
                        {otp.map((data, index) => (
                            <CodeInput
                                disabled={smallLoader}
                                key={index}
                                maxLength={1}
                                type="text"
                                value={data}
                                onChange={(e) => handleInputChange(e.target, index)}
                                placeholder="-"
                                onKeyDown={(e)=>{
                                    if(e.key==='Backspace'){
                                        handleBackspace(e.target, index)
                                    }
                                }}
                            />
                        ))}
                    {smallLoader&&<LoadingSpinnerElement/>}
                    {/* {!verifyingCode&&<div style={{width: '3px', padding:'10px', marginLeft:'20px'}}/>} */}
                    </CodeInputContainer>
                </Label>
                <ResendContainer>
                {canResend ? (
                    <ResendButton onClick={resendOTP}>Resend Code?</ResendButton>
                ) : (
                    <ResendButton>{formatTime(timeLeft)} left</ResendButton>
                )}
                </ResendContainer>

                {message&&<ResponseText style={{color:'var(--lumina-brand-color)'}}>{message}</ResponseText>}
                {error&&<ResponseText>{error}</ResponseText>}
            </MiddleSection>
            

            // OTP is correct 
            :emailFound && otpIsConfirmed
            ?<MiddleSection onSubmit={resetPassword}>
                <Inputabel>
                    Enter new password
                    <InputContainer>
                        <Input value={newPassword} placeholder="Enter new password" onChange={(e)=>{setNewPassword(e.target.value); setError('')}} />
                    </InputContainer>
                </Inputabel>
                <br/>
                <Inputabel>
                    Confirm new password
                    <InputContainer>
                        <Input value={confirmNewPassword} placeholder="Confirm new password" onChange={(e)=>{setConfirmNewPassword(e.target.value); setError('')}} />
                    </InputContainer>
                </Inputabel>
                
                {error&&<ResponseText>{error}</ResponseText>}
                

                <ActionButton type='submit' onClick={resetPassword}>Reset Password</ActionButton>
            </MiddleSection>

            // Enter email
            :<MiddleSection onSubmit={findEmail}>
                <Inputabel>
                    Enter your email
                    <InputContainer>
                        <Input value={email} placeholder="Let's find your account" onChange={(e)=>{setEmail(e.target.value); setError('')}} />
                    </InputContainer>
                </Inputabel>
                {error&&<ResponseText>{error}</ResponseText>}

                <ActionButton type='submit' onClick={findEmail}>Continue</ActionButton>
            </MiddleSection>}

            
        </Container>
        </>
    )
}

export default ResetPassEnterEmail