import styled, { keyframes } from "styled-components";
import VerificationSheild from "../assets/verification-shield.png";
// import SheildIcon from "../assets/shield-icon.svg";
import axios from "axios";
import { API_Route } from "../utils/Api";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingModalComponent from "../components/LoadingModal.Component";
import {Helmet} from "react-helmet"

const Container = styled.div`
    // display: flex;
    // align-items: center;
    // position: absolute;
    // top: 0;
    // left: 0;
    // right:0;
    // bottom: 0;
    // justify-content: center;
    // padding: 40px 0px;
`;
const InnerContainer = styled.div`
    width: 400px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: auto;
    margin-top: 20px;

    @media screen and (max-width: 500px){
        width: 380px
    }
    // justify-content: center;
    // align-items: center;
    // background: blue;
`;
const BigShieldContainer = styled.div`
    width: 150px;
    margin: auto;
    
    // height: 200px;
`;
const BigShieldImage = styled.img`
    width: 100%;
    height: 100%;
`;
const WeSentYouText = styled.h4`
    font-size: 14px;
    font-weight: 500;
    color: var(--lumina-text-color);
`;
const VerificationCodeText = styled.label`
    font-size: 15px;
    font-weight: 600;
    color: var(--lumina-text-color);
    text-align: left;
`;
const CodeInputContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 10px 0px;
    // padding: 10px;
    
    // border-radius: 10px;

    // @media screen and (max-width: 750px){
    //     padding: 15px;
    // }
`;
const CodeInput = styled.input`
    /* Hide up and down arrows in number input */
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    & {
        -moz-appearance: textfield; /* Firefox */
    }
    
    background: var(--lumina-text-box-color);
    border-radius: 10px;
    outline: none;
    border: solid 2px var(--lumina-text-box-color);
    color: var(--lumina-text-color);
    flex: 1;
    width: 60%;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    transition: 0s;
    padding: 5px;

    &:focus{
        border: solid 2px var(--lumina-brand-color);
    }
`;
const ResendContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;
const ResendButton = styled.div`
    color: var(--lumina-brand-color);
    font-weight: 600;
    font-size: 13px;
    background: var(--lumina-transparent-brand-color);
    padding: 10px;
    border-radius: 20px;
    cursor: pointer;
`;
// const ExpirationTime = styled.div`
//     font-size: 13px;
//     font-weight: 500;
//     color: var(--lumina-text-color);
// `;


// const SecuredByText = styled.h4`
//     color: var(--lumina-text-color);
//     font-size: 11px;
//     display: flex;
//     align-items: center;
//     gap: 10px;
//     margin: auto;

//     @media screen and (max-width: 750px){
//         margin-bottom: 150px;
//     }
// `;
// const SheildIconImg = styled.img`
//     width: 20px;
//     height: 20px;
// `;



const rotate=keyframes`
    from {
        transform:rotate(0deg);
    }
    to{
        transform:rotate(360deg)
    }
`;

const LoadingSpinnerElement=styled.div`
    padding:10px;
    background-color:transparent;
    border-radius:100px;
    width:3px;
    height:3px;
    border-style:solid;
    border-left-color:var(--lumina-brand-color);
    border-top-color:var(--lumina-brand-color);
    border-width:5px;
    border-right-color:rgba(214, 214, 212, 0.39);
    border-bottom-color:rgba(214, 214, 212, 0.39);
    transition:linear,500ms;
    animation:${rotate} 1s linear infinite;
    margin-left: 20px;
`;

const ResponseText = styled.div`
    font-size: 12px;
    // margin-top: 10px;
    font-weight: 500;
    color: red;
    text-align: left;
`;

const VerifyEmailOTP = () => {
    const inputLength = 6;
    const [otpInput, setOTPInput] = useState(new Array(inputLength).fill(''));
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(true)
    const [verifyingCode, setVerifyingCode] = useState(false)
    const [message, setMessage] = useState('')
    const [error, setError] = useState('')

    const [timeLeft, setTimeLeft] = useState(null); 
    const [isRunning, setIsRunning] = useState(false);
    // const [lastRequestTime, setLastRequestTime] = useState(null);
    const [canResend, setCanResend] = useState(false);
    
    const navigate = useNavigate();
    const userSessionToken = localStorage.getItem('userSessionToken');

    const handleInputChange = (e, index)=>{
        const value = e.value;
        setError('');
        setMessage('');

        
        // Ensure the input is a number and of a single character
        if (isNaN(value) || value.length > 1) return;

        const newCode = [...otpInput];
        newCode[index] = value;
        setOTPInput(newCode)

        if(index < inputLength - 1 && value){
            e.nextSibling.focus();
        }

        // Check if all inputs are filled
        const allFilled = newCode.every(val => val.length === 1);
        
        if (allFilled) {
            verifyEmail(newCode.join('')); 
        }
    }

    const handleBackspace=(e, index)=>{
        const newCode = [...otpInput];
        newCode[index] = '';
        setOTPInput(newCode)

        if(index > 0){
            e.previousSibling.focus();
        }
    }
    useEffect(() => {
        if (!userSessionToken) {
            navigate('/signin');
        }
    }, [userSessionToken, navigate]);

    useEffect(() => {
        const getUser = async () => {
            try {
                const response = await axios.post(`${API_Route}getUserProfile`, {
                    userSessionToken
                });
                if (response.status === 200) {

                    if(response.data.emailActivated){
                        navigate('/dashboard')
                    }else{
                        setEmail(response.data.email);
                        const lastRequest = new Date(response.data.last_otp_resend_time);
                        // setLastRequestTime(lastRequest);
                        const timeDiff = Math.floor((new Date() - lastRequest) / 1000);

                        if (timeDiff >= 120) {
                            setCanResend(true);
                        } else {
                            setCanResend(false);
                            setTimeLeft(120 - timeDiff);
                            setIsRunning(true);
                        }
                        setLoading(false);
                    }
                    
                }
            } catch (error) {
                console.log(error);
            }
        };

        getUser();
    }, [userSessionToken, navigate]);

    useEffect(() => {
        let timerInterval;
        if (isRunning && timeLeft > 0) {
            timerInterval = setInterval(() => {
                setTimeLeft(prevTime => {
                    if (prevTime <= 1) {
                        clearInterval(timerInterval);
                        setIsRunning(false);
                        setCanResend(true);
                        return 0;
                    }
                    return prevTime - 1;
                });
            }, 1000);
        }

        return () => clearInterval(timerInterval);
    }, [isRunning, timeLeft]);

    const startTimer = () => {
        setTimeLeft(120); // 2 minutes
        setIsRunning(true);
        setCanResend(false);
        // const currentTime = new Date();
        // setLastRequestTime(currentTime);
    };

    const formatTime = (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60).toString().padStart(2, '0');
        const seconds = (timeInSeconds % 60).toString().padStart(2, '0');
        return `00:${minutes}:${seconds}`;
    };



    const resendOTP = async () => {
        setLoading(true);
        setError('');
        // Call your API to resend the OTP here...
        try {
            const response = await axios.post(`${API_Route}regenerateTempVerificationCode`, {
                email: email
            });

            if (response.status === 200) {
                setMessage(`An OTP has been sent to ${email}`);
                startTimer();
                setLoading(false);
            }
        } catch (error) {
            setError('Something went wrong')
            setLoading(false);
        }
    };

    const verifyEmail = async (otp) => {
        setVerifyingCode(true)

        if(otp){
            try {
                const response = await axios.post(`${API_Route}verifyUser`, {
                    email: email,
                    otp: otp
                });
    
                if (response.status === 200) {
                    navigate('/dashboard');
                }
                setVerifyingCode(false)
            } catch (error) {
                setVerifyingCode(false)
                if(error.response.status&&error.response.status===400){
                    setError('Incorrect OTP')
                }else{
                    setError('Something went wrong')
                }

            }
        }else{
            setError('OTP cannot be empty');
        }
        
    };

    return (
        <>
        <Helmet>
        <title>Verify Account</title>
    </Helmet>
        <Container>
            {loading
            ? <LoadingModalComponent showModal={loading} />
            :<InnerContainer>
                <BigShieldContainer>
                    <BigShieldImage src={VerificationSheild}/>
                </BigShieldContainer>

                <WeSentYouText>We just sent a verification code<br/>to  <strong>{email}</strong></WeSentYouText>

                <VerificationCodeText>Enter 6-digit verification Code</VerificationCodeText>
                <CodeInputContainer>
                    {otpInput.map((data, index) => (
                        <CodeInput
                            disabled={verifyingCode}
                            key={index}
                            maxLength={1}
                            type="text"
                            value={data}
                            onChange={(e) => handleInputChange(e.target, index)}
                            placeholder="-"
                            onKeyDown={(e)=>{
                                if(e.key==='Backspace'){
                                    handleBackspace(e.target, index)
                                }
                            }}
                        />
                    ))}
                    {/* {!verifyingCode&&<div style={{width: '3px', padding:'10px', marginLeft:'20px'}}/>} */}
                    {verifyingCode&&<LoadingSpinnerElement/>}
                </CodeInputContainer>                
                                

                <ResendContainer>
                {canResend ? (
                            <ResendButton onClick={resendOTP}>Resend Code?</ResendButton>
                        ) : (
                            <ResendButton>{formatTime(timeLeft)} left</ResendButton>
                        )}
                </ResendContainer>

                {message&&<ResponseText style={{color:'var(--lumina-brand-color)'}}>{message}</ResponseText>}
                {error&&<ResponseText>{error}</ResponseText>}


                {/* <SecuredByText><SheildIconImg src={SheildIcon}/> Secured by <span style={{color: "var(--lumina-brand-color)"}}>Lumina</span></SecuredByText> */}
            </InnerContainer>}
        </Container>
        </>
    )
}

export default VerifyEmailOTP
