import styled from "styled-components"
import {Helmet} from "react-helmet"

const Container = styled.div``;

const TermsAndConditions = () => {
    return (
        <>
        <Helmet>
        <title>Handiswap | Terms&Conditions</title>
    </Helmet>
    <Container>
        
        </Container>
    </>
    )
}

export default TermsAndConditions