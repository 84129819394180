import styled from "styled-components"

const Container = styled.div``;

const Notfound = () => {
    return (
        <Container>
            
        </Container>
    )
}

export default Notfound