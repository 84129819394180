import { Route, Routes } from 'react-router-dom';
import './App.css';
import Homepage from './pages/Homepage';
import Dashboard from './pages/Dashboard';
import Notfound from './pages/Notfound';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndCondition';
import SignIn from './pages/Signin';
import SignUp from './pages/Signup';
// import ResetPassEnterEmail from './pages/ResetPassEnterEmail';
// import ResetPassEnterNewPass from './pages/ResetPassEnterNewPass';
// import ResetPassEnterOTP from './pages/ResetPassEnterOTP';
import VerifyEmailOTP from './pages/VerifyEmailOTP';
import { AuthenticatedRoutes, UnAuthenticatedRoutes } from './utils/PrivateRoutes';
import Swap from './pages/Swap';
import TransactionCancelled from './pages/TransactionCancelled';
import ConfirmStripeTransaction from './pages/ConfirmStripeTransaction';
import TransactionSuccess from './pages/TransactionSuccess';
import ConfirmPaystackTransaction from './pages/ConfirmPaystackTransaction';
import ResetPassEnterEmail from './pages/ResetPassEnterEmail';
import AboutPage from "./pages/AboutPage"
import AboutUs from './components/AboutUs';

function App() {

    
    return (
        <div className="App">
            <Routes>

                {/* Only authenticated users can view dashoard */}
                <Route element={<AuthenticatedRoutes/>}>
                    <Route path='/dashboard' element={<Dashboard />}/>
                    <Route path='/app/swap' element={<Swap/>}/>
                    <Route path='/success' element={<TransactionSuccess/>}/>
                    <Route path='/confirm-stripe-transaction' element={<ConfirmStripeTransaction/>}/>
                    <Route path='/confirm-paystack-transaction' element={<ConfirmPaystackTransaction/>}/>
                    <Route path='/cancel_url' element={<TransactionCancelled/>}/>
                </Route>

                {/* Only unathenticated users can view signup, signin and verify-email */}
                <Route element={<UnAuthenticatedRoutes/>}>
                    <Route path='/signup' element={<SignUp/>}/>
                    <Route path='/signin' element={<SignIn/>}/>
                    <Route path='/verify-email' element={<VerifyEmailOTP/>}/>
                    <Route path='/reset-password' element={<ResetPassEnterEmail/>}/>
                </Route>

                <Route path='/' element={<Homepage/>}/>
                <Route path='/terms-of-service' element={<TermsAndConditions/>}/>
                <Route path='/privacy-policy' element={<PrivacyPolicy/>}/>
                <Route path="/about" element={<AboutPage/>} />
                <Route path="/about-us" element={<AboutUs/>} />
                <Route path='*' element={<Notfound/>}/>

            </Routes>
        </div>
    );
}

export default App;
