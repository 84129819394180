import { Outlet, Navigate } from "react-router-dom";


export function AuthenticatedRoutes (){
    const auth = localStorage.getItem('userSessionToken');
    return(
        auth!=null?<Outlet/>:<Navigate to='/signin'/>
    )
}

export function UnAuthenticatedRoutes (){
    const auth = localStorage.getItem('userSessionToken');
    return(
        auth===null?<Outlet/>:<Navigate to='/dashboard'/>
    )
}

