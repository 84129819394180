import axios from 'axios';
import React, { useEffect,useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { API_Route } from '../utils/Api';
import ConfirmComponent from '../components/ConfirmComponent';
import ProcessingComponent from '../components/ProcessingComponent';
import {Helmet} from "react-helmet"

const ConfirmStripeTransaction = () => {

    const [searchParams] = useSearchParams();
    const [processingModal,setProcessingModal] = useState('flex');
    const [confirmedModal, setConfirmedModal] = useState({scaling:'50%',visibility:'none'})

    const session_id = searchParams.get('session_id')

    const navigate = useNavigate();


    // check if there's a session id
    useEffect(()=>{
        if(!session_id){
            navigate('/')
        }
    },[session_id, navigate])



    useEffect(()=>{
        const confirmTransaction=async()=>{

            try {
                const userSessionToken = localStorage.getItem('userSessionToken');

                const response = await axios.post(`${API_Route}confirmBuyCryptoTransaction`,{
                    userSessionToken,
                    stripe_reference_no: session_id,
                    stripePayment: true,
                    paystackPayment: false,
                });

                if(response.status===200){
                    setProcessingModal('none');  // make processing modal disappear after transaction is confirmed 

                    // define confirmation params 
                    const confirmation_params={
                        scaling:'100%',
                        visibility:'flex'
                    }
                    setConfirmedModal(confirmation_params); // display confirmation modal after processing
                    navigate('/dashboard') // navigate to dashboard after processing transaction 
                }

                console.log(response.data)
            } catch (error) {
                console.log(error)
            }
        }

        confirmTransaction();
    },[session_id, navigate])


    return (
        <>
         <Helmet>
            <title>Confirming..</title>
        </Helmet>
        <ConfirmComponent isShowing={confirmedModal.visibility} imageScale={confirmedModal.scaling} data='Successful' />
       <ProcessingComponent isShowing={processingModal} data='Processing ...'/>
     </>
    )
}

export default ConfirmStripeTransaction;